import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import React, { Component } from 'react'
import { Select } from 'antd'

import { capitalizeAllWords } from '../../helpers/utils'
import { GRAY_COLOR } from '../../constants'

class SelectWithRadioButtons extends Component {
  render() {
    const { name, onChange, options, placeholder, selected, titleText } =
      this.props
    const { allOptions, suggestedOptions } = options

    const CombinedComponent = () => (
      <div>
        <RadioButtons
          name={name}
          options={suggestedOptions}
          onChange={onChange}
          selected={selected}
          titleText={titleText}
        />
        <Select
          options={allOptions}
          onChange={onChange}
          placeholder={placeholder}
          value={selected}
          style={{ width: '100%' }}
        />
      </div>
    )

    return <CombinedComponent />
  }
}

const LeadText = ({ titleText }) => {
  if (isEmpty(titleText)) return null

  const style = {
    color: GRAY_COLOR,
    fontStyle: 'italic',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  }
  return <label style={style}>{titleText}</label>
}

const RadioButtons = (props) => {
  const { name, onChange, options, selected, titleText } = props

  const shouldRender = !isEmpty(name) && !isEmpty(options)

  if (shouldRender) {
    return (
      <>
        <LeadText titleText={titleText} />
        {options.map((option, index) => {
          const styles = {
            wrapper: {
              display: 'block',
              marginBottom: '1rem',
              cursor: 'pointer',
            },
            text: {
              marginLeft: '1rem',
              cursor: 'pointer',
            },
          }

          return (
            <label
              key={`${index}`}
              htmlFor={`${name}-${index}`}
              style={styles.wrapper}
            >
              <input
                id={`${name}-${index}`}
                name={name}
                type="radio"
                value={option.value}
                checked={isEqual(selected, option.value) ? 'checked' : null}
                onChange={() => onChange(option.value)}
              />
              <span style={styles.text} className="modal-prominent-text">
                {capitalizeAllWords(option.label)}
              </span>
            </label>
          )
        })}
      </>
    )
  }
  return null
}

export default SelectWithRadioButtons
