import { useSelector, shallowEqual } from 'react-redux'
import { useMakeModelByVin } from '../../hooks/MakeModels'
import VehicleDetails from '../VehicleDetails'
import { useSalesCodeConstraintsByVin } from '../../hooks/SalesCodes'
import { Popover, Button } from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl'
import React from 'react'

import { CarOutlined, DownOutlined } from '@ant-design/icons'

function Collapsible(props) {
  const { vehicleName, onClick } = props

  return (
    <div data-testid="collapsible_vehicle_make_model" className="collapsible">
      <div className="vehicle-name">{vehicleName}</div>
      <Button onClick={onClick}>
        <CarOutlined />
        <DownOutlined />
      </Button>
    </div>
  )
}

const VehicleMakeModelName = (props) => {
  const { className, showVin } = props
  const vin =
    useSelector((state) => state.componentState.vin, shallowEqual) || props.vin
  const { isSuccess, isLoading, makeModel } = useMakeModelByVin({ vin })
  const {
    isSuccess: salesCodeConstraintsIsSuccess,
    isLoading: salesCodeConstraintsisLoading,
    salesCodeConstraints,
  } = useSalesCodeConstraintsByVin({ vin })

  if (isLoading)
    return (
      <div className={className}>
        <p>
          <FormattedMessage id="tracer.loading" />
        </p>
      </div>
    )

  let vehicleName = (
    <>
      <p>
        <FormattedMessage id="tracer.unknownVehicle" />
      </p>
    </>
  )
  if (makeModel) {
    if (showVin) {
      vehicleName = (
        <>
          <p
            className={
              makeModel.is_virtual == false ? 'vehicle-name-description' : ''
            }
          >
            {makeModel.make_description || makeModel.make}{' '}
            {makeModel.model_description || makeModel.model}
          </p>
          {makeModel.is_virtual === false && (
            <p className="vehicle-name-description">
              <span>VIN:</span> {vin}
            </p>
          )}
        </>
      )
    } else {
      vehicleName = (
        <>
          <p className="vehicle-name-description">
            {makeModel.make_description || makeModel.make}
          </p>
          <p className="vehicle-name-description">
            {makeModel.model_description || makeModel.model}
          </p>
        </>
      )
    }
  }

  return (
    <div className={className}>
      <Popover
        trigger="click"
        placement="bottom"
        content={() => (
          <VehicleDetails salesCodeConstraints={salesCodeConstraints} />
        )}
        overlayStyle={{
          width: '40vw',
        }}
      >
        <Collapsible vehicleName={vehicleName} />
      </Popover>
    </div>
  )
}

export default injectIntl(VehicleMakeModelName)
