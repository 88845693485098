import DeviceNode from '../DeviceNode'
import GroundNode from '../GroundNode'

export default function nodeRouter({
  node,
  onNodeClick,
  onPortClick,
  theme = null,
}) {
  const { category } = node.properties.data
  switch (category) {
    case 'ground':
      return (
        <GroundNode
          node={node}
          onNodeClick={onNodeClick}
          onPortClick={onPortClick}
          theme={theme}
        />
      )
    default:
      return (
        <DeviceNode
          node={node}
          onNodeClick={onNodeClick}
          onPortClick={onPortClick}
          theme={theme}
        />
      )
  }
}
