import { createContext, useContext } from 'react'
import { useImmerReducer } from 'use-immer'

const VirtualVehicleConfigurationContext = createContext(null)
const VirtualVehicleConfigurationDispatchContext = createContext(null)

export function VirtualVehicleConfigurationProvider({ children }) {
  const [state, dispatch] = useImmerReducer(
    virtualVehicleConfigurationReducer,
    initialConfiguration,
  )

  return (
    <VirtualVehicleConfigurationContext.Provider value={state}>
      <VirtualVehicleConfigurationDispatchContext.Provider value={dispatch}>
        {children}
      </VirtualVehicleConfigurationDispatchContext.Provider>
    </VirtualVehicleConfigurationContext.Provider>
  )
}

export function useVirtualVehicleConfiguration() {
  return useContext(VirtualVehicleConfigurationContext)
}

export function useVirtualVehicleConfigurationDispatch() {
  const dispatch = useContext(VirtualVehicleConfigurationDispatchContext)

  const reset = () => dispatch({ type: 'reset' })

  const handleMakeModelIDSelection = ({ makeModelID }) => {
    dispatch({
      type: 'sales_codes_make_model_id_selected',
      makeModelID,
    })
  }
  const handleSalesCodeAdded = ({ group, salesCode }) => {
    dispatch({
      type: 'sales_codes_sales_code_added',
      salesCode,
      group,
    })
  }

  const handleSalesCodeDelete = ({ group }) => {
    dispatch({
      type: 'sales_codes_sales_code_deleted',
      group,
    })
  }

  const handleInitialSalesCodes = ({ salesCodesSelection }) => {
    dispatch({
      type: 'sales_codes_initial',
      salesCodesSelection,
    })
  }

  const updateSalesCodesPreSelectedVIN = ({ salesCodesPreSelectedVIN }) => {
    dispatch({
      type: 'updateSalesCodesPreSelectedVIN',
      salesCodesPreSelectedVIN,
    })
  }

  const toggleVINLoading = (isVinLoading) => {
    dispatch({
      type: 'toggleVINLoading',
      isVinLoading,
    })
  }

  const updateSelectedMakeDesc = ({ selectedMakeDesc }) => {
    dispatch({
      type: 'updateSelectedMakeDesc',
      selectedMakeDesc,
    })
  }

  const updateSelectedModelId = ({ selectedModelId }) => {
    dispatch({
      type: 'updateSelectedModelId',
      selectedModelId,
    })
  }

  const updateMakeSearch = ({ makeSearch }) => {
    dispatch({
      type: 'updateMakeSearch',
      makeSearch,
    })
  }

  const updateModelSearch = ({ modelSearch }) => {
    dispatch({
      type: 'updateModelSearch',
      modelSearch,
    })
  }

  return {
    handleMakeModelIDSelection,
    handleSalesCodeAdded,
    handleInitialSalesCodes,
    handleSalesCodeDelete,
    updateSalesCodesPreSelectedVIN,
    toggleVINLoading,
    updateSelectedMakeDesc,
    updateSelectedModelId,
    updateMakeSearch,
    updateModelSearch,
    reset,
  }
}

function virtualVehicleConfigurationReducer(draft, action) {
  switch (action.type) {
    case 'reset': {
      draft.makeModelID = initialConfiguration.makeModelID
      draft.salesCodesSelection = initialConfiguration.salesCodesSelection
      draft.salesCodesPreSelectedVIN =
        initialConfiguration.salesCodesPreSelectedVIN
      draft.isVinLoading = initialConfiguration.isVinLoading
      draft.selectedMakeDesc = initialConfiguration.selectedMakeDesc
      draft.selectedModelId = initialConfiguration.selectedModelId
      draft.makeSearch = initialConfiguration.makeSearch
      draft.modelSearch = initialConfiguration.modelSearch
      break
    }
    case 'sales_codes_make_model_id_selected': {
      draft.makeModelID = action.makeModelID
      draft.selectedModelDescription = action.selectedModelDescription
      draft.salesCodesSelection = initialConfiguration.salesCodesSelection
      break
    }
    case 'sales_codes_sales_code_added': {
      draft.salesCodesSelection[action.group] = action.salesCode
      break
    }
    case 'sales_codes_initial': {
      draft.salesCodesSelection = action.salesCodesSelection
      break
    }
    case 'sales_codes_sales_code_deleted': {
      draft.salesCodesSelection[action.group] = null
      break
    }
    case 'updateSalesCodesPreSelectedVIN': {
      draft.salesCodesPreSelectedVIN = action.salesCodesPreSelectedVIN
      break
    }
    case 'toggleVINLoading': {
      draft.isVinLoading = action.isVinLoading
      break
    }
    case 'updateSelectedMakeDesc': {
      draft.selectedMakeDesc = action.selectedMakeDesc
      break
    }
    case 'updateSelectedModelId': {
      draft.selectedModelId = action.selectedModelId
      break
    }
    case 'updateMakeSearch': {
      draft.makeSearch = action.makeSearch
      break
    }
    case 'updateModelSearch': {
      draft.modelSearch = action.modelSearch
      break
    }
    default: {
      throw Error('Unknown action: ' + action.type)
    }
  }
}

const initialConfiguration = {
  makeModelID: null,
  salesCodesSelection: {},
  salesCodesPreSelectedVIN: null,
  isVinLoading: false,
  selectedMakeDesc: '',
  selectedModelId: null,
  makeSearch: '',
  modelSearch: '',
}
