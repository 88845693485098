import React from 'react'

const Header = (props) => {
  let headerText = ''

  if (props.title && props.title != '') {
    headerText += props.title
  }

  if (props.extendedtitle && props.extendedtitle != '') {
    headerText += ` ${props.extendedtitle}`
  }

  if (props.subtitle && props.subtitle != '') {
    headerText += ` - ${props.subtitle}`
  }

  return (
    <div id="header-component-details">
      <h5>{headerText}</h5>
    </div>
  )
}

export default Header
