import React from 'react'

import { RED_COLOR, WHITE_COLOR } from '../../constants'

const defaultProps = {
  fill: RED_COLOR,
  circleStroke: WHITE_COLOR,
  stroke: WHITE_COLOR,
  enableOutline: true,
  wrapperClass: '',
}

const IconFail = (props) => {
  let { wrapperClass, circleStroke, fill, stroke } = props
  if (!props.enableOutline) {
    circleStroke = props.fill
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={wrapperClass}
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(2 2)">
          <circle fill={fill} cx="8" cy="8" r="9" />
          <circle cx="8" cy="8" r="9" stroke={circleStroke} strokeWidth="2" />
        </g>
        <path
          stroke={stroke}
          strokeLinecap="square"
          strokeWidth="2"
          d="M7.08 6.883l5.846 6.187M12.93 7.034l-5.638 6.02"
        />
      </g>
    </svg>
  )
}

IconFail.defaultProps = defaultProps

export default IconFail
