import React, { useState } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import history from '../history'
import { useUserPlant, useUserInfo } from '../hooks/User'
import { GRAY_COLOR } from '../constants'
import { unsetModal } from '../actions/modal-actions'
import { LoadingOutlined } from '@ant-design/icons'
import { Flex, Modal, Spin, Button, Radio, Space } from 'antd'

const SelectPlant = ({ intl, isOpen, closeModal }) => {
  const location = useLocation()
  const [userPlant, setUserPlant] = useUserPlant()
  const [, , remove] = useLocalStorage('reports-query')
  const { isLoading, userInfo } = useUserInfo()
  const [selectedPlant, selectPlant] = useState(userPlant)
  const onSubmitClick = () => {
    remove()
    setUserPlant(selectedPlant)
    closeModal()
    if (location.pathname == '/reports') history.go(0)
  }

  return (
    <Modal
      open={isOpen}
      title={<FormattedMessage id="selectPlant.title" />}
      onCancel={closeModal}
      footer={[
        <Button onClick={closeModal}>
          <FormattedMessage id="forms.cancel" />
        </Button>,
        <Button
          type="primary"
          onClick={onSubmitClick}
          disabled={selectedPlant === null}
        >
          <FormattedMessage id="forms.submit" />
        </Button>,
      ]}
      centered={true}
    >
      <Flex vertical={true} gap="middle">
        <div style={{ color: GRAY_COLOR }}>
          <FormattedMessage id="selectPlant.leadingText" />
        </div>
        {isLoading && <Spin indicator={<LoadingOutlined spin />} />}
        <Radio.Group
          onChange={(e) => selectPlant(e.target.value)}
          value={selectedPlant}
        >
          <Space direction="vertical">
            {userInfo?.plants.map((availablePlant) => {
              return <Radio value={availablePlant}>{availablePlant}</Radio>
            })}
          </Space>
        </Radio.Group>
      </Flex>
    </Modal>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    isOpen: state.modalState.isOpen,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    closeModal: () => {
      dispatch(unsetModal())
    },
  }
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(SelectPlant),
)
