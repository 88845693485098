import React, { useState } from 'react'
import { withCookies } from 'react-cookie'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import { unsetModal } from '../actions/modal-actions'
import { GRAY_COLOR } from '../constants'
import {
  availableMainViewsToFavorite,
  mainViewCookieKey,
} from '../reducers/tab-reducer'

import { Modal, Radio, Button, Space, Flex } from 'antd'

const setTabFavorite = (cookies, selectedTab) => {
  const expirationDate = new Date()
  const ageInYears = 1
  const ageInDays = ageInYears * 365
  const ageInSeconds = ageInDays * 24 * 60 * 60
  const ageInMillis = ageInSeconds * 1000
  expirationDate.setTime(expirationDate.getTime() + ageInMillis)

  // I.E. doesn't use maxAge, so use expires as well
  const options = {
    expires: expirationDate,
    maxAge: ageInSeconds,
    path: '/',
  }
  cookies.remove(mainViewCookieKey)
  cookies.set(mainViewCookieKey, selectedTab, options)
}

const ChangeStartView = (props) => {
  const { tabs, favoritedStartTab, intl, isOpen, closeModal, cookies } = props
  const [selectedTab, selectTab] = useState(favoritedStartTab)
  const onSubmitClick = () => {
    setTabFavorite(cookies, selectedTab)
    closeModal()
  }
  return (
    <Modal
      open={isOpen}
      title={<FormattedMessage id="changeStartView.title" />}
      onCancel={closeModal}
      footer={[
        <Button onClick={closeModal}>
          <FormattedMessage id="forms.cancel" />
        </Button>,
        <Button
          type="primary"
          onClick={onSubmitClick}
          disabled={isEmpty(selectedTab)}
        >
          <FormattedMessage id="forms.submit" />
        </Button>,
      ]}
      centered={true}
    >
      <Flex gap="middle" vertical={true}>
        <div style={{ color: GRAY_COLOR }}>
          <FormattedMessage id="changeStartView.leadingText" />
        </div>
        <Radio.Group
          onChange={(e) => selectTab(e.target.value)}
          value={selectedTab}
        >
          <Space direction="vertical">
            {tabs.map((tab) => {
              const intlId = `tracer.${tab}.tabName`
              const text = intl.formatMessage({ id: intlId })
              return <Radio value={tab}>{text}</Radio>
            })}
          </Space>
        </Radio.Group>
      </Flex>
    </Modal>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { cookies } = ownProps

  return {
    isOpen: state.modalState.isOpen,
    favoritedStartTab: cookies.get(mainViewCookieKey),
    tabs: availableMainViewsToFavorite,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    closeModal: () => {
      dispatch(unsetModal())
    },
  }
}

export default withCookies(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(ChangeStartView)),
)
