import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import get from 'lodash/get'

import { Button, Flex, ConfigProvider } from 'antd'
import {
  EDGE_TYPE_DEVICE,
  EDGE_TYPE_INLINE,
  MODAL_COMPONENT_STATE,
  MODAL_CONFIRM_VERIFY_COMPONENT,
  MODAL_DEVICE_STATE,
  MODAL_HARNESS_STATE,
  WHITE_COLOR,
  VIVID_GREEN_COLOR,
  BRIGHT_GREEN_COLOR,
  PASTEL_RED_COLOR,
} from '../../constants'
import MustHave from '../../containers/MustHave'
import AdditionalComponentFaultMenu from '../AdditionalComponentFaultMenu'
import { setComponent, setModal } from '../../actions/modal-actions'
import { onUnmarkComponentAsVerified } from '../../thunks/component'
import {
  clearInlineComponents,
  setInlineComponents,
} from '../../actions/harness-actions'

import { useDeviceList } from '../../hooks/HarnessSearch'

const propTypes = {
  fillIconOk: PropTypes.string,
  fillIconFail: PropTypes.string,
  strokeIconOk: PropTypes.string,
  strokeIconFail: PropTypes.string,
  outline: PropTypes.bool,
  tooltip: PropTypes.bool,
  onBeforeClick: PropTypes.func,
}

const defaultProps = {
  fillIconOk: 'transparent',
  strokeIconOk: WHITE_COLOR,
  fillIconFail: 'transparent',
  outline: false,
  tooltip: false,
}

const StateButtons = (props) => {
  const dispatch = useDispatch()

  const component = useSelector((state) => state.componentState.selectedElement)
  const vin = useSelector((state) => state.componentState.vin)
  const componentId = get(component, 'id')

  const componentState = useSelector(
    (state) => state.loggingState.componentStates[componentId],
  )
  const status = get(componentState, 'status')
  const [deviceList, updateDeviceList] = useState([])

  const { isLoading, devicelist } = useDeviceList({ vin, componentId })

  useEffect(() => {
    if (!isLoading && devicelist && devicelist.length !== 0) {
      updateDeviceList(devicelist)
    }
  }, [isLoading, devicelist])

  const showVerifyButton =
    component &&
    (component.isNode || component.edge_type === EDGE_TYPE_INLINE) &&
    component.category !== EDGE_TYPE_DEVICE

  const verifiedComponents = useSelector(
    (state) => state.componentState.verifiedComponents || [],
    shallowEqual,
  )
  const isComponentVerified =
    verifiedComponents.findIndex((cid) => cid === componentId) !== -1
  const { outline, tooltip, onBeforeClick } = props

  const disableVerifyComponentButton = status !== undefined

  const openConfirmationModal = () => {
    if (onBeforeClick) {
      onBeforeClick()
    }
    dispatch(setModal(MODAL_CONFIRM_VERIFY_COMPONENT))
  }
  const unmarkComponentAsVerified = () => {
    if (onBeforeClick) {
      onBeforeClick()
    }
    dispatch(onUnmarkComponentAsVerified())
  }

  const openModal = (component) => {
    if (onBeforeClick) {
      onBeforeClick()
    }

    if (component.isNode) {
      dispatch(clearInlineComponents())
      if (component.category === 'device') {
        dispatch(setModal(MODAL_DEVICE_STATE))
      } else {
        dispatch(setModal(MODAL_COMPONENT_STATE))
      }
    } else if (component.isEdge) {
      if (component.edge_type === EDGE_TYPE_INLINE) {
        const source = component.source
        const target = component.target
        dispatch(setInlineComponents(source, target))
        dispatch(setModal(MODAL_COMPONENT_STATE))
      } else {
        dispatch(clearInlineComponents())
        dispatch(setModal(MODAL_HARNESS_STATE))
      }
    }
    dispatch(setComponent(component))
  }

  // set click handler dynamically
  const onVerifyButtonClick = isComponentVerified
    ? unmarkComponentAsVerified
    : openConfirmationModal

  return (
    <MustHave permission="component-fault:create" fallback={<div />}>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorBgContainer: WHITE_COLOR,
              defaultBg: WHITE_COLOR,
              borderColorDisabled: WHITE_COLOR,
              defaultBorderColor: VIVID_GREEN_COLOR,
              defaultColor: VIVID_GREEN_COLOR,
              defaultHoverBg: WHITE_COLOR,
              defaultHoverBorderColor: BRIGHT_GREEN_COLOR,
              defaultHoverColor: BRIGHT_GREEN_COLOR,
              colorErrorBorderHover: PASTEL_RED_COLOR,
            },
          },
        }}
      >
        <Flex gap="middle" align="space-between">
          <Button
            id="component-list-faulty-button"
            danger
            onClick={() => openModal(component)}
          >
            <FormattedMessage id="tracer.componentListPanel.logFailureButton" />
          </Button>
          {showVerifyButton && (
            <MustHave permission="component-verified:create">
              <Button
                id="component-list-verified-button"
                onClick={onVerifyButtonClick}
                disabled={disableVerifyComponentButton}
              >
                <FormattedMessage id="tracer.componentListPanel.logVerifyButton" />
              </Button>
            </MustHave>
          )}
          {!tooltip && (
            <AdditionalComponentFaultMenu
              component={component}
              openModal={openModal}
              deviceList={deviceList}
            />
          )}
        </Flex>
      </ConfigProvider>
    </MustHave>
  )
}

StateButtons.propTypes = propTypes
StateButtons.defaultProps = defaultProps

export default StateButtons
