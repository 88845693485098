import React, { useState } from 'react'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect, useDispatch } from 'react-redux'

import { formatTestZone, formatTimestamp } from '../helpers/utils'
import MustBeOnline from '../containers/MustBeOnline'
import MustBeOffline from '../containers/MustBeOffline'
import CantFixWidget from './CantFixWidget'
import CantFixReason from './CantFixReason'
import { useCantFixReason, useCantFix } from '../hooks/CantFix'
import MoreMenu from './MoreMenu'
import { setModal } from '../actions/modal-actions'
import { MODAL_REPAIR_HISTORY, MODAL_SWITCH_TEST } from '../constants'
import { HistoryOutlined, SwapOutlined } from '@ant-design/icons'
import { Flex } from 'antd'

const propTypes = {
  sequenceNumber: PropTypes.string,
  vin: PropTypes.string,
}

const defaultProps = {
  sequenceNumber: undefined,
  vin: '',
}

function VinPanel({
  intl,
  currentVehicleTestTimestamp,
  sequenceNumber,
  vin,
  vehicleTestId,
  testZoneDescription,
}) {
  const dispatch = useDispatch()
  const [error, cantFix] = useCantFix(vehicleTestId)
  const [status, cantFixReason] = useCantFixReason(vehicleTestId)
  const [isMenuVisible, setIsMenuVisible] = useState(false)

  const timestamp = formatTimestamp(
    intl.formatMessage({ id: 'format.dayAndTime' }),
    currentVehicleTestTimestamp,
  )

  const menuOptions = [
    <Flex
      className="menu-option"
      gap="middle"
      onClick={() => {
        dispatch(setModal(MODAL_REPAIR_HISTORY))
        setIsMenuVisible(false)
      }}
    >
      <HistoryOutlined />
      <span className="uppercase">
        <FormattedMessage id="tracer.repairHistoryButton" />
      </span>
    </Flex>,
    <Flex
      className="menu-option"
      gap="middle"
      onClick={() => {
        dispatch(setModal(MODAL_SWITCH_TEST))
        setIsMenuVisible(false)
      }}
    >
      <SwapOutlined />
      <span className="uppercase">
        <FormattedMessage id="tracer.switchTest.openModalButton" />
      </span>
    </Flex>,
  ]

  return (
    <div className={`vin-panel-container ${cantFix ? 'cantfix' : ''}`}>
      <div className="vin-panel">
        <div className="vin-panel-left">
          <div className="vin-item">
            <span className="gray">
              <FormattedMessage id="generic.vin" />:{' '}
            </span>
            <span>{vin}</span>
          </div>
          {sequenceNumber && (
            <div className="vin-item">
              <span className="gray">
                <FormattedMessage id="generic.sequenceNumber" />:{' '}
              </span>
              <span>{sequenceNumber}</span>
            </div>
          )}
          {timestamp && (
            <div className="current-test-info">
              <p className="uppercase">
                <span className="gray">
                  <FormattedMessage id="generic.current" />:{' '}
                </span>
                {formatTestZone(testZoneDescription)}
                {timestamp}
              </p>
            </div>
          )}
        </div>
        <Flex className="vin-panel-right" vertical={true} gap="middle">
          <MoreMenu
            menuOptions={menuOptions}
            isOpen={isMenuVisible}
            setIsOpen={setIsMenuVisible}
          />
          <MustBeOffline>
            {cantFixReason ? (
              <div className="cant-fix-container">
                <CantFixReason />
              </div>
            ) : null}
          </MustBeOffline>
        </Flex>
      </div>
      <MustBeOnline>
        <div className="cant-fix-container">
          <CantFixWidget />
          {cantFixReason && <CantFixReason />}
        </div>
      </MustBeOnline>
    </div>
  )
}

VinPanel.propTypes = propTypes
VinPanel.defaultProps = defaultProps

const mapStateToProps = (state) => {
  return {
    vehicleTestId: get(state, 'vehicleTests.currentVehicleTest.id'),
  }
}

export default injectIntl(connect(mapStateToProps, null)(VinPanel))
