import { LIGHT_MODE } from '../../../constants/misc'
import { Edge } from '../../reaflow'

export const BlockDiagramEdge = ({ onEdgeClick, ...props }) => {
  return (
    <Edge
      {...props}
      interpolation="linear"
      onClick={(event, edge) => {
        onEdgeClick({ event, edge })
      }}
    >
      {
        // Set default edge color.
        (event) => (
          <use
            href={`#${props.properties.id}`}
            strokeWidth="2pt"
            stroke={props.theme == LIGHT_MODE ? 'black' : '#8295b0'}
          />
        )
      }
    </Edge>
  )
}
