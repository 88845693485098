import React, { useEffect } from 'react'
import VehicleTestResultNavigation from '../VehicleTestResultNavigation'
import { FormattedMessage } from 'react-intl'
import { Button, Flex } from 'antd'
import './style.scss'

export const OverlayButtons = ({
  vehicleTestResultId,
  open,
  openFaultModalHandler,
  hrefParams = null,
}) => {
  useEffect(() => {
    const navigationButtons = document.getElementsByClassName(
      'overlay-navigation-container',
    )
    if (navigationButtons) {
      Array.from(navigationButtons).forEach((navigationButton) => {
        if (open) {
          navigationButton.style.width = 'calc(100% - 278px)'
        } else {
          navigationButton.style.width = '100%'
        }
      })
    }

    const faultButtons = document.getElementsByClassName('add-fault-button')
    if (faultButtons) {
      Array.from(faultButtons).forEach((faultButton) => {
        if (open) {
          faultButton.style.right = '298px'
        } else {
          faultButton.style.right = '20px'
        }
      })
    }
  }, [open])

  return (
    <>
      <Flex className="overlay-navigation-container" justify="center">
        <VehicleTestResultNavigation
          vehicleTestResultId={vehicleTestResultId}
          hrefParams={hrefParams}
        />
      </Flex>
      <Button
        onClick={openFaultModalHandler}
        data-testid="add-fault-button"
        className="add-fault-button"
      >
        <FormattedMessage id="repairHistory.titles.addFault" />
      </Button>
    </>
  )
}
