import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { useGenerateNavigationResult } from '../hooks/VehicleTestNavigation'
import Loading from '../components/Loading'

const NavigationRedirect = () => {
  const history = useHistory()
  const search = history.location.search
  const { vehicleTestResultId, navType } = useParams()
  const {
    data: { url },
    isSuccess,
  } = useGenerateNavigationResult({
    vehicleTestResultId,
    navType,
  })

  useEffect(() => {
    history.push({ pathname: url, search })
  }, [isSuccess, url, navType])

  return (
    <div>
      <Loading showText={false} />
    </div>
  )
}

export default NavigationRedirect
