import React, { useEffect, useState } from 'react'
import { ConfigProvider, theme as antdTheme } from 'antd'
import { CookiesProvider } from 'react-cookie'
import { IntlProvider } from 'react-intl'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import history from './history'
import App from './components/App'
import { locale, translations } from './Locale'
import {
  DARK_MODE,
  WHITE_COLOR,
  CHARCOAL_NAVY_COLOR,
  COMET_COLOR,
  CHARCOAL_BLUE_COLOR,
  LIGHT_GRAY_COLOR,
  GUNMETAL_COLOR,
  BLACK_COLOR,
  BRIGHT_GREY_COLOR,
  GRAY_COLOR,
  MEDIUM_GRAY_COLOR,
  TRANSPARENT,
  AZURE_RADIANCE_COLOR,
} from './constants'
import { customThemeGenerator } from './CustomTheme'
import { useSelector, shallowEqual } from 'react-redux'
import { BRAND_BLUE } from './constants/colors'

const queryClient = new QueryClient()

/* Define Root with Route Provider */
export default function Root() {
  const props = useSelector((state) => {
    return {
      theme: state.app.theme,
    }
  }, shallowEqual)
  const { defaultAlgorithm, darkAlgorithm } = antdTheme
  const [customTheme, setCustomTheme] = useState(
    customThemeGenerator(props.theme),
  )

  useEffect(() => {
    setCustomTheme(customThemeGenerator(props.theme))
  }, [props.theme])

  const themeComponentCreator = () => {
    let obj = {}
    if (props.theme == DARK_MODE) {
      obj = {
        Card: {
          colorBgContainer: CHARCOAL_BLUE_COLOR,
          colorBorderSecondary: COMET_COLOR,
        },
        Popover: {
          zIndexPopup: 8000,
          colorBgElevated: CHARCOAL_NAVY_COLOR,
        },
        Pagination: {
          itemActiveBg: WHITE_COLOR,
          colorBgContainer: '',
        },
        Layout: {
          headerBg: CHARCOAL_NAVY_COLOR,
        },
        Button: {
          colorBgContainer: CHARCOAL_NAVY_COLOR,
          defaultHoverBg: CHARCOAL_NAVY_COLOR,
          colorPrimary: WHITE_COLOR,
          primaryColor: CHARCOAL_NAVY_COLOR,
          colorPrimaryHover: LIGHT_GRAY_COLOR,
        },
        Radio: {
          buttonBg: CHARCOAL_NAVY_COLOR,
          buttonSolidCheckedBg: WHITE_COLOR,
          buttonSolidCheckedColor: COMET_COLOR,
          colorPrimaryHover: LIGHT_GRAY_COLOR,
        },
        Table: {
          colorBgContainer: CHARCOAL_NAVY_COLOR,
          colorBorderSecondary: COMET_COLOR,
          headerBg: LIGHT_GRAY_COLOR,
          headerColor: CHARCOAL_NAVY_COLOR,
          headerSplitColor: TRANSPARENT,
        },
        Select: {
          colorBgContainer: CHARCOAL_NAVY_COLOR,
          colorBgElevated: CHARCOAL_NAVY_COLOR,
        },
        Input: {
          colorBgContainer: CHARCOAL_NAVY_COLOR,
        },
        Modal: {
          contentBg: GUNMETAL_COLOR,
          headerBg: GUNMETAL_COLOR,
        },
        Tabs: {
          colorBgContainer: WHITE_COLOR,
          itemSelectedColor: CHARCOAL_NAVY_COLOR,
          itemHoverColor: LIGHT_GRAY_COLOR,
          horizontalMargin: '0 0 10px 0',
        },
      }
    } else {
      obj = {
        Popover: {
          zIndexPopup: 8000,
        },
        Card: {
          colorBgContainer: WHITE_COLOR,
          colorBorderSecondary: LIGHT_GRAY_COLOR,
        },
        Layout: {
          headerBg: WHITE_COLOR,
        },
        Table: {
          headerBg: LIGHT_GRAY_COLOR,
          headerColor: CHARCOAL_NAVY_COLOR,
          headerSplitColor: TRANSPARENT,
        },
        Tabs: {
          colorBgContainer: AZURE_RADIANCE_COLOR,
          itemSelectedColor: WHITE_COLOR,
          horizontalMargin: '0 0 10px 0',
        },
        Button: {
          colorPrimary: BRAND_BLUE,
        },
        Radio: {
          colorPrimary: BRAND_BLUE,
        },
      }
    }
    return obj
  }

  return (
    <ConfigProvider
      theme={{
        algorithm: props.theme == DARK_MODE ? darkAlgorithm : defaultAlgorithm,
        components: { ...themeComponentCreator() },
        token: {
          fontFamily: 'Montserrat',
          colorBgLayout:
            props.theme == DARK_MODE ? GUNMETAL_COLOR : BRIGHT_GREY_COLOR,
          colorLink: props.theme == DARK_MODE ? WHITE_COLOR : BLACK_COLOR,
          colorLinkHover:
            props.theme == DARK_MODE ? MEDIUM_GRAY_COLOR : GRAY_COLOR,
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <IntlProvider locale={locale} messages={translations}>
          <CookiesProvider>
            <ConnectedRouter history={history}>
              <Switch>
                <Route>
                  <App customTheme={customTheme} />
                </Route>
              </Switch>
            </ConnectedRouter>
          </CookiesProvider>
        </IntlProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ConfigProvider>
  )
}
