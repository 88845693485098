import React from 'react'
import { injectIntl } from 'react-intl'
import { Flex } from 'antd'
import BaseDiagram from './BaseDiagram'
import calculatePartition from './partitions'
import { useBlockDiagram } from './hooks'
import { getNodeWidthByContent, calculateTextSize } from './nodes/dimensions'
import edgeRouter from './edges/block/Router'
import {
  useDiagram,
  useDiagramDispatch,
} from '../FunctionalDiagrams/DiagramContext'
import nodeRouter from './nodes/block/Router'
import { Loadable } from '../components/Loading'

const BlockDiagram = ({
  intl,
  vin,
  subsystemId,
  hideDisconnectedParents = true,
  hideDisconnectedPorts = true,
  setRightPanelVisible,
  theme = null,
}) => {
  const diagram = useDiagram()
  const dispatch = useDiagramDispatch()

  const { isSuccess, isLoading, blockDiagramGraph, devices, connectors } =
    useBlockDiagram({ vin, subsystemId })

  const getNodeDimensions = ({ parent, ports }) => {
    const height = 40

    const width = getNodeWidthByContent({ parent, ports })

    return {
      width,
      height,
    }
  }

  // Returns neighbor component ids of the port.
  const getConnectorNeighbors = ({ connector }) => {
    return connector
      .parent()
      .children()
      .filter(
        (element) =>
          element.data('componentId') === connector.data('componentId'),
      )
      .openNeighborhood()
      .filter((element) => element.isNode())
      .map((node) => node.data('componentId'))
  }

  const onPortClick = ({ graph, port }) => {
    const {
      id,
      componentId,
      imageName,
      description,
      color,
      alias,
      locationImageName,
    } = port

    const neighborIds = getConnectorNeighbors({ connector: graph.$id(port.id) })
    const ids = graph
      .$id(id)
      .parent()
      .children()
      .filter((element) => element.data('componentId') === componentId)
      .map((port) => port.id())

    dispatch.blockDiagram.handleConnectorSelection({
      ids,
      componentId,
      imageName,
      description,
      color,
      alias,
      neighborIds,
      locationImageName,
    })
    setRightPanelVisible(true)
  }

  const onNodeClick = ({ graph, node, ids }) => {
    const {
      componentId,
      imageName,
      description,
      alias,
      locationImageName,
      category,
    } = node.data

    const neighborIds = graph
      .$id(node.id)
      .children()
      .reduce((neighbors, connector) => {
        neighbors.push(...getConnectorNeighbors({ connector }))
        return neighbors
      }, [])

    dispatch.blockDiagram.handleDeviceSelection({
      description,
      alias,
      componentId,
      imageName,
      ids,
      neighborIds,
      locationImageName,
      category,
    })
    setRightPanelVisible(true)
  }

  const onEdgeClick = ({ graph, edge }) => {
    const portsIds = graph
      .$id(edge.id)
      .connectedNodes()
      .map((port) => port.id())
    const fromComponent = graph.$id(edge.fromPort).data('componentId')
    const toComponent = graph.$id(edge.toPort).data('componentId')
    const fromComponentAlias = graph.$id(edge.fromPort).data('alias')
    const toComponentAlias = graph.$id(edge.toPort).data('alias')

    dispatch.blockDiagram.handleConnectionSelection({
      fromComponent,
      toComponent,
      fromComponentAlias,
      toComponentAlias,
      ids: [...portsIds, edge.id],
    })
    setRightPanelVisible(true)
  }

  const prepareNodes = (graph) => {
    const allParents = graph.nodes().filter((ele) => ele.isParent())

    const parents = hideDisconnectedParents
      ? allParents.filter(
          // Do not show parent nodes (devices) that are disconnected from other parent nodes via ports.
          (parent) => parent.children().connectedEdges().length > 0,
        )
      : allParents

    return parents.map((parent) => {
      const allChildren = parent.children()

      const children = hideDisconnectedPorts
        ? allChildren.filter(
            // Do not show ports that are disconnected from other ports.
            (child) => child.connectedEdges().length > 0,
          )
        : allChildren

      const ports = children.map((child) => {
        const { height, width } = calculateTextSize(child.data('label'))

        return {
          ...child.data(),
          id: child.id(),
          borderOffset: 10,
          width: width,
          height: height,
        }
      })

      const { width, height } = getNodeDimensions({ parent, ports })

      return {
        text: parent.data('label'),
        id: parent.id(),
        width,
        height,
        layoutOptions: {
          'org.eclipse.elk.portConstraints': 'FREE',
          'org.eclipse.elk.partitioning.partition': calculatePartition(parent),
        },
        data: {
          ...parent.data(),
        },
        ports,
      }
    })
  }

  const prepareEdges = (graph) => {
    return graph.edges().reduce((result, edge) => {
      const sourceParent = edge.source().parent()
      const targetParent = edge.target().parent()
      if (
        edge.data('source') &&
        edge.data('target') &&
        sourceParent.id() &&
        targetParent.id()
      ) {
        result.push({
          id: edge.id(),
          from: sourceParent.id(),
          to: targetParent.id(),
          fromPort: edge.data('source'),
          toPort: edge.data('target'),
          dataTestId: [edge.source().data('label'), edge.target().data('label')]
            .sort()
            .join('::'),
        })
      }
      return result
    }, [])
  }

  return (
    <>
      <Loadable isLoading={isLoading} showText={false}>
        {isSuccess && (
          <div className="block-diagram">
            <BaseDiagram
              data={blockDiagramGraph}
              prepareEdges={prepareEdges}
              prepareNodes={prepareNodes}
              edgeRouter={edgeRouter}
              nodeRouter={nodeRouter}
              onNodeClick={onNodeClick}
              onPortClick={onPortClick}
              onEdgeClick={onEdgeClick}
              selectionIds={diagram.blockDiagram.graph.data.ids}
              layoutOptions={
                {
                  //org.eclipse.elk.portAlignment': 'CENTER',
                }
              }
              theme={theme}
            />
          </div>
        )}
      </Loadable>
    </>
  )
}

export default injectIntl(BlockDiagram)
