import React from 'react'
import { Field } from 'redux-form'
import { Form, Button, Flex, Input } from 'antd'
import { required } from '../../validators'
import { injectIntl } from 'react-intl'
import Select from '../Fields/Select'

const FaultEditForm = (props) => {
  const {
    submit,
    formId,
    intl,
    faults,
    dtcOptions,
    selectedDtcs,
    onClose,
    annotations,
    updateAnnotation,
    categorySelectorDetails = null,
  } = props

  const generateLabelId = (label) => {
    return `field_entry_${label.replace(/\W+/g, '_').toLowerCase()}`
  }

  return (
    <Form
      layout="vertical"
      onFinish={submit}
      id={formId}
      className="fault-update-form"
    >
      {categorySelectorDetails != null && (
        <Form.Item
          label={intl.formatMessage({
            id: categorySelectorDetails.label,
          })}
          id={generateLabelId(
            intl.formatMessage({
              id: categorySelectorDetails.label,
            }),
          )}
        >
          <Select
            disabled={true}
            name={categorySelectorDetails.name}
            options={categorySelectorDetails.data}
            validate={[required]}
            placeholder={intl.formatMessage({
              id: categorySelectorDetails.placeholder,
            })}
          />
        </Form.Item>
      )}
      <Form.Item
        label={intl.formatMessage({
          id: 'repairHistory.add.form.faultTypeLabel',
        })}
        id={generateLabelId(
          intl.formatMessage({
            id: 'repairHistory.add.form.faultTypeLabel',
          }),
        )}
      >
        <Select
          name="item.reason"
          options={faults}
          validate={[required]}
          placeholder={intl.formatMessage({
            id: 'repairHistory.add.form.faultTypePlaceholder',
          })}
        />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({
          id: 'repairHistory.add.form.dtcCodesLabel',
        })}
        id={generateLabelId(
          intl.formatMessage({
            id: 'repairHistory.add.form.dtcCodesLabel',
          }),
        )}
      >
        <Select
          name="item.dtcs"
          options={dtcOptions}
          multiselect={true}
          defaultValue={selectedDtcs}
          placeholder={intl.formatMessage({
            id: 'repairHistory.add.form.faultTypePlaceholder',
          })}
        />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({
          id: 'repairHistory.add.form.notesLabel',
        })}
        id={generateLabelId(
          intl.formatMessage({
            id: 'repairHistory.add.form.notesLabel',
          }),
        )}
      >
        <Input.TextArea
          value={annotations}
          onChange={(e) => updateAnnotation(e.target.value)}
          rows={4}
          placeholder={intl.formatMessage({
            id: 'repairHistory.add.form.notesPlaceholder',
          })}
        />
      </Form.Item>
      <Flex vertical={false} gap="middle">
        <Button type="primary" htmlType="submit">
          {intl.formatMessage({
            id: 'forms.save',
          })}
        </Button>
        <Button onClick={onClose}>
          {intl.formatMessage({
            id: 'forms.cancel',
          })}
        </Button>
      </Flex>
    </Form>
  )
}

export default injectIntl(FaultEditForm)
