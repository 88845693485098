import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { FormattedMessage, injectIntl } from 'react-intl'
import ConnectorView from '../components/SvgViews/ConnectorView'
import {
  COMPONENT_CATEGORY_DEVICE,
  COMPONENT_CATEGORY_SPLICE,
} from '../constants/index'
import { getElementName } from '../helpers/element'
import SvgCircle from './SvgCircle'
import { Button, Flex, Row, Col, Table } from 'antd'

const propTypes = {
  selectedElement: PropTypes.object,
  showConnectorModal: PropTypes.func,
  svgCircleDiameter: PropTypes.number,
}

const defaultProps = {
  selectedElement: null,
  svgCircleDiameter: 26,
}

class PinDetails extends Component {
  renderComponentDetails(component) {
    if (!component._addPinsToComponents) {
      // You need to hold your horses and wait for the graph to add the pin data.
      return null
    }

    const pinDetails = []
    const pinNumbers = []
    if (component.pins) {
      for (const pin of component.pins.incoming) {
        if (pin) {
          let alias = getElementName(pin.source_component)
          if (alias !== pin.source_component.id) {
            alias += ` (${pin.source_component.id})`
          }
          pinDetails.push({
            pinNumber: pin.number,
            pinColor: pin.colors,
            pinColorDesc: pin.color_desc,
          })
          pinNumbers.push(pin.number)
        }
      }
      for (const pin of component.pins.outgoing) {
        let alias = getElementName(pin.target_component)
        if (alias !== pin.target_component.id) {
          alias += ` (${pin.target_component.id})`
        }
        pinDetails.push({
          pinNumber: pin.number,
          pinColor: pin.colors,
          pinColorDesc: pin.color_desc,
        })
        pinNumbers.push(pin.number)
      }
    }

    const columns = [
      {
        title: <FormattedMessage id="pinout.detail.component.pinNumber" />,
        dataIndex: ['pinNumber'],
      },
      {
        title: <FormattedMessage id="pinout.detail.component.pinColor" />,
        render: (text, record) => {
          return (
            <Flex vertical={false} gap="small" justify="center" align="center">
              {record.pinColorDesc}
              <SvgCircle
                colors={record.pinColor}
                diameter={this.props.svgCircleDiameter}
              />
            </Flex>
          )
        },
      },
    ]
    const connectorFileName =
      component.images && component.images.connector_file_name
        ? component.images.connector_file_name
        : undefined
    return (
      <Flex vertical gap={32} className="pin-details-left-pane">
        <div className="pin-details-left-pane-connector-details">
          <Row>
            <Col span={12}>
              <FormattedMessage id="pinout.detail.component.title" />
            </Col>
            <Col span={12}>{getElementName(component)}</Col>
          </Row>
          <Row>
            <Col span={12}>
              <FormattedMessage id="pinout.detail.component.id" />
            </Col>
            <Col span={12}>{component.id}</Col>
          </Row>
          {component.description && (
            <Row>
              <Col span={12}>
                <FormattedMessage id="pinout.detail.component.description" />
              </Col>
              <Col span={12}>{component.description}</Col>
            </Row>
          )}
          {component.category !== COMPONENT_CATEGORY_DEVICE &&
            component.total_pins && (
              <Row>
                <Col span={12} key="total_pins_header">
                  <FormattedMessage id="pinout.detail.component.totalPins" />
                </Col>
                <Col span={12} key="total_pins_value">
                  {component.total_pins}
                </Col>
              </Row>
            )}
        </div>
        <div className="pin-details-left-pane-connector-container">
          <div className="pin-details-table-container">
            <Table
              pagination={{
                pageSize: 3,
                hideOnSinglePage: true,
                showSizeChanger: false,
              }}
              columns={columns}
              dataSource={pinDetails}
            />
          </div>
          <Flex
            vertical
            gap="medium"
            justify="flex-start"
            className="connector-preview-container"
            align="center"
          >
            <div>
              <ConnectorView
                connectorName={getElementName(component)}
                pins={pinNumbers}
                imageUrl={connectorFileName}
                onClick={() =>
                  this.handleConnectorViewClick(pinNumbers, connectorFileName)
                }
              />
            </div>
            {connectorFileName && (
              <Button
                key={`view-connector-${connectorFileName}`}
                onClick={() =>
                  this.handleConnectorViewClick(pinNumbers, connectorFileName)
                }
                type="primary"
                className="connector-preview-button"
              >
                <FormattedMessage id="pinout.buttons.viewConnector" />
              </Button>
            )}
          </Flex>
        </div>
      </Flex>
    )
  }

  handleConnectorViewClick = (pins, imageUrl) => {
    this.props.showConnectorModal(pins, imageUrl)
  }

  render() {
    if (!isEmpty(this.props.selectedElement)) {
      return this.renderComponentDetails(this.props.selectedElement)
    }
    return null
  }
}

PinDetails.defaultProps = defaultProps
PinDetails.propTypes = propTypes

export default injectIntl(PinDetails)
