import React, { useRef, useState, useMemo } from 'react'
import { Canvas } from './reaflow'
import cytoscape from 'cytoscape'
import { useMeasure } from 'react-use'
import { injectIntl } from 'react-intl'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { Radio } from 'antd'
import {
  ZoomInControl as ZoomIn,
  ZoomOutControl as ZoomOut,
  ZoomResetControl as ZoomReset,
} from '../components/ZoomControls'

import './styles.scss'

function BaseDiagram({
  intl,
  prepareEdges,
  prepareNodes,
  data,
  layoutOptions = {},
  selectionIds = [],
  onPortClick,
  onEdgeClick,
  onNodeClick,
  edgeRouter,
  nodeRouter,
  diagramControlClassNames = [],
  refreshOnResize = false,
  preprocess = () => {},
  theme = null,
}) {
  const canvasRef = useRef(null)
  const [canvasSize, setCanvasSize] = useState({ height: 0, width: 0 })
  const [initialScale, setInitialScale] = useState(0)
  const [containerRef, { width: containerWidth, height: containerHeight }] =
    useMeasure()
  const [graph, setGraph] = useState(null)
  const [nodes, setNodes] = useState([])
  const [edges, setEdges] = useState([])

  React.useEffect(() => {
    resize()
  }, [canvasSize.height, canvasSize.width])

  React.useEffect(() => {
    console.log(containerHeight)
    console.log(containerWidth)
    if (refreshOnResize === true) {
      resize()
    }
  }, [containerHeight, containerWidth])

  const resize = () => {
    const scaleHeight = containerHeight / canvasSize.height
    const scaleWidth = containerWidth / canvasSize.width
    const scale = Math.round(Math.min(scaleHeight, scaleWidth) * 100) / 100
    const newScale = scale > 1.0 ? 1.0 : scale
    if (!Number.isNaN(newScale) && Number.isFinite(newScale) && newScale > 0) {
      setInitialScale((previousScale) =>
        previousScale !== newScale ? newScale : previousScale,
      )
    }
  }

  React.useEffect(() => {
    const graph = cytoscape({
      headless: true,
    })
    graph.json(data)
    setGraph(graph)
    preprocess(graph)
    setEdges(prepareEdges(graph))
    setNodes(prepareNodes(graph))
  }, [data])

  const handlePortClick = ({ event, port }) => {
    onPortClick({
      graph,
      port,
    })
  }

  const handleEdgeClick = ({ event, edge }) => {
    onEdgeClick({
      graph,
      edge,
    })
  }

  const handleNodeClick = ({ event, node }) => {
    onNodeClick({
      graph,
      node,
      ids: [node.id],
    })
  }

  return (
    <div
      className="base-diagram-container"
      ref={containerRef}
      style={{ height: '100%', width: '100%' }}
    >
      <TransformWrapper
        key={initialScale}
        wheel={{ step: 0.1 }}
        initialScale={initialScale}
        limitToBounds={false}
        minScale={-2}
        doubleClick={{ disabled: true }}
      >
        {({ zoomIn, zoomOut, resetTransform, centerView, ...rest }) => (
          <>
            <TransformComponent
              wrapperStyle={{
                height: containerHeight,
                width: containerWidth,
                display: 'grid',
                placeItems: 'center center',
              }}
            >
              <Canvas
                className="diagram-canvas"
                ref={canvasRef}
                fit={true}
                animated={true}
                zoomable={false}
                readonly={true}
                height={canvasSize.height}
                width={canvasSize.width}
                maxHeight={canvasSize.height}
                maxWidth={canvasSize.width}
                nodes={nodes}
                edges={edges}
                node={(node) =>
                  nodeRouter({
                    theme,
                    node,
                    onNodeClick: handleNodeClick,
                    onPortClick: handlePortClick,
                  })
                }
                edge={(edge) =>
                  edgeRouter({ edge, onEdgeClick: handleEdgeClick, theme })
                }
                arrow={null}
                onLayoutChange={(layout) => {
                  const newHeight = layout.height * 1.1
                  const newWidth = layout.width * 1.1
                  if (canvasSize.height === 0 || canvasSize.width === 0) {
                    setCanvasSize({
                      height: newHeight,
                      width: newWidth,
                    })
                  }
                }}
                selections={selectionIds}
                layoutOptions={{
                  'elk.nodeLabels.placement': 'INSIDE V_CENTER H_RIGHT',
                  'elk.algorithm': 'org.eclipse.elk.layered',
                  'elk.direction': 'DOWN',
                  'org.eclipse.elk.partitioning.activate': 'true',
                  'org.eclipse.elk.layered.layering.strategy':
                    'NETWORK_SIMPLEX',
                  'org.eclipse.elk.edgeRouting': 'ORTHOGONAL',
                  'org.eclipse.elk.layered.spacing.baseValue': '40',
                  'org.eclipse.elk.layered.nodePlacement.strategy':
                    'BRANDES_KOEPF',
                  'elk.layered.nodePlacement.bk.edgeStraightening':
                    'IMPROVE_STRAIGHTNESS',
                  'org.eclipse.elk.layered.nodePlacement.bk.fixedAlignment':
                    'BALANCED',
                  'org.eclipse.elk.layered.cycleBreaking.strategy':
                    'DEPTH_FIRST',
                  'org.eclipse.elk.insideSelfLoops.activate': 'true',
                  'org.eclipse.elk.separateConnectedComponents': 'false',
                  'org.eclipse.elk.spacing.componentComponent': '20', // Spacing to be preserved between pairs of connected components. This option is only relevant if ‘separateConnectedComponents’ is activated.
                  ...layoutOptions,
                }}
              />
            </TransformComponent>
            <Radio.Group
              value=""
              className={`zoom-control-button-group ${diagramControlClassNames}`}
            >
              <Radio.Button>
                <ZoomIn zoomIn={() => zoomIn()} />
              </Radio.Button>
              <Radio.Button>
                <ZoomReset zoomReset={() => resetTransform()} />
              </Radio.Button>
              <Radio.Button>
                <ZoomOut zoomOut={() => zoomOut()} />
              </Radio.Button>
            </Radio.Group>
          </>
        )}
      </TransformWrapper>
    </div>
  )
}

export default injectIntl(BaseDiagram)
