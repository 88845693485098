import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { unsetModal } from '../../actions/modal-actions'
import { FormattedMessage } from 'react-intl'
import TabContent from '../Navigation/TabContent'
import TabPane from '../Navigation/TabPane'
import {
  ADD_FAULT_COMPONENT_VIEW,
  ADD_FAULT_GENERIC_VIEW,
  ADD_FAULT_HARNESS_VIEW,
  ADD_FAULT_DEVICE_VIEW,
  ADD_FAULT_TABS_NAMESPACE,
} from '../../constants'
import NavigationTabs from '../Navigation/NavigationTabs'
import { useComponentSearch } from '../../hooks/ComponentSearch'
import { useComponentDetails } from '../../hooks/ComponentDetails'
import { useHarnessSearch } from '../../hooks/HarnessSearch'
import {
  resetComponent,
  setSearch,
  setSelectedComponent,
  setSelectedComponentId,
} from '../../actions/add-fault-component'
import Topology from '../SvgViews/Topology'
import { getDtcOptions } from '../../selectors/repairHistory'
import {
  addComponentProblem,
  addHarnessProblem,
  addOtherProblem,
  logDeviceProblem,
} from '../../api/controller-api'
import {
  resetHarness,
  setSearchHarness,
  setSelectedHarness,
} from '../../actions/add-fault-harness'
import SvgViewer from '../SvgViews/SvgViewer'
import {
  ZoomInControl,
  ZoomOutControl,
  ZoomResetControl,
} from '../ZoomControls'
import IconNoImage from '../Icons/IconNoImage'
import { selectNavigationTab } from '../../actions/tab-actions'
import {
  setSearchDevice,
  resetDevice,
  setSelectedDevice,
} from '../../actions/add-fault-device'
import { useDeviceSearch } from '../../hooks/DeviceSearch'
import { useCDN } from '../../hooks/CDN'
import { ArrowLeftOutlined } from '@ant-design/icons'
import ModalFullScreen from '../Modal/ModalFullScreen'
import { ButtonGroup } from './ButtonGroup'
import {
  Modal,
  Layout,
  Row,
  Col,
  Button,
  Select,
  Table,
  Input,
  Flex,
  Radio,
} from 'antd'
import './Fault.scss'
import { ErrorComponent } from '../ErrorImageComponent'

const { Header, Content } = Layout
const { TextArea } = Input

const ComponentsSearchTable = ({ componentSearchString }) => {
  const dispatch = useDispatch()
  const props = useSelector(
    (state) => ({
      vin: state.componentState.vin,
      selectedComponentId: state.addFaultComponent.selectedComponentId,
    }),
    shallowEqual,
  )
  const selectedComponentId = props.selectedComponentId

  const { components, isLoading, isSuccess } = useComponentSearch({
    searchString: componentSearchString,
    vin: props.vin,
  })
  const { componentDetails, isSuccess: componentDetailsIsSuccess } =
    useComponentDetails({ vin: props.vin, componentId: selectedComponentId })

  const doSelectComponent = (componentId) => {
    dispatch(resetComponent())
    dispatch(setSelectedComponentId(componentId))
  }

  React.useEffect(() => {
    if (componentDetailsIsSuccess)
      dispatch(setSelectedComponent(componentDetails.component))
  }, [componentDetails, selectedComponentId])

  const columns = [
    {
      title: <FormattedMessage id="addFaultComponent.columns.alias" />,
      dataIndex: ['component', 'alias'],
    },
    {
      title: <FormattedMessage id="addFaultComponent.columns.id" />,
      dataIndex: ['component', 'id'],
    },
    {
      title: <FormattedMessage id="addFaultComponent.columns.description" />,
      dataIndex: ['component', 'description'],
    },
  ]
  return (
    <div className="component-table">
      <div className="add-fault-table-header">
        {isLoading ? (
          <FormattedMessage id="addFaultComponent.loadingSearchRes" />
        ) : isSuccess ? (
          <FormattedMessage
            id="addFaultComponent.searchRes"
            values={{
              num: components.length,
              search: componentSearchString,
            }}
          />
        ) : (
          <FormattedMessage id="addFaultComponent.searchResLabel" />
        )}
      </div>
      <Table
        pagination={{
          pageSize: 3,
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        rowSelection={{
          selectedRowKeys: [selectedComponentId],
          type: 'radio',
          onChange: (selectedRowKeys) => doSelectComponent(selectedRowKeys[0]),
        }}
        onRow={(record) => ({
          onClick: () => {
            doSelectComponent(record.component.id)
          },
        })}
        columns={columns}
        dataSource={components}
        rowKey={(record) => record.component.id}
      />
    </div>
  )
}

const FaultComponentForm = ({ goBack, componentId }) => {
  const props = useSelector(
    (state) => ({
      vehicleTestResultId: state.componentState.vehicleTestResultId,
      makeModelId: state.componentState.makeModelId,
      vin: state.componentState.vin,
      typesOfFault: state.loggingState.faults.component.map((item) => ({
        value: item.key,
        label: item.key,
      })),
      dtcOptions: getDtcOptions(state),
    }),
    shallowEqual,
  )

  const [faultType, setFaultType] = React.useState('')
  const [dtcs, setDtcs] = React.useState([])
  const [notes, setNotes] = React.useState('')

  function submitFaultComponent() {
    addComponentProblem({
      makeModelId: props.makeModelId,
      vin: props.vin,
      vehicleTestResultId: props.vehicleTestResultId,
      componentId: componentId,
      isProblem: true,
      reason: faultType,
      dtcs: dtcs,
      annotation: notes,
    }).then(() => goBack())
  }

  return (
    <FormRenderer
      goBack={goBack}
      submit={submitFaultComponent}
      typesOfFault={props.typesOfFault}
      dtcOptions={props.dtcOptions}
      setFaultType={setFaultType}
      setDtcs={setDtcs}
      setNotes={setNotes}
      faultType={faultType}
      notes={notes}
    />
  )
}

const AddFaultComponent = ({ goBack }) => {
  const dispatch = useDispatch()
  // State from addFaultComponentReducer
  const props = useSelector((state) => state.addFaultComponent, shallowEqual)

  const doChangeSearchQuery = (e) => setSearchString(e.target.value)

  const doSearch = () => {
    dispatch(setSearch(searchString))
    dispatch(resetComponent())
  }

  const doSearchByPressEnter = (e) => searchString && doSearch()

  const [searchString, setSearchString] = React.useState(props.search)

  return (
    <Row data-testid="add-fault-component">
      <Col span={12} className="component-harness-fault-leftPane">
        <Topology component={props.selectedComponent} />
      </Col>
      <Col span={12} className="component-harness-fault-rightPane">
        <Flex vertical="true" style={{ height: '100%' }} gap="middle">
          <Col span={24} className="component-harness-fault-rightPane-top">
            <Row align="bottom" justify="space-between">
              <Col span={20}>
                <label htmlFor="search-input">
                  <FormattedMessage id="addFaultComponent.searchLabel" />
                </label>
                <Input
                  value={searchString}
                  onChange={doChangeSearchQuery}
                  onPressEnter={doSearchByPressEnter}
                />
              </Col>
              <Col span={3}>
                <Button onClick={doSearch} disabled={!searchString}>
                  <FormattedMessage id="addFaultComponent.search" />
                </Button>
              </Col>
            </Row>
            <Row className="component-harness-table-container">
              <ComponentsSearchTable componentSearchString={props.search} />
            </Row>
          </Col>
          <Col span={24} className="component-harness-fault-rightPane-bottom">
            {props.selectedComponentId && (
              <FaultComponentForm
                goBack={goBack}
                componentId={props.selectedComponentId}
              />
            )}
          </Col>
        </Flex>
      </Col>
    </Row>
  )
}

function getIdForHarness(harnessObj) {
  if (harnessObj) {
    const harnessId = `${harnessObj.harness}:${harnessObj.make_model_id}:${harnessObj.description}`
    const file_name = `${harnessObj.file_name}:${harnessObj.level}`
    return `${harnessId}:${file_name}:`
  } else {
    return ''
  }
}

const HarnessSearchTable = ({ searchString }) => {
  const dispatch = useDispatch()

  const props = useSelector(
    (state) => ({
      vin: state.componentState.vin,
      vehicleTestResultId: state.componentState.vehicleTestResultId,
      selectedHarness: state.addFaultHarness.selectedHarness,
    }),
    shallowEqual,
  )

  const { harnesses, isLoading, isSuccess } = useHarnessSearch({
    searchString,
    vehicleTestResultId: props.vehicleTestResultId,
    vin: props.vin,
  })

  const doSelectHarness = (harness) => {
    dispatch(resetHarness())
    dispatch(setSelectedHarness(harness))
  }

  const columns = [
    {
      title: <FormattedMessage id="addFaultHarness.columns.id" />,
      dataIndex: ['harness'],
    },
    {
      title: <FormattedMessage id="addFaultHarness.columns.description" />,
      dataIndex: ['description'],
    },
  ]

  return (
    <div className="harness-table">
      <div className="add-fault-table-header">
        {isLoading ? (
          <FormattedMessage id="addFaultHarness.loadingSearchRes" />
        ) : isSuccess ? (
          <FormattedMessage
            id="addFaultHarness.searchRes"
            values={{ num: harnesses.length, search: searchString }}
          />
        ) : (
          <FormattedMessage id="addFaultHarness.searchResLabel" />
        )}
      </div>
      <Table
        pagination={{
          pageSize: 3,
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        rowSelection={{
          selectedRowKeys: [getIdForHarness(props.selectedHarness)],
          type: 'radio',
          onChange: (selectedRowKeys, selectedRows) =>
            doSelectHarness(selectedRows[0]),
        }}
        onRow={(record) => ({
          onClick: () => {
            doSelectHarness(record)
          },
        })}
        columns={columns}
        dataSource={harnesses}
        rowKey={(record) => getIdForHarness(record)}
      />
    </div>
  )
}

const FaultHarnessForm = ({ goBack, harness }) => {
  const props = useSelector(
    (state) => ({
      vehicleTestResultId: state.componentState.vehicleTestResultId,
      makeModelId: state.componentState.makeModelId,
      vin: state.componentState.vin,
      typesOfFault: state.loggingState.faults.harness.map((item) => ({
        value: item.key,
        label: item.key,
      })),
      faults: state.loggingState.faults,
      dtcOptions: getDtcOptions(state),
    }),
    shallowEqual,
  )

  const [faultType, setFaultType] = React.useState('')
  const [dtcs, setDtcs] = React.useState([])
  const [notes, setNotes] = React.useState('')

  function submitFaultHarness() {
    addHarnessProblem({
      vehicleTestResultId: props.vehicleTestResultId,
      makeModelId: props.makeModelId,
      vin: props.vin,
      harness: harness.harness,
      harnessLevel: harness.level,
      reason: faultType,
      dtcs,
      isProblem: true,
      annotation: notes,
    }).then(() => goBack())
  }

  return (
    <FormRenderer
      goBack={goBack}
      submit={submitFaultHarness}
      typesOfFault={props.typesOfFault}
      dtcOptions={props.dtcOptions}
      setFaultType={setFaultType}
      setDtcs={setDtcs}
      setNotes={setNotes}
      faultType={faultType}
      notes={notes}
    />
  )
}

const ImageViewer = ({ fileName, noImageMsg = '' }) => {
  const { isSuccess, cdn } = useCDN()
  let zoomCtrl

  const zoomCenter = () => {
    zoomCtrl.resize()
    zoomCtrl.fit()
    zoomCtrl.center()
  }

  const onSvgReady = (svg, panZoom) => {
    zoomCtrl = panZoom

    svg.setAttribute('width', '100%')
    svg.setAttribute('height', '100%')

    zoomCenter()
  }

  return (
    <>
      {isSuccess && (
        <div className="image-viewer overflow-hidden">
          {fileName ? (
            <>
              <SvgViewer
                src={`${cdn.imagesURL}/${fileName}`}
                onReady={onSvgReady}
                panzoom={true}
                zoomEnabled={true}
              />
              <Radio.Group value="" className="zoom-control-button-group">
                <Radio.Button>
                  <ZoomInControl zoomIn={() => zoomCtrl.zoomIn()} />
                </Radio.Button>
                <Radio.Button>
                  <ZoomResetControl zoomReset={() => zoomCenter()} />
                </Radio.Button>
                <Radio.Button>
                  <ZoomOutControl zoomOut={() => zoomCtrl.zoomOut()} />
                </Radio.Button>
              </Radio.Group>
            </>
          ) : (
            <div className="image-viewer-no-image">
              <ErrorComponent>
                <h2>
                  <FormattedMessage id="imageViewer.noImage" />
                </h2>
                {noImageMsg && <p>{noImageMsg}</p>}
              </ErrorComponent>
            </div>
          )}
        </div>
      )}
    </>
  )
}

const AddFaultHarness = ({ goBack }) => {
  const dispatch = useDispatch()
  // State from addFaultHarnessReducer
  const props = useSelector((state) => state.addFaultHarness, shallowEqual)

  const doChangeSearchQuery = (e) => setSearchString(e.target.value)

  const doSearch = () => {
    dispatch(setSearchHarness(searchString))
    dispatch(resetHarness())
  }

  const doSearchByPressEnter = (e) => searchString && doSearch()

  const [searchString, setSearchString] = React.useState(props.search)

  return (
    <Row data-testid="add-fault-harness">
      <Col span={12} className="component-harness-fault-leftPane">
        <ImageViewer
          fileName={props.selectedHarness && props.selectedHarness.file_name}
          noImageMsg={<FormattedMessage id="addFaultHarness.noImage" />}
        />
      </Col>
      <Col span={12} className="component-harness-fault-rightPane">
        <Flex vertical="true" style={{ height: '100%' }} gap="middle">
          <Col span={24} className="component-harness-fault-rightPane-top">
            <Row align="bottom" justify="space-between">
              <Col span={20}>
                <label htmlFor="search-input">
                  <FormattedMessage id="addFaultHarness.searchLabel" />
                </label>
                <Input
                  value={searchString}
                  onChange={doChangeSearchQuery}
                  onPressEnter={doSearchByPressEnter}
                />
              </Col>
              <Col span={3}>
                <Button onClick={doSearch} disabled={!searchString}>
                  <FormattedMessage id="addFaultHarness.search" />
                </Button>
              </Col>
            </Row>
            <Row className="component-harness-table-container">
              <HarnessSearchTable searchString={props.search} />
            </Row>
          </Col>
          <Col span={24} className="component-harness-fault-rightPane-bottom">
            {props.selectedHarness && (
              <FaultHarnessForm
                goBack={goBack}
                harness={props.selectedHarness}
              />
            )}
          </Col>
        </Flex>
      </Col>
    </Row>
  )
}

const FaultDeviceForm = ({ goBack, device }) => {
  const props = useSelector(
    (state) => ({
      vehicleTestResultId: state.componentState.vehicleTestResultId,
      makeModelId: state.componentState.makeModelId,
      vin: state.componentState.vin,
      dtcOptions: getDtcOptions(state),
      typesOfFault: state.loggingState.faults.device.map((item) => ({
        value: item.key,
        label: item.key,
      })),
    }),
    shallowEqual,
  )

  const [faultType, setFaultType] = React.useState('')
  const [dtcs, setDtcs] = React.useState([])
  const [notes, setNotes] = React.useState('')

  function submitFaultDevice() {
    logDeviceProblem({
      annotation: notes,
      vehicleTestResultId: props.vehicleTestResultId,
      dtcs,
      makeModelId: props.makeModelId,
      reason: faultType,
      vin: props.vin,
      deviceName: device.id,
    }).then(() => goBack())
  }

  return (
    <FormRenderer
      goBack={goBack}
      submit={submitFaultDevice}
      typesOfFault={props.typesOfFault}
      dtcOptions={props.dtcOptions}
      setFaultType={setFaultType}
      setDtcs={setDtcs}
      setNotes={setNotes}
      faultType={faultType}
      notes={notes}
    />
  )
}

const DevicesSearchTable = ({ searchString }) => {
  const dispatch = useDispatch()

  const props = useSelector(
    (state) => ({
      vin: state.componentState.vin,
      makeModelId: state.componentState.makeModelId,
      selectedDevice: state.addFaultDevice.selectedDevice,
    }),
    shallowEqual,
  )

  const { devices, isLoading, isSuccess } = useDeviceSearch({
    searchString,
    makeModelId: props.makeModelId,
  })

  const doSelectDevice = (device) => {
    dispatch(resetDevice())
    dispatch(setSelectedDevice(device))
  }

  const columns = [
    {
      title: <FormattedMessage id="addFaultDevice.columns.id" />,
      dataIndex: ['id'],
    },
    {
      title: <FormattedMessage id="addFaultDevice.columns.description" />,
      dataIndex: ['description'],
    },
  ]

  return (
    <div className="device-table">
      <div className="device-header">
        {isLoading ? (
          <FormattedMessage id="addFaultDevice.loadingSearchRes" />
        ) : isSuccess ? (
          <FormattedMessage
            id="addFaultDevice.searchRes"
            values={{ num: devices.length, search: searchString }}
          />
        ) : (
          <FormattedMessage id="addFaultDevice.searchResLabel" />
        )}
      </div>
      <Table
        pagination={{
          pageSize: 3,
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        rowSelection={{
          selectedRowKeys: [
            props.selectedDevice ? props.selectedDevice.id : null,
          ],
          type: 'radio',
          onChange: (selectedRowKeys, selectedRows) =>
            doSelectDevice(selectedRows[0]),
        }}
        onRow={(record) => ({
          onClick: () => {
            doSelectDevice(record)
          },
        })}
        columns={columns}
        dataSource={devices}
        rowKey={(record) => record.id}
      />
    </div>
  )
}

const AddFaultDevice = ({ goBack }) => {
  const dispatch = useDispatch()
  // State from addFaultDeviceReducer
  const props = useSelector((state) => state.addFaultDevice, shallowEqual)

  const doChangeSearchQuery = (e) => setSearchString(e.target.value)

  const doSearch = () => {
    dispatch(setSearchDevice(searchString))
    dispatch(resetDevice())
  }

  const doSearchByPressEnter = (e) => searchString && doSearch()

  const [searchString, setSearchString] = React.useState(props.search)

  return (
    <Flex
      className="device-fault-wrapper"
      align="center"
      vertical="false"
      data-testid="add-fault-device"
    >
      <Row className="device-fault-wrapper-row">
        <Col span={24} className="device-fault-rightPane">
          <Flex vertical="true" gap="middle">
            <Col span={24} className="component-harness-fault-rightPane-top">
              <Row align="bottom" justify="space-between">
                <Col span={20}>
                  <label htmlFor="search-input">
                    <FormattedMessage id="addFaultDevice.searchLabel" />
                  </label>
                  <Input
                    value={searchString}
                    onChange={doChangeSearchQuery}
                    onPressEnter={doSearchByPressEnter}
                  />
                </Col>
                <Col span={3}>
                  <Button onClick={doSearch} disabled={!searchString}>
                    <FormattedMessage id="addFaultDevice.search" />
                  </Button>
                </Col>
              </Row>
              <Row className="component-harness-table-container">
                <DevicesSearchTable searchString={props.search} />
              </Row>
            </Col>
            <Col span={24} className="component-harness-fault-rightPane-bottom">
              {props.selectedDevice && (
                <FaultDeviceForm
                  goBack={goBack}
                  device={props.selectedDevice}
                />
              )}
            </Col>
          </Flex>
        </Col>
      </Row>
    </Flex>
  )
}

const FaultGenericForm = ({ goBack, classNames }) => {
  const props = useSelector(
    (state) => ({
      vehicleTestResultId: state.componentState.vehicleTestResultId,
      makeModelId: state.componentState.makeModelId,
      vin: state.componentState.vin,
      typesOfFault: state.loggingState.faults.other.map((item) => ({
        value: item.key,
        label: item.key,
      })),
      dtcOptions: getDtcOptions(state),
    }),
    shallowEqual,
  )

  const [faultType, setFaultType] = React.useState('')
  const [dtcs, setDtcs] = React.useState([])
  const [notes, setNotes] = React.useState('')

  function submit() {
    addOtherProblem({
      vehicleTestResultId: props.vehicleTestResultId,
      makeModelId: props.makeModelId,
      vin: props.vin,
      reason: faultType,
      dtcs,
      isProblem: true,
      annotation: notes,
    }).then(() => goBack())
  }

  return (
    <FormRenderer
      goBack={goBack}
      submit={submit}
      typesOfFault={props.typesOfFault}
      dtcOptions={props.dtcOptions}
      setFaultType={setFaultType}
      setDtcs={setDtcs}
      setNotes={setNotes}
      faultType={faultType}
      notes={notes}
      classNames={classNames}
    />
  )
}

const FormRenderer = ({
  goBack,
  typesOfFault,
  dtcOptions,
  setFaultType,
  setDtcs,
  setNotes,
  submit,
  faultType,
  notes,
  classNames,
}) => {
  return (
    <Flex
      vertical
      gap="middle"
      className={
        classNames
          ? `repair-history-form-wrapper ${classNames}`
          : 'repair-history-form-wrapper'
      }
      data-testid="add-fault-generic"
    >
      <div>
        <label htmlFor="type-of-fault">
          <FormattedMessage id="addFaultGeneric.typeOfFault" /> :
        </label>
        <Select
          id="type-of-fault"
          options={typesOfFault}
          onChange={(data) => setFaultType(data)}
          placeholder="Select type of fault"
          style={{ width: '100%' }}
        />
      </div>
      <div>
        <label htmlFor="dtc-codes">
          <FormattedMessage id="addFaultGeneric.dtcCodes" /> :
        </label>
        <Select
          id="dtc-codes"
          mode="multiple"
          allowClear
          options={dtcOptions}
          onChange={(values) => setDtcs(values.map((value) => value))}
          placeholder="Select DTC codes"
          style={{ width: '100%' }}
        />
      </div>
      <div>
        <label htmlFor="notes">
          <FormattedMessage id="addFaultGeneric.notes" /> :
        </label>
        <TextArea
          id="notes"
          rows={4}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </div>
      <ButtonGroup submit={submit} goBack={goBack} faultType={faultType} />
    </Flex>
  )
}

function AddFault() {
  const dispatch = useDispatch()
  const props = useSelector(
    (state) => ({
      vin: state.componentState.vin,
      isOpen: state.modalState.isOpen,
    }),
    shallowEqual,
  )

  const goBack = () => {
    dispatch(unsetModal())
    dispatch(resetComponent())
    dispatch(setSearch(''))
    dispatch(resetHarness())
    dispatch(setSearchHarness(''))
    dispatch(
      selectNavigationTab(ADD_FAULT_GENERIC_VIEW, ADD_FAULT_TABS_NAMESPACE),
    )
  }

  const header = () => {
    return (
      <Row>
        <Col span={6}>
          <Row gap={2}>
            <Col span={8}>
              <Button
                icon={<ArrowLeftOutlined />}
                onClick={goBack}
                data-testid="add-fault-modal-back-button"
              >
                Go Back
              </Button>
            </Col>
            <Col span={16} className="modal-title-container">
              <FormattedMessage id="repairHistory.titles.addFault" />
            </Col>
          </Row>
        </Col>
        <Col span={18}>
          <Row>
            <Col span={16}>
              <div
                className="header-fault-type-selection"
                data-testid="header-fault-type-selection"
              >
                <NavigationTabs
                  namespace={ADD_FAULT_TABS_NAMESPACE}
                  changeSidebarState={false}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  const content = () => {
    return (
      <TabContent>
        <TabPane
          id={ADD_FAULT_GENERIC_VIEW}
          namespace={ADD_FAULT_TABS_NAMESPACE}
          position={0}
          ignoreError={true}
          enabled
        >
          <FaultGenericForm goBack={goBack} classNames="generic-form-wrapper" />
        </TabPane>
        <TabPane
          id={ADD_FAULT_COMPONENT_VIEW}
          namespace={ADD_FAULT_TABS_NAMESPACE}
          position={1}
          ignoreError={true}
        >
          <AddFaultComponent goBack={goBack} />
        </TabPane>
        <TabPane
          id={ADD_FAULT_HARNESS_VIEW}
          namespace={ADD_FAULT_TABS_NAMESPACE}
          position={2}
          ignoreError={true}
        >
          <AddFaultHarness goBack={goBack} />
        </TabPane>
        <TabPane
          id={ADD_FAULT_DEVICE_VIEW}
          namespace={ADD_FAULT_TABS_NAMESPACE}
          position={3}
          ignoreError={true}
        >
          <AddFaultDevice goBack={goBack} />
        </TabPane>
      </TabContent>
    )
  }

  return (
    <ModalFullScreen
      ContentProp={content}
      HeaderProp={header}
      isOpen={props.isOpen}
      dataTestIdContainer={'add-fault-modal'}
      dataTestIdHeader={'add-fault-modal-header'}
      dataTestIdContent={'add-fault-modal-container'}
    />
  )
}

export default AddFault
