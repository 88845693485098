import {
  BLACK_COLOR,
  AZURE_RADIANCE_COLOR,
  BRIGHT_GREY_COLOR,
  CHARCOAL_BLUE_COLOR,
  CHARCOAL_NAVY_COLOR,
  GREEN_COLOR,
  GUNMETAL_COLOR,
  LIGHT_GRAY_COLOR,
  LIGHT_RED_COLOR,
  PICKLED_BLUEWOOD_COLOR,
  RED_COLOR,
  SKY_BLUE_COLOR,
  WHITE_COLOR,
  LIGHT_GREEN_COLOR,
  BRAND_BLUE,
  DARK_MODE,
} from './constants'

export const customThemeGenerator = (theme) => {
  return {
    '--color-border-custom': theme == DARK_MODE ? '#5A6382' : '#b3b3b3',
    '--color-text': theme == DARK_MODE ? WHITE_COLOR : BLACK_COLOR,
    '--color-background-alternate':
      theme == DARK_MODE ? PICKLED_BLUEWOOD_COLOR : '#666666',
    '--color-background-main':
      theme == DARK_MODE ? PICKLED_BLUEWOOD_COLOR : '#d9d9d9',
    '--color-background-collapsible-panel':
      theme == DARK_MODE ? GUNMETAL_COLOR : BRIGHT_GREY_COLOR,
    '--color-background-vehicle-test-side-panel-header':
      theme == DARK_MODE ? WHITE_COLOR : BRAND_BLUE,
    '--color-text-vehicle-test-side-panel-header':
      theme == DARK_MODE ? CHARCOAL_NAVY_COLOR : WHITE_COLOR,
    '--color-connector-preview-background':
      theme == DARK_MODE ? CHARCOAL_NAVY_COLOR : WHITE_COLOR,
    '--color-collapsable-sidepanel-background-header':
      theme == DARK_MODE ? WHITE_COLOR : BRAND_BLUE,
    '--color-collapsable-sidepanel-text':
      theme == DARK_MODE ? BLACK_COLOR : WHITE_COLOR,
    '--color-fill-pinout-table-circuit':
      theme == DARK_MODE ? WHITE_COLOR : BLACK_COLOR,
    '--color-form-container':
      theme == DARK_MODE ? CHARCOAL_BLUE_COLOR : WHITE_COLOR,
    '--color-text-editor-container':
      theme == DARK_MODE ? CHARCOAL_NAVY_COLOR : WHITE_COLOR,

    // dtc status
    '--color-dtc-active':
      theme == DARK_MODE ? AZURE_RADIANCE_COLOR : SKY_BLUE_COLOR,

    //component status
    '--color-component-indicate':
      theme == DARK_MODE ? AZURE_RADIANCE_COLOR : SKY_BLUE_COLOR,
    '--color-component-success':
      theme == DARK_MODE ? GREEN_COLOR : LIGHT_GREEN_COLOR,
    '--color-component-danger':
      theme == DARK_MODE ? RED_COLOR : LIGHT_RED_COLOR,

    //vehicle-config
    '--color-footer-custom':
      theme == DARK_MODE ? CHARCOAL_NAVY_COLOR : WHITE_COLOR,

    //functional-diagram
    '--color-diagram-edge-hover':
      theme == DARK_MODE ? SKY_BLUE_COLOR : AZURE_RADIANCE_COLOR,

    '--color-diagram-port-fill': theme == DARK_MODE ? '#3e405a' : '#D9D9D9',
    '--color-diagram-port-stroke': theme == DARK_MODE ? '#475872' : BLACK_COLOR,
    '--color-diagram-port-fill-active':
      theme == DARK_MODE ? '#0077cc' : '#E0F2FF',
    '--color-diagram-port-stroke-active': AZURE_RADIANCE_COLOR,
    '--color-diagram-port-stroke-hover':
      theme == DARK_MODE ? SKY_BLUE_COLOR : AZURE_RADIANCE_COLOR,
    '--color-diagram-port-label':
      theme == DARK_MODE ? WHITE_COLOR : BLACK_COLOR,
    '--color-diagram-port-label-active':
      theme == DARK_MODE ? WHITE_COLOR : AZURE_RADIANCE_COLOR,

    '--color-diagram-device-fill': theme == DARK_MODE ? '#2b2c3e' : WHITE_COLOR,
    '--color-diagram-device-stroke-unlinkable':
      theme == DARK_MODE ? '#ff005d' : '#cc004b',
    '--color-diagram-text-fill': theme == DARK_MODE ? WHITE_COLOR : BLACK_COLOR,
    '--color-diagram-text-stroke':
      theme == DARK_MODE ? CHARCOAL_NAVY_COLOR : LIGHT_GRAY_COLOR,
  }
}
