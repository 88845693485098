import React, { Component } from 'react'
import { Layout, Button } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import ConnectorView from './SvgViews/ConnectorView'
import ModalFullScreen from './Modal/ModalFullScreen'

const { Header, Content } = Layout

class ConnectorViewModal extends Component {
  constructor(props) {
    super(props)
    this.header = this.header.bind(this)
    this.content = this.content.bind(this)
  }

  header() {
    return (
      <Button icon={<ArrowLeftOutlined />} onClick={this.props.onClose}>
        Go Back
      </Button>
    )
  }

  content() {
    return (
      <ConnectorView
        pins={this.props.pins}
        imageUrl={this.props.imageUrl}
        panzoom={false}
      />
    )
  }

  render() {
    return (
      <ModalFullScreen
        HeaderProp={this.header}
        ContentProp={this.content}
        isOpen={this.props.isOpen}
        dataTestIdContainer={'connector-view-modal'}
        dataTestIdHeader={'connector-view-modal-header'}
        dataTestIdContent={'connector-view-modal-content'}
      />
    )
  }
}

export default ConnectorViewModal
