import get from 'lodash/get'
import React, { useState } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Modal, Button, Flex, Radio, Space } from 'antd'
import { MODAL_SUBMIT_FEEDBACK } from '../constants'
import {
  SET_MODAL_CONTAINER,
  CANT_FIX_SUBMIT_START,
} from '../actions/action-types'
import { INCORRECT_RECOMMENDATION } from './FeedbackForm'

import { unsetModal } from '../actions/modal-actions'

import {
  useCantFixReasons,
  useCantFix,
  useAddCantFix,
  useDeleteCantFix,
  useUpdateCantFix,
} from '../hooks/CantFix'

const ReasonInputs = ({ selectedReason, selectReason }) => {
  const [status, cantFixReasons] = useCantFixReasons()

  if (status === 'loading') {
    return (
      <div>
        <FormattedMessage id="cantFix.loading" />
      </div>
    )
  }
  const reasons = cantFixReasons.map((reason) => ({
    value: reason.id,
    label: reason.name,
  }))

  return (
    <Radio.Group
      value={selectedReason}
      onChange={(e) => selectReason(e.target.value)}
    >
      <Space direction="vertical">
        {reasons.map((reason) => {
          return <Radio value={reason.value}>{reason.label}</Radio>
        })}
      </Space>
    </Radio.Group>
  )
}

const CantFixForm = ({
  vehicleTestId,
  closeModal,
  openFeedbackModal,
  submitCantFix,
}) => {
  const [status, cantFix, error, isFetching, isLoading] =
    useCantFix(vehicleTestId)
  const [selectedReason, selectReason] = useState(
    cantFix ? cantFix.reason_id : null,
  )
  const addCantFix = useAddCantFix()
  const deleteCantFix = useDeleteCantFix()
  const updateCantFix = useUpdateCantFix()
  const [, cantFixReasons] = useCantFixReasons()

  const isFeedbackNeeded = () => {
    return cantFixReasons.some((reason) => {
      return reason.id == selectedReason && reason.needs_feedback
    })
  }

  const onSubmit = (event) => {
    event.preventDefault()

    const selectedReasonObj = cantFixReasons.find(
      (reason) => reason.id === selectedReason,
    )
    submitCantFix(selectedReasonObj && selectedReasonObj.name)

    const addingCantFix = !cantFix && selectedReason
    const updatingCantFix = cantFix && selectedReason

    if (updatingCantFix) {
      updateCantFix.mutate({ vehicleTestId, reasonId: selectedReason })
    } else if (addingCantFix) {
      addCantFix.mutate({ vehicleTestId, reasonId: selectedReason })
    } else if (cantFix && !selectedReason) {
      deleteCantFix.mutate({ vehicleTestId })
    }
    closeModal()

    if ((addingCantFix || updatingCantFix) && isFeedbackNeeded()) {
      openFeedbackModal()
    }
  }

  return (
    <Flex vertical={true} gap="middle" id="cant-fix-form-container">
      <Flex vertical={true}>
        <ReasonInputs
          selectedReason={selectedReason}
          selectReason={selectReason}
        />
      </Flex>
      <Flex vertical={false} justify="center" gap="middle">
        <Button onClick={closeModal}>
          <FormattedMessage id="forms.cancel" />
        </Button>
        <Button
          onClick={onSubmit}
          type="primary"
          disabled={selectedReason === null}
        >
          <FormattedMessage id="forms.submit" />
        </Button>
      </Flex>
    </Flex>
  )
}

const CantFixPopup = ({
  intl,
  vehicleTestId,
  isOpen,
  closeModal,
  openFeedbackModal,
  submitCantFix,
}) => {
  return (
    <Modal
      open={isOpen}
      title={intl.formatMessage({ id: 'cantFix.reason.title' })}
      onCancel={closeModal}
      footer={null}
      centered={true}
    >
      <CantFixForm
        vehicleTestId={vehicleTestId}
        closeModal={closeModal}
        openFeedbackModal={openFeedbackModal}
        submitCantFix={submitCantFix}
      />
    </Modal>
  )
}

const mapStateToProps = (state) => {
  return {
    vehicleTestId: get(state, 'vehicleTests.currentVehicleTest.id'),
    isOpen: state.modalState.isOpen,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(unsetModal()),
    openFeedbackModal: () =>
      dispatch({
        type: SET_MODAL_CONTAINER,
        container: MODAL_SUBMIT_FEEDBACK,
        initialOption: INCORRECT_RECOMMENDATION,
      }),
    submitCantFix: (selectedReason) =>
      dispatch({
        type: CANT_FIX_SUBMIT_START,
        selectedReason,
      }),
  }
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(CantFixPopup),
)
