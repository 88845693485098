import React from 'react'

import { MAIN_NAMESPACE } from '../../constants'
import NavigationTabs from './NavigationTabs'

const Navigation = ({ showViewNavigation }) => {
  return (
    <div>
      {showViewNavigation && <NavigationTabs namespace={MAIN_NAMESPACE} />}
    </div>
  )
}

Navigation.defaultProps = {
  showViewNavigation: false,
}

export default Navigation
