import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Popover, Flex } from 'antd'
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'
import MemoButton, { AddButton, EditButton } from './DTCMemoButton'
import MustHave from '../../containers/MustHave'
import { hideDtcMemoPopup } from '../../actions/dtc-actions'

const PopoverTitle = ({ title, onClick }) => (
  <>
    <span>{title}</span>
  </>
)

function DTCMemoPopup({
  dtc,
  makeModelId,
  acronym,
  intl,
  isSelected,
  children,
}) {
  const dispatch = useDispatch()
  const isDtcMemoPopupOpen = useSelector(
    (state) => state.dtcState.dtcMemoPopup,
    shallowEqual,
  )
  const title = (
    <PopoverTitle title={intl.formatMessage({ id: 'dtcMemo.label' })} />
  )
  const hasDtcMemo = !!dtc.memo && !!dtc.memo.memo

  const Content = () => {
    return (
      <Flex className="dtc-memo-view-body-content" vertical={true} gap="large">
        <Flex vertical={false} justify="space-between">
          <div>
            <div>
              <strong>{`${dtc.memo.dtc} - ${dtc.memo.acronym}`}</strong>
            </div>
            <div>
              <strong>
                <FormattedMessage id="dtcMemo.author" />:{' '}
              </strong>
              {dtc.memo.updated_by ? dtc.memo.updated_by : 'Tracer'}
            </div>
          </div>
          <div>
            <MustHave permission="dtc-memo:edit">
              <MemoButton
                dtc={dtc}
                makeModelId={makeModelId}
                acronym={acronym}
                editIcon={EditButton}
                addIcon={AddButton}
              />
            </MustHave>
          </div>
        </Flex>
        <div dangerouslySetInnerHTML={{ __html: dtc.memo.memo }} />
      </Flex>
    )
  }

  return (
    <Popover
      open={isDtcMemoPopupOpen && isSelected && hasDtcMemo}
      title={hasDtcMemo ? title : ''}
      id={`dtc-memo-${dtc.memo?.id}`}
      trigger="click"
      zIndex={9000}
      placement="left"
      overlayStyle={{
        width: '40vw',
      }}
      content={() => <Content />}
      onOpenChange={() => dispatch(hideDtcMemoPopup())}
    >
      {children}
    </Popover>
  )
}

export default injectIntl(DTCMemoPopup)
