import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import { LoadingOutlined } from '@ant-design/icons'
import { Spin, Flex } from 'antd'

const defaultProps = {
  showText: true,
  text: <FormattedMessage id="processes.loading" />,
}

class Loading extends Component {
  render() {
    return (
      <Flex
        className="loading-container"
        vertical={true}
        align="center"
        gap="middle"
        justify="center"
      >
        {this.props.showText && <p>{this.props.text}</p>}
        <Spin indicator={<LoadingOutlined spin style={{ fontSize: 70 }} />} />
      </Flex>
    )
  }
}

Loading.defaultProps = defaultProps

export function Loadable({ isLoading, showText, text, children }) {
  if (isLoading) {
    return <Loading showText={showText} text={text} />
  }
  return children
}

export default Loading
