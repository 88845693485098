import React from 'react'
import { FormattedMessage } from 'react-intl'

import IconNoGraph from '../components/Icons/IconNoGraph'
import { ErrorComponent } from '../components/ErrorImageComponent'

function NoGraph() {
  return (
    <ErrorComponent>
      <IconNoGraph />
      <h2>
        <FormattedMessage id="tracer.harnessView.noGraph.header" />
      </h2>
      <p>
        <FormattedMessage id="tracer.harnessView.noGraph.embeddedModeMessage" />
      </p>
    </ErrorComponent>
  )
}

export default NoGraph
