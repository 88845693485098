import React from 'react'
import { Spin, Flex } from 'antd'

import './style.scss'

export default function Loading() {
  return (
    <Flex
      align="middle"
      justify="center"
      className="functional-diagrams-loading"
    >
      <Spin size="large" />
    </Flex>
  )
}
