import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'

import { getPowerDistributionInformation } from '../api/power-distribution-api'
import PowerDistributionBoxView from '../components/SvgViews/PowerDistributionBoxView'
import Loading from './Loading'
import { Flex } from 'antd'
import { ErrorComponent } from './ErrorImageComponent'

export function usePowerDistributionInformation(
  vehicleTestId,
  componentId,
  pin,
) {
  const [imageName, setImageName] = useState(null)
  const [fuses, setFuses] = useState([])
  const [relays, setRelays] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const effectDeps = []

  useEffect(() => {
    async function handler() {
      setIsLoading(true)

      const data = await getPowerDistributionInformation(
        vehicleTestId,
        componentId,
        pin,
      )
      if (data) {
        const { imageName: imageFileName, fuses, relays } = data
        setImageName(imageFileName)
        if (fuses) {
          setFuses(fuses)
        }
        if (relays) {
          setRelays(relays)
        }
      }
      setIsLoading(false)
    }

    handler()
  }, effectDeps)

  return { imageName, fuses, relays, isLoading }
}

const NoImage = injectIntl(() => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ErrorComponent>
        <h2>
          <FormattedMessage id="tracer.fusesAndRelaysView.noComponent.header" />
        </h2>
        <p>
          <FormattedMessage id="tracer.fusesAndRelaysView.noComponent.message" />
        </p>
      </ErrorComponent>
    </div>
  )
})

function FusesAndRelays(props) {
  const { vehicleTestId, componentId, connectorName, pin } = props
  const { theme } = useSelector((state) => {
    return {
      theme: state.app.theme,
    }
  })
  const { imageName, fuses, relays, isLoading } =
    usePowerDistributionInformation(vehicleTestId, componentId, pin)
  const units = [...fuses, ...relays]

  if (isLoading) {
    return <Loading showText={false} />
  }

  if (!imageName) {
    return <NoImage />
  }

  return (
    <Flex className="fuses-and-relays-container" vertical={false}>
      <div className="power-distribution-box-image">
        <PowerDistributionBoxView
          units={units}
          imageName={imageName}
          connectorName={connectorName}
          theme={theme}
        />
      </div>
      <Flex className="units" vertical={true}>
        {fuses.map((fuse) => (
          <div className="unit" key={fuse.name}>
            <div className="unit-title">
              <FormattedMessage id="tracer.fusesAndRelaysView.fuse" />:{' '}
              {fuse.name}
            </div>
            <div className="current-amperage">
              <FormattedMessage id="tracer.fusesAndRelaysView.currentAmpere" />:{' '}
              {fuse.currentAmpere}
            </div>
            <div className="unit-pin">
              <FormattedMessage id="tracer.fusesAndRelaysView.pin" />:{' '}
              {fuse.pin}
            </div>
          </div>
        ))}

        {relays.map((relay) => (
          <div className="unit" key={relay.name}>
            <div className="unit-title">
              <FormattedMessage id="tracer.fusesAndRelaysView.relay" />:{' '}
              {relay.name}
            </div>

            <div className="unit-pin">
              <FormattedMessage id="tracer.fusesAndRelaysView.pin" />:{' '}
              {relay.pin}
            </div>
          </div>
        ))}
      </Flex>
    </Flex>
  )
}

export default injectIntl(FusesAndRelays)
