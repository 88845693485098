import React, { Component } from 'react'
import Sidebar from 'react-sidebar'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Button } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import DTCListContainer from '../containers/DTCListContainer'
import ListPanel from './ListPanel/ListPanel'
import isEmpty from 'lodash/isEmpty'

class CollapsibleSidePanel extends Component {
  onSetSidebarOpen = (open) => {
    this.props.setOpen(open)
  }

  toggle = () => {
    this.onSetSidebarOpen(!this.props.open)
  }

  render() {
    const props = {
      title: this.props.intl.formatMessage({ id: 'tracer.dtcListPanel.title' }),
      scrolling: true,
      onHeaderClick: this.toggle,
      onScroll: this.props.onScroll,
      toggleShowHideDtcMemoEdit: this.props.toggleShowHideDtcMemoEdit,
      isDtcMemoEditShown: this.props.isDtcMemoEditShown,
      id: 'dtc-list-container',
      isEphemeralVehicleTest: this.props.isEphemeralVehicleTest,
    }
    const dtcDependencies = this.props.dtcDependencies
    const sidebarContent = (
      <ListPanel {...props}>
        {dtcDependencies && dtcDependencies.length > 0 && (
          <Button
            onClick={this.props.onClickDtcDependencies}
            data-cy="not-relevant-ctrl"
            className="not-relevant-ctrl"
          >
            <FormattedMessage
              id="dtcDependencies.title"
              values={{ total: dtcDependencies.length }}
            />
          </Button>
        )}
        <div className="sidepanel-container">
          <DTCListContainer />
        </div>
      </ListPanel>
    )

    return (
      <Sidebar
        defaultSidebarWidth={278}
        sidebarClassName="right-sidebar"
        contentClassName="right-sidebar-content"
        touch={false}
        sidebar={sidebarContent}
        transitions={false}
        shadow={false}
        open={false}
        onSetOpen={this.onSetSidebarOpen}
        docked={this.props.open}
        pullRight={true}
        styles={{
          sidebar: {
            overflowY: 'initial',
          },
        }}
      >
        <a className="button-collapse" onClick={this.toggle}>
          {this.props.open ? <LeftOutlined /> : <RightOutlined />}
        </a>
      </Sidebar>
    )
  }
}

export default injectIntl(CollapsibleSidePanel)
