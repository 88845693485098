import React from 'react'

import { WHITE_COLOR, GREEN_COLOR } from '../../constants'

const defaultProps = {
  fill: GREEN_COLOR,
  circleStroke: WHITE_COLOR,
  stroke: WHITE_COLOR,
  enableOutline: true,
}

const IconOk = (props) => {
  let circleStroke = props.circleStroke
  if (!props.enableOutline) {
    circleStroke = props.fill
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(2 2)">
          <circle fill={props.fill} cx="8" cy="8" r="9" />
          <circle cx="8" cy="8" r="9" stroke={circleStroke} strokeWidth="2" />
        </g>
        <path
          stroke={props.stroke}
          strokeWidth="2"
          d="M13.735 7.164l-5.412 5.523L6 10.571"
        />
      </g>
    </svg>
  )
}

IconOk.defaultProps = defaultProps

export default IconOk
