import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { useLocation } from 'react-router-dom'

import HarnessViewGraphContainer from '../../containers/HarnessViewGraphContainer'
import {
  ZoomInControl,
  ZoomOutControl,
  ZoomResetControl,
} from '../ZoomControls'
import { setModal } from '../../actions/modal-actions'
import { MODAL_ADD_FAULT, ROOT_CAUSE_QUERY_PARAM } from '../../constants'
import { OverlayButtons } from '../OverlayButtons/OverlayButtons'

const HarnessView = (props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const { open } = props

  useEffect(() => {
    if (params.has(ROOT_CAUSE_QUERY_PARAM)) {
      dispatch(setModal(MODAL_ADD_FAULT))
    }
  }, [location.search])

  const graphNodes = useSelector(
    (state) => state.componentState.graphData.nodes,
    shallowEqual,
  )
  const vehicleTestResultId = useSelector(
    (state) => state.componentState.vehicleTestResultId,
  )
  const isEphemeralVehicleTest = useSelector(
    (state) => state.ephemeralVehicleTest.isEphemeralVehicleTest,
    shallowEqual,
  )

  const openFaultModalHandler = () => dispatch(setModal(MODAL_ADD_FAULT))

  if (isEmpty(graphNodes)) return null

  return (
    <>
      <HarnessViewGraphContainer
        zoomInControl={ZoomInControl}
        zoomOutControl={ZoomOutControl}
        zoomResetControl={ZoomResetControl}
      />
      {!isEphemeralVehicleTest && (
        <OverlayButtons
          open={open}
          vehicleTestResultId={vehicleTestResultId}
          openFaultModalHandler={openFaultModalHandler}
        />
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    open: state.sidebar.open,
  }
}

export default connect(mapStateToProps)(HarnessView)
