import * as types from '../actions/action-types'

export function setMainDashboardPageNum(pageNum) {
  return {
    type: types.SET_MAIN_DASHBOARD_PAGE_NUM,
    pageNum,
  }
}

export function setVIN(vin) {
  return {
    type: types.SET_VIN,
    vin,
  }
}

export function resetVIN() {
  return {
    type: types.RESET_VIN,
  }
}

export function updateTheme(updatedTheme) {
  return {
    type: types.TOGGLE_THEME,
    updatedTheme,
  }
}
