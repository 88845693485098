import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { useRecentSubsystems } from './hooks'
import classNames from 'classnames'
import { requireAuthentication } from '../helpers/authentication'
import './style.scss'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useLocation, useHistory } from 'react-router-dom'
import {
  Input,
  Button,
  Radio,
  Table,
  Drawer,
  Checkbox,
  Row,
  Col,
  Empty,
  Breadcrumb,
  Space,
  Skeleton,
  Spin,
  Tabs,
  Flex,
} from 'antd'
import {
  SearchOutlined,
  FilterOutlined,
  LayoutOutlined,
  LeftOutlined,
  HomeOutlined,
  LoadingOutlined,
} from '@ant-design/icons'

import {
  BlockDiagram,
  useBlockDiagram,
  useSubsystems,
  useComponentPinout,
} from '../BlockDiagram'
import { useGlobalSearch } from '../hooks/GlobalSearch'
import { useDiagram, useDiagramDispatch } from './DiagramContext'
import { ComponentOrLocationImage, LocationImage } from './Image'
import { ConnectorPreview } from '../components/ConnectorPreview/index'
import ConnectorPinout from './ConnectorPinout/BlockDiagramPinout'
import DiagramDetailView from './DiagramDetailView'
import { RelatedSubsystemsButton } from '../components/RelatedSubsystemsButton'
import { useSearchSubsystems } from '../BlockDiagram/hooks'
import { SubsystemsViewTable, searchList } from '../components/SubsystemTable'
import { useFuseDetails } from '../hooks/PowerDistribution'
import { useSelector, shallowEqual } from 'react-redux'
import { Loadable } from '../components/Loading'

const DIAGRAMS_VIEW = 'diagrams_view'
const RECENT_VIEW = 'recent_view'

const DIAGRAMS_RECENT_OPTS = [
  {
    label: (
      <FormattedMessage id="functionalDiagrams.diagramRecentOptsLabel.diagramLabel" />
    ),
    value: DIAGRAMS_VIEW,
  },
  {
    label: (
      <FormattedMessage id="functionalDiagrams.diagramRecentOptsLabel.recentLabel" />
    ),
    value: RECENT_VIEW,
  },
]

const DEVICES_VIEW = 'device'
const CONNECTORS_VIEW = 'connector'

const DEVICES_CONNECTORS_OPTS = [
  {
    label: (
      <FormattedMessage id="functionalDiagrams.deviceConnectorsOptsLabel.deviceLabel" />
    ),
    value: DEVICES_VIEW,
  },
  {
    label: (
      <FormattedMessage id="functionalDiagrams.deviceConnectorsOptsLabel.connectorsLabel" />
    ),
    value: CONNECTORS_VIEW,
  },
]

const devicesTableColumns = [
  {
    title: <FormattedMessage id="functionalDiagrams.deviceTable.name" />,
    dataIndex: 'componentId',
  },
  {
    title: <FormattedMessage id="functionalDiagrams.deviceTable.description" />,
    dataIndex: 'description',
  },
]

const connectorsTableColumns = [
  {
    title: <FormattedMessage id="functionalDiagrams.connectorTable.name" />,
    dataIndex: 'componentId',
  },
  {
    title: (
      <FormattedMessage id="functionalDiagrams.connectorTable.description" />
    ),
    dataIndex: 'description',
  },
]

function DiagramsOrRecentControlsHeader(props) {
  const [searchFieldVisible, setSearchFieldVisible] = useState(false)
  const diagram = useDiagram()
  const dispatch = useDiagramDispatch()

  const {
    diagramOrRecentTab,
    setSubsystemOrRecentTab,
    setFiltersVisible,
    numOfSelectedFilters,
    searchString,
    setSearchString,
    intl,
  } = props

  const toggleSearchBar = () => {
    setSearchFieldVisible(!searchFieldVisible)
    setSearchString('')
  }

  return (
    <div>
      <div className="functional-diagrams-breadcrumb">
        <LeftOutlined
          onClick={() => null}
          className="back-button button-disabled"
        />
        <div className="functional-diagrams-breadcrumb-wrapper">
          <Breadcrumb>
            <Breadcrumb.Item>
              <HomeOutlined onClick={() => dispatch.handleDiagramReset()} />
            </Breadcrumb.Item>
            {diagram.subsystem && (
              <Breadcrumb.Item>{diagram.subsystem?.name}</Breadcrumb.Item>
            )}
          </Breadcrumb>
        </div>
      </div>
      <Flex
        vertical={false}
        justify="space-between"
        className="functional-diagrams-right-controls-header"
      >
        <Button
          icon={searchFieldVisible ? <LeftOutlined /> : <SearchOutlined />}
          onClick={toggleSearchBar}
        />
        {searchFieldVisible ? (
          <>
            <SearchInput
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
              intl={intl}
            />
          </>
        ) : (
          <>
            <Radio.Group
              options={DIAGRAMS_RECENT_OPTS}
              onChange={(e) => setSubsystemOrRecentTab(e.target.value)}
              value={diagramOrRecentTab}
              optionType="button"
              buttonStyle="solid"
            />
            <Flex vertical={false} gap="small">
              {numOfSelectedFilters > 0 && (
                <div className="functional-diagrams-filter-selection-info">
                  {`${numOfSelectedFilters} filters applied`}
                </div>
              )}
              <Button
                icon={<FilterOutlined />}
                onClick={() => setFiltersVisible(true)}
              />
            </Flex>
          </>
        )}
      </Flex>
    </div>
  )
}

const SearchInput = ({ value, onChange, intl }) => {
  return (
    <Input
      autoFocus
      allowClear
      className="functional-diagrams-search"
      placeholder={intl.formatMessage({
        id: 'functionalDiagrams.searchInputLabel',
      })}
      value={value}
      onChange={onChange}
    />
  )
}

function DevicesOrConnectorsHeader(props) {
  const diagram = useDiagram()
  const dispatch = useDiagramDispatch()
  const {
    searchString,
    setSearchString,
    searchFieldVisible,
    setSearchFieldVisible,
    intl,
  } = props

  const toggleSearchBar = () => {
    setSearchFieldVisible(!searchFieldVisible)
    setSearchString('')
  }

  return (
    <Flex
      vertical={false}
      justify="space-between"
      className="functional-diagrams-right-controls-header"
    >
      <Button
        icon={searchFieldVisible ? <LeftOutlined /> : <SearchOutlined />}
        onClick={toggleSearchBar}
      />
      {searchFieldVisible ? (
        <>
          <SearchInput
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            intl={intl}
          />
        </>
      ) : (
        <>
          <Radio.Group
            options={DEVICES_CONNECTORS_OPTS}
            onChange={(e) => {
              dispatch.blockDiagram.handleTabChange({ tab: e.target.value })
            }}
            value={diagram.blockDiagram.tab}
            optionType="button"
            buttonStyle="solid"
          />
          <div className="space-stub" />
        </>
      )}
    </Flex>
  )
}

const DeviceTable = ({ devices, searchString }) => {
  const dispatch = useDiagramDispatch()
  const diagram = useDiagram()
  return (
    <Table
      rowSelection={{
        type: 'radio',
        onSelect: (record, selected, selectedRows, nativeEvent) => {
          dispatch.blockDiagram.handleDeviceTableSelection({
            componentId: record.componentId,
            ids: [record.componentId],
            alias: record.alias,
          })
        },
        selectedRowKeys: [diagram.blockDiagram.table.data.componentId],
      }}
      size="small"
      dataSource={searchList({
        items: devices?.map((item) => {
          return {
            ...item,
            key: item.componentId,
          }
        }),
        searchString,
        fields: [['componentId'], ['description']],
      })}
      columns={devicesTableColumns}
      rowKey={(record) => record.id}
      data-testid="devices-table"
      pagination={{
        pageSize: 15,
        hideOnSinglePage: true,
      }}
    />
  )
}

const ConnectorTable = ({ connectors, searchString }) => {
  const dispatch = useDiagramDispatch()
  const diagram = useDiagram()
  return (
    <Table
      rowSelection={{
        type: 'radio',
        onSelect: (record, selected, selectedRows, nativeEvent) => {
          dispatch.blockDiagram.handleConnectorTableSelection({
            componentId: record.componentId,
            ids: record.ids,
            imageName: record.imageName,
            alias: record.alias,
          })
        },
        selectedRowKeys: [diagram.blockDiagram.table.data.componentId],
      }}
      size="small"
      dataSource={searchList({
        items: connectors?.map((item) => {
          return {
            ...item,
            key: item.componentId,
          }
        }),
        searchString,
        fields: [['componentId'], ['description']],
      })}
      columns={connectorsTableColumns}
      data-testid="connectors-table"
      pagination={{
        pageSize: 15,
        hideOnSinglePage: true,
      }}
    />
  )
}

const ComponentTable = ({ intl }) => {
  const diagram = useDiagram()

  const { isSuccess, isLoading, devices, connectors } = useBlockDiagram({
    vin: diagram.vin,
    subsystemId: diagram.subsystem?.id,
  })
  const [searchString, setSearchString] = useState('')
  const [searchFieldVisible, setSearchFieldVisible] = useState(false)

  return (
    <Skeleton
      loading={isLoading}
      paragraph={{ rows: 10 }}
      className="functional-diagrams-loading-padding"
    >
      <DevicesOrConnectorsHeader
        {...{
          searchFieldVisible,
          setSearchFieldVisible,
          searchString,
          setSearchString,
          intl,
        }}
      />
      <div className="functional-diagram-table-wrapper">
        {diagram.blockDiagram.tab === 'device' && (
          <DeviceTable devices={devices} searchString={searchString} />
        )}
        {diagram.blockDiagram.tab === 'connector' && (
          <ConnectorTable connectors={connectors} searchString={searchString} />
        )}
      </div>
    </Skeleton>
  )
}

function DevicesOrConnectors({ intl }) {
  const diagram = useDiagram()
  const dispatch = useDiagramDispatch()

  const back = () => {
    if (diagram.blockDiagram.graph.selection) {
      dispatch.blockDiagram.reset()
    } else {
      dispatch.handleDiagramReset()
    }
  }

  return (
    <>
      <div className="functional-diagrams-breadcrumb">
        <LeftOutlined onClick={back} className="back-button" />
        <div className="functional-diagrams-breadcrumb-wrapper">
          <Breadcrumb>
            <Breadcrumb.Item>
              <HomeOutlined
                className="clickable"
                onClick={() => {
                  dispatch.handleDiagramReset()
                }}
              />
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className={classNames({
                clickable: diagram.blockDiagram.graph.selection,
              })}
              onClick={() => dispatch.blockDiagram.reset()}
            >
              {diagram.subsystem?.name}
            </Breadcrumb.Item>
            {diagram.blockDiagram.graph.selection && (
              <Breadcrumb.Item>
                {diagram.blockDiagram.graph.data.alias}
              </Breadcrumb.Item>
            )}
          </Breadcrumb>
        </div>
      </div>
      {diagram.blockDiagram.tab && <ComponentTable intl={intl} />}
      {!diagram.blockDiagram.tab &&
        diagram.blockDiagram.graph.selection === 'device' && <ConnectorView />}
      {!diagram.blockDiagram.tab &&
        diagram.blockDiagram.graph.selection === 'connector' && (
          <ConnectorView />
        )}
    </>
  )
}

function LeftPanel(props) {
  const { rightPanelVisible, setRightPanelVisible, theme } = props
  const diagram = useDiagram()
  const { searchSubsystems } = useSearchSubsystems({
    vin: diagram.vin,
    componentId: diagram.detailDiagram.relatedSubsystemComponentID,
  })
  return (
    <Flex
      className={classNames('functional-diagrams-left-panel', {
        expand: !rightPanelVisible,
      })}
      vertical={true}
    >
      <div className="functional-diagrams-left-panel-header">
        <div className="functional-diagrams-breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              {diagram.subsystem
                ? `${diagram.subsystem?.system.name} — ${diagram.subsystem?.name}`
                : 'No selection'}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="functional-diagrams-expand-button">
          <Button
            icon={<LayoutOutlined />}
            onClick={() => setRightPanelVisible(!rightPanelVisible)}
            data-testid="collapse-right-panel-button"
          />
        </div>
      </div>
      <Flex className="functional-diagrams-left-panel-content" vertical={true}>
        <div className="functional-diagrams-left-panel-content-diagram">
          <DiagramImageViewer
            setRightPanelVisible={setRightPanelVisible}
            theme={theme}
          />
        </div>
        {searchSubsystems && (
          <div>
            <SubsystemsTable subsystems={searchSubsystems} pageSize={2} />
          </div>
        )}
      </Flex>
    </Flex>
  )
}

export const NoSelection = ({ className }) => {
  return (
    <Flex
      vertical={true}
      justify="center"
      align="middle"
      className={classNames('functional-diagrams-empty', className)}
      data-testid="no-selection"
    >
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No selection" />
    </Flex>
  )
}

export const NoImage = () => {
  return (
    <div className="functional-diagrams-empty" data-testid="no-image">
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No image" />
    </div>
  )
}

function DiagramImageViewer({ setRightPanelVisible, theme }) {
  const diagram = useDiagram()

  if (!diagram.subsystem) return <NoSelection />

  return (
    <BlockDiagram
      key={`${diagram.vin}-${diagram.subsystem.id}`}
      vin={diagram.vin}
      subsystemId={diagram.subsystem.id}
      setRightPanelVisible={setRightPanelVisible}
      theme={theme}
    />
  )
}

function SubsystemsTable({
  subsystems,
  searchString,
  systemTypesFilter,
  pageSize = 15,
}) {
  const dispatch = useDiagramDispatch()

  const selectSubsystem = ({ record }) => {
    dispatch.handleDiagramReset()
    dispatch.handleSubsystemSelection({ subsystem: record })
  }

  return (
    <SubsystemsViewTable
      subsystems={subsystems}
      searchString={searchString}
      systemTypesFilter={systemTypesFilter}
      pageSize={pageSize}
      susbsystemCallback={selectSubsystem}
    />
  )
}

function SubsystemsOrRecentTable({
  subsystems,
  selectedTab,
  searchString,
  systemTypesFilter,
}) {
  const { recentSubsystemIds } = useRecentSubsystems()

  const recentSubsystems = subsystems.filter((subsystem) =>
    recentSubsystemIds.includes(subsystem.id),
  )

  return (
    <SubsystemsTable
      subsystems={selectedTab === DIAGRAMS_VIEW ? subsystems : recentSubsystems}
      searchString={searchString}
      systemTypesFilter={systemTypesFilter}
    />
  )
}

function RightPanel(props) {
  const { isLoading, subsystems, visible, intl } = props
  const [searchString, setSearchString] = useState('')
  const [diagramOrRecentTab, setSubsystemOrRecentTab] = useState(DIAGRAMS_VIEW)
  const [filtersVisible, setFiltersVisible] = useState(false)
  const [systemTypesFilter, setSystemTypesFilter] = useState([])
  const diagram = useDiagram()
  const dispatch = useDiagramDispatch()
  const { search } = useLocation()
  const history = useHistory()
  const searchParams = new URLSearchParams(search)
  const subsystemId = parseInt(searchParams.get('subsystem'))

  useEffect(() => {
    if (!isLoading && subsystems && subsystemId) {
      const filteredSubsystems = searchSubsystem(subsystemId)
      dispatch.handleSubsystemSelection({ subsystem: filteredSubsystems[0] })
      searchParams.delete('subsystem')
      history.replace({
        search: searchParams.toString(),
      })
    }
  }, [isLoading])

  const searchSubsystem = (filterId) => {
    return subsystems.filter((subsystem) => subsystem.id === filterId)
  }

  return (
    <Flex
      className={classNames('functional-diagrams-right-panel', {
        collapse: !visible,
      })}
      vertical={true}
    >
      <Skeleton
        loading={isLoading}
        paragraph={{ rows: 5 }}
        className="functional-diagrams-loading-padding"
      >
        {diagram.subsystem ? (
          diagram.blockDiagram.graph.selection === 'connection' ? (
            <DiagramDetailView />
          ) : (
            <DevicesOrConnectors intl={intl} />
          )
        ) : (
          <>
            <DiagramsOrRecentControlsHeader
              searchString={searchString}
              setSearchString={setSearchString}
              diagramOrRecentTab={diagramOrRecentTab}
              setSubsystemOrRecentTab={setSubsystemOrRecentTab}
              setFiltersVisible={setFiltersVisible}
              numOfSelectedFilters={systemTypesFilter.length}
              intl={intl}
            />
            <div className="functional-diagram-table-wrapper">
              <SubsystemsOrRecentTable
                selectedTab={diagramOrRecentTab}
                searchString={searchString}
                subsystems={subsystems}
                systemTypesFilter={systemTypesFilter}
              />
            </div>
            <Drawer
              title={intl.formatMessage({
                id: 'functionalDiagrams.rightPanel.filter',
              })}
              placement="right"
              onClose={() => setFiltersVisible(false)}
              open={filtersVisible}
              getContainer={false}
              width={260}
              style={{ position: 'absolute' }}
              extra={
                <Space>
                  <Button type="link" onClick={() => setSystemTypesFilter([])}>
                    <FormattedMessage id="functionalDiagrams.rightPanel.clearAll" />
                  </Button>
                </Space>
              }
            >
              <Checkbox.Group
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
                value={systemTypesFilter}
                onChange={(values) => setSystemTypesFilter(values)}
              >
                {Array.from(new Set(subsystems?.map((obj) => obj.system.name)))
                  .sort()
                  .map((obj) => (
                    <Row key={obj}>
                      <Col span={24}>
                        <Checkbox value={obj}>{obj}</Checkbox>
                      </Col>
                    </Row>
                  ))}
              </Checkbox.Group>
            </Drawer>
          </>
        )}
      </Skeleton>
    </Flex>
  )
}

function FunctionalDiagrams({ intl }) {
  const dispatch = useDiagramDispatch()
  const { vin } = useParams()

  const { isLoading, subsystems } = useSubsystems({ vin })
  const [rightPanelVisible, setRightPanelVisible] = useState(true)
  const props = useSelector((state) => {
    return {
      theme: state.app.theme,
    }
  }, shallowEqual)
  React.useEffect(() => {
    dispatch.handleVinAdded({ vin })
  }, [vin])

  return (
    <Flex className="functional-diagrams-root-container">
      <LeftPanel
        rightPanelVisible={rightPanelVisible}
        setRightPanelVisible={setRightPanelVisible}
        theme={props.theme}
      />
      <RightPanel
        isLoading={isLoading}
        subsystems={subsystems}
        visible={rightPanelVisible}
        intl={intl}
      />
    </Flex>
  )
}

function ConnectorInfoTopPanel({ connector }) {
  return (
    <Space direction="horizontal" size="large">
      <div>{connector.alias}</div>
      <div>{connector.description}</div>
      <div>{connector.color}</div>
    </Space>
  )
}

function FuseImageDetails({ fuseDetails }) {
  return (
    <>
      {fuseDetails == null && <></>}
      {fuseDetails != null && (
        <div className="fuse-details-container">
          <div
            className="fuse-color-image-container"
            data-testid="fuse-color-image-container"
          >
            <ConnectorPreview
              title={fuseDetails.title}
              colorCode={fuseDetails.colorCode}
              fileName={fuseDetails.componentImage}
            />
          </div>
          <div
            className="location-details-container"
            data-testid="location-image-container"
          >
            <LocationImage
              fileName={fuseDetails.locationImage}
              componentId={fuseDetails.componentId}
            />
          </div>
        </div>
      )}
    </>
  )
}

function ConnectorView() {
  const diagram = useDiagram()
  const dispatch = useDiagramDispatch()
  const [searchString, updateSearchString] = useState(null)
  const [selectedFuseDetail, updateSelectedFuseDetail] = useState(null)

  const { results: fuseList, isLoading: fuseListLoading } = useGlobalSearch({
    vin: diagram.vin,
    searchString: searchString,
  })

  const { results: fuseDetails, isLoading: fuseDetailsLoading } =
    useFuseDetails({
      makeModelId:
        !fuseListLoading && fuseList
          ? fuseList[0].make_model.make_model_id
          : null,
      fuseName: !fuseListLoading && fuseList ? fuseList[0].alias : null,
    })

  const { isLoading, isSuccess, pinout } = useComponentPinout({
    vin: diagram.vin,
    componentId: diagram.blockDiagram.graph.data.componentId,
    neighborIds: diagram.blockDiagram.graph.data.neighborIds,
  })

  const [activeKeyState, udpateActiveKey] = useState('1')

  const items = [
    {
      key: '1',
      label: 'ConnectorPinout',
      children: (
        <div className="functional-diagrams-connector-pinout">
          <ConnectorPinout
            key={diagram.blockDiagram.graph.data.componentId}
            pageSize={5}
          />
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <>
          Fuse Details{' '}
          {fuseListLoading && (
            <Spin indicator={<LoadingOutlined spin />} size="small" />
          )}
        </>
      ),
      disabled: selectedFuseDetail == null,
      children: <FuseImageDetails fuseDetails={selectedFuseDetail} />,
    },
  ]

  const isComponentImagePDBox =
    diagram.blockDiagram.graph.data.category == 'power_distribution'
      ? true
      : false

  const onFuseClick = (event) => {
    if (event) {
      const fuseParentGroup = event.target.parentElement.parentElement
      const pattern = /^fuse_.*/
      let fuseIDArray = []
      fuseParentGroup.childNodes.forEach((node) => {
        if (node.id && pattern.test(node.id)) {
          fuseIDArray.push(node.id.substring(5))
        }
      })
      updateSearchString(fuseIDArray.toString())
    } else {
      resetFuseImages()
    }
  }

  useEffect(() => {
    if (!fuseListLoading && !fuseDetailsLoading && fuseList && fuseDetails) {
      udpateActiveKey('2')
      updateSelectedFuseDetail({
        title: `${fuseList[0].alias} - ${fuseDetails.current_ampere}A`,
        colorCode: fuseList[0].color,
        componentImage: fuseList[0].imageName,
        locationImage: fuseList[0].locationImageName,
        componentId: fuseList[0].componentId,
      })
    }
  }, [fuseList, fuseDetails])

  const resetFuseImages = () => {
    updateSearchString(null)
    updateSelectedFuseDetail(null)
    udpateActiveKey('1')
  }

  const onImageSwitch = () => {
    resetFuseImages()
  }
  return (
    <div className="functional-diagrams-right-panel-content">
      <div className="functional-diagrams-block-diagram-image">
        <Loadable showText={false} isLoading={isLoading || !isSuccess}>
          <ComponentOrLocationImage
            componentImageName={diagram.blockDiagram.graph.data.imageName}
            pins={pinout == undefined ? [] : pinout.map((pin) => pin.pin)}
            componentId={diagram.blockDiagram.graph.data.componentId}
            locationImageName={
              diagram.blockDiagram.graph.data.locationImageName
            }
            alias={diagram.blockDiagram.graph.data.alias}
            description={diagram.blockDiagram.graph.data.description}
            isComponentImagePDBox={isComponentImagePDBox}
            onFuseClick={onFuseClick}
            onImageSwitch={onImageSwitch}
            color={diagram.blockDiagram.graph.data.color}
          >
            {diagram.blockDiagram.graph.data.category !== 'inline' && (
              <RelatedSubsystemsButton
                showButtonText={true}
                vin={diagram.vin}
                componentId={diagram.blockDiagram.graph.data.componentId}
                contextComponentId={
                  diagram.detailDiagram.relatedSubsystemComponentID
                }
                componentIdDispatch={dispatch.handleRelatedSubsystemsButton}
              />
            )}
          </ComponentOrLocationImage>
        </Loadable>
      </div>
      <Flex
        className={classNames('functional-diagrams-info-panel')}
        vertical={true}
      >
        {!isComponentImagePDBox && (
          <div className="functional-diagrams-connector-pinout">
            <ConnectorPinout
              key={diagram.blockDiagram.graph.data.componentId}
              pageSize={7}
            />
          </div>
        )}
        {isComponentImagePDBox && (
          <Tabs
            activeKey={activeKeyState}
            type="card"
            size="small"
            items={items}
            style={{ flex: 1 }}
            onTabClick={(key, event) => udpateActiveKey(key)}
            data-testid="component-fusedetails-tab"
            className="functional-diagrams-tabs"
          />
        )}
      </Flex>
    </div>
  )
}

export default requireAuthentication(injectIntl(FunctionalDiagrams))
