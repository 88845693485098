import {
  CLASS_NAME_ACTIVE,
  CLASS_NAME_FAULTY,
  EDGE_TYPE_DEVICE,
  EDGE_TYPE_INLINE,
  EDGE_TYPE_INTERNAL_DEVICE,
  EDGE_TYPE_INTERNAL_FUSE,
  WHITE_COLOR,
  RED_COLOR,
  COMET_COLOR,
  AZURE_RADIANCE_COLOR,
  PIPER_COLOR,
  DARK_PIPER_COLOR,
  EBONY_CLAY_COLOR,
  GRAY_COLOR,
  LIGHT_GRAY_COLOR,
  COMPONENT_CATEGORY_SPLICE,
  COMPONENT_CATEGORY_FUSE,
  COMPONENT_CATEGORY_HIGH_VOLTAGE,
  COMPONENT_CATEGORY_DEVICE,
  COMPONENT_CATEGORY_INLINE,
} from '../constants'

import ConnectorIcon from '../assets/images/icons/svg/ic-connector.svg'
import JointIcon from '../assets/images/icons/svg/ic-joint.svg'
import DeviceIcon from '../assets/images/icons/svg/ic-device.svg'
import GroundIcon from '../assets/images/icons/svg/ic-ground.svg'
import InlineConnectionIcon from '../assets/images/icons/svg/ic-inline-connection.svg'
import ModuleIcon from '../assets/images/icons/svg/ic-module.svg'
import PowerIcon from '../assets/images/icons/svg/ic-power-distribution.svg'
import FuseIcon from '../assets/images/icons/svg/ic-fuse.svg'
import ObdiiIcon from '../assets/images/icons/svg/ic-gwm.svg'
import HEVIcon from '../assets/images/icons/svg/ic-ISO-7010.svg'
import CheckIcon from '../assets/images/icons/svg/ic-not-electrical.svg'
import DeviceSwitchIcon from '../assets/images/icons/svg/device-switch.svg'
import DeviceSpeakerIcon from '../assets/images/icons/svg/device-speaker.svg'
import DeviceSolenoidIcon from '../assets/images/icons/svg/device-solenoid.svg'
import DeviceSensorIcon from '../assets/images/icons/svg/device-sensor.svg'
import DeviceMotorIcon from '../assets/images/icons/svg/device-motor.svg'
import DeviceMicrophoneIcon from '../assets/images/icons/svg/device-microphone.svg'
import DeviceLedIcon from '../assets/images/icons/svg/device-led.svg'
import DeviceLampIcon from '../assets/images/icons/svg/device-lamp.svg'
import DeviceCameraIcon from '../assets/images/icons/svg/device-camera.svg'
import DeviceAntennaIcon from '../assets/images/icons/svg/device-antenna.svg'
import DeviceActuatorIcon from '../assets/images/icons/svg/device-actuator.svg'
import DeviceUltrasonicIcon from '../assets/images/icons/svg/device-ultrasonic.svg'
import DeviceThermistorIcon from '../assets/images/icons/svg/device-thermistor.svg'
import DeviceProximityIcon from '../assets/images/icons/svg/device-proximity.svg'
import DeviceHallIcon from '../assets/images/icons/svg/device-hall.svg'
import DeviceGeneratorIcon from '../assets/images/icons/svg/device-generator.svg'
import DeviceBatteryIcon from '../assets/images/icons/svg/device-battery.svg'
import DeviceHeaterIcon from '../assets/images/icons/svg/device-heater.svg'
import DeviceExternalMirrorIcon from '../assets/images/icons/svg/device-external-mirror.svg'
import DeviceInternalMirrorIcon from '../assets/images/icons/svg/device-internal-mirror.svg'
import DeviceBeltIcon from '../assets/images/icons/svg/device-belt.svg'
import DeviceSquibIcon from '../assets/images/icons/svg/device-squib.svg'
import DeviceInjectorIcon from '../assets/images/icons/svg/device-injector.svg'
import DeviceGeneric from '../assets/images/icons/svg/device-generic.svg'

import ConnectorIconLight from '../assets/images/icons/svg/ic-connector-light.svg'
import GroundIconLight from '../assets/images/icons/svg/ic-ground-light.svg'
import InlineConnectionIconLight from '../assets/images/icons/svg/ic-inline-connection-light.svg'
import ModuleIconLight from '../assets/images/icons/svg/ic-module-light.svg'
import PowerIconLight from '../assets/images/icons/svg/ic-power-distribution-light.svg'
import FuseIconLight from '../assets/images/icons/svg/ic-fuse-light.svg'
import ObdiiIconLight from '../assets/images/icons/svg/ic-gwm-light.svg'
import CheckIconLight from '../assets/images/icons/svg/ic-not-electrical-light.svg'
import DeviceSwitchIconLight from '../assets/images/icons/svg/device-switch-light.svg'
import DeviceSpeakerIconLight from '../assets/images/icons/svg/device-speaker-light.svg'
import DeviceSolenoidIconLight from '../assets/images/icons/svg/device-solenoid-light.svg'
import DeviceSensorIconLight from '../assets/images/icons/svg/device-sensor-light.svg'
import DeviceMotorIconLight from '../assets/images/icons/svg/device-motor-light.svg'
import DeviceMicrophoneIconLight from '../assets/images/icons/svg/device-microphone-light.svg'
import DeviceLedIconLight from '../assets/images/icons/svg/device-led-light.svg'
import DeviceLampIconLight from '../assets/images/icons/svg/device-lamp-light.svg'
import DeviceCameraIconLight from '../assets/images/icons/svg/device-camera-light.svg'
import DeviceAntennaIconLight from '../assets/images/icons/svg/device-antenna-light.svg'
import DeviceActuatorIconLight from '../assets/images/icons/svg/device-actuator-light.svg'
import DeviceUltrasonicIconLight from '../assets/images/icons/svg/device-ultrasonic-light.svg'
import DeviceThermistorIconLight from '../assets/images/icons/svg/device-thermistor-light.svg'
import DeviceProximityIconLight from '../assets/images/icons/svg/device-proximity-light.svg'
import DeviceHallIconLight from '../assets/images/icons/svg/device-hall-light.svg'
import DeviceGeneratorIconLight from '../assets/images/icons/svg/device-generator-light.svg'
import DeviceBatteryIconLight from '../assets/images/icons/svg/device-battery-light.svg'
import DeviceHeaterIconLight from '../assets/images/icons/svg/device-heater-light.svg'
import DeviceExternalMirrorIconLight from '../assets/images/icons/svg/device-external-mirror-light.svg'
import DeviceInternalMirrorIconLight from '../assets/images/icons/svg/device-internal-mirror-light.svg'
import DeviceBeltIconLight from '../assets/images/icons/svg/device-belt-light.svg'
import DeviceSquibIconLight from '../assets/images/icons/svg/device-squib-light.svg'
import DeviceInjectorIconLight from '../assets/images/icons/svg/device-injector-light.svg'

import Splice from '../assets/images/icons/ic-splice.svg'

import { BLACK_COLOR } from '../constants/colors'

/*
 * Gets the background image url for a graph node based on its category.
 */
const svgMaps = {
  connector: ConnectorIcon,
  device: DeviceIcon,
  ground: GroundIcon,
  inline: InlineConnectionIcon,
  joint: JointIcon,
  module: ModuleIcon,
  power_distribution: PowerIcon,
  fuse: FuseIcon,
  gateway_odbii: ObdiiIcon,
  high_voltage: HEVIcon,
  not_electrical: CheckIcon,
  splice: Splice,
}

const svgDeviceMaps = {
  ground: GroundIcon,
  joint: JointIcon,
  module: ModuleIcon,
  power_distribution: PowerIcon,
  high_voltage: HEVIcon,
  not_electrical: CheckIcon,
  switch: DeviceSwitchIcon,
  speaker: DeviceSpeakerIcon,
  solenoid: DeviceSolenoidIcon,
  sensor: DeviceSensorIcon,
  motor: DeviceMotorIcon,
  microphone: DeviceMicrophoneIcon,
  led: DeviceLedIcon,
  lamp: DeviceLampIcon,
  camera: DeviceCameraIcon,
  antenna: DeviceAntennaIcon,
  actuator: DeviceActuatorIcon,
  ultrasonic: DeviceUltrasonicIcon,
  thermistor: DeviceThermistorIcon,
  proximity: DeviceProximityIcon,
  hall: DeviceHallIcon,
  generator: DeviceGeneratorIcon,
  battery: DeviceBatteryIcon,
  heater: DeviceHeaterIcon,
  external_mirror: DeviceExternalMirrorIcon,
  internal_mirror: DeviceInternalMirrorIcon,
  belt: DeviceBeltIcon,
  squib: DeviceSquibIcon,
  injector: DeviceInjectorIcon,
  generic: DeviceGeneric,
}

const svgMapsLight = {
  connector: ConnectorIconLight,
  device: DeviceIcon,
  ground: GroundIconLight,
  inline: InlineConnectionIconLight,
  joint: JointIcon,
  module: ModuleIconLight,
  power_distribution: PowerIconLight,
  fuse: FuseIconLight,
  gateway_odbii: ObdiiIconLight,
  high_voltage: HEVIcon,
  not_electrical: CheckIconLight,
  splice: Splice,
}

const svgDeviceMapsLight = {
  ground: GroundIconLight,
  joint: JointIcon,
  module: ModuleIconLight,
  power_distribution: PowerIconLight,
  high_voltage: HEVIcon,
  not_electrical: CheckIconLight,
  switch: DeviceSwitchIconLight,
  speaker: DeviceSpeakerIconLight,
  solenoid: DeviceSolenoidIconLight,
  sensor: DeviceSensorIconLight,
  motor: DeviceMotorIconLight,
  microphone: DeviceMicrophoneIconLight,
  led: DeviceLedIconLight,
  lamp: DeviceLampIconLight,
  camera: DeviceCameraIconLight,
  antenna: DeviceAntennaIconLight,
  actuator: DeviceActuatorIconLight,
  ultrasonic: DeviceUltrasonicIconLight,
  thermistor: DeviceThermistorIconLight,
  proximity: DeviceProximityIconLight,
  hall: DeviceHallIconLight,
  generator: DeviceGeneratorIconLight,
  battery: DeviceBatteryIconLight,
  heater: DeviceHeaterIconLight,
  external_mirror: DeviceExternalMirrorIconLight,
  internal_mirror: DeviceInternalMirrorIconLight,
  belt: DeviceBeltIconLight,
  squib: DeviceSquibIconLight,
  injector: DeviceInjectorIconLight,
  generic: DeviceGeneric,
}

export function getNodeBackgroundImage(node, isDarkTheme) {
  if (node.data('icon')) {
    return isDarkTheme
      ? `/tracer/icon/${node.data('icon')}`
      : `/tracer/icon/${node.data('icon')}?theme=light`
  }
  if (node.data('category') === 'device') {
    const category = node.data('device_category')
    return isDarkTheme ? svgDeviceMaps[category] : svgDeviceMapsLight[category]
  } else {
    const category = node.data('category')
    return isDarkTheme ? svgMaps[category] : svgMapsLight[category]
  }
}

/*
 * Creates the styling for the cytoscape graph
 * More info: http://js.cytoscape.org/#style
 */
export function getCyStyles(options = {}) {
  const isDarkTheme = options.theme == 'dark'
  const styles = [
    {
      selector: 'node',
      style: {
        'background-color': isDarkTheme ? COMET_COLOR : WHITE_COLOR,
        'background-fit': 'contain',
        'background-image': (el) => getNodeBackgroundImage(el, isDarkTheme),
        'background-image-crossorigin': 'use-credentials',
        'background-opacity': 1,
        'border-width': 2,
        'border-color': GRAY_COLOR,
        'background-width-relative-to': 'inner',
        color: isDarkTheme ? WHITE_COLOR : BLACK_COLOR,
        content: (el) => {
          if (el.data('category') === COMPONENT_CATEGORY_SPLICE) {
            return ''
          } else {
            // We return UNKNOWN because it helps cytoscape position the text in the graph correctly
            // If you just return blank there will be weird rendering errors as the text gets updated.
            return el.data('label') ? el.data('label') : 'UNKNOWN'
          }
        },
        'font-family': 'TracerIcons,Montserrat,sans-serif',
        'font-size': '20px',
        'font-weight': '500',
        height: 40,
        padding: 5,
        shape: 'roundrectangle',
        'text-halign': 'right',
        'text-margin-x': '10px',
        'text-valign': 'center',
        width: 40,
      },
    },
    {
      selector: `node[category="${COMPONENT_CATEGORY_FUSE}"]`,
      style: {
        'background-color': isDarkTheme ? EBONY_CLAY_COLOR : LIGHT_GRAY_COLOR,
        'background-image': (el) => getNodeBackgroundImage(el, isDarkTheme),
        'background-fit': 'contain',
        'border-width': 2,
        'border-color': GRAY_COLOR,
        padding: 5,
        shape: 'roundrectangle',
      },
    },
    {
      selector: `node[category="${COMPONENT_CATEGORY_DEVICE}"]`,
      style: {
        'background-color': isDarkTheme ? EBONY_CLAY_COLOR : LIGHT_GRAY_COLOR,
        'background-image': (el) => getNodeBackgroundImage(el, isDarkTheme),
        'background-fit': 'contain',
        'border-width': 2,
        'border-color': GRAY_COLOR,
        padding: 5,
        shape: 'roundrectangle',
      },
    },
    {
      selector: `node[category="${COMPONENT_CATEGORY_INLINE}"]`,
      style: {
        'border-width': isDarkTheme ? 0 : 2,
        'border-color': BLACK_COLOR,
        width: 40,
        height: 30,
      },
    },
    {
      selector: `node[category="${COMPONENT_CATEGORY_HIGH_VOLTAGE}"]`,
      style: {
        'background-color': isDarkTheme ? COMET_COLOR : WHITE_COLOR,
        'background-image': (el) => getNodeBackgroundImage(el, isDarkTheme),
        'background-fit': 'contain',
        'border-width': isDarkTheme ? 0 : 2,
        'border-color': BLACK_COLOR,
        padding: 5,
        shape: 'roundrectangle',
      },
    },
    {
      selector: `node[category="${COMPONENT_CATEGORY_SPLICE}"]`,
      style: {
        content: '',
        'background-color': isDarkTheme ? COMET_COLOR : BLACK_COLOR,
        'background-fit': 'none',
        'background-height': '50%',
        'background-width': '50%',
        width: 20,
        height: 20,
        'border-width': 0,
        'border-color': COMET_COLOR,
        padding: 0,
        shape: 'ellipse',
      },
    },
    {
      selector: 'node[?virtual]',
      style: {
        events: 'no',
      },
    },
    {
      selector: 'node[category="device"]',
      style: {
        events: 'yes',
      },
    },
    {
      selector: 'node[?unfocus]',
      style: {
        'background-image-opacity': 0.35,
        height: 35,
        opacity: 0.35,
        width: 35,
      },
    },
    {
      selector: `edge[edge_type="${EDGE_TYPE_INLINE}"]`,
      style: {
        'curve-style': 'bezier',
        'line-color': isDarkTheme ? COMET_COLOR : BLACK_COLOR,
        'line-style': 'dashed',
        events: 'no',
      },
    },
    {
      selector: `edge[edge_type="${EDGE_TYPE_DEVICE}"]`,
      style: {
        'curve-style': 'bezier',
        'line-color': isDarkTheme ? COMET_COLOR : BLACK_COLOR,
        'line-style': 'dashed',
        events: 'no',
      },
    },
    {
      selector: `edge[edge_type="${EDGE_TYPE_INTERNAL_DEVICE}"]`,
      style: {
        'line-color': isDarkTheme ? PIPER_COLOR : DARK_PIPER_COLOR,
        'line-style': 'dotted',
        'mid-source-arrow-shape': 'triangle-backcurve',
        display: 'none',
        events: 'no',
      },
    },
    {
      selector: `edge[edge_type="${EDGE_TYPE_INTERNAL_FUSE}"]`,
      style: {
        'line-color': isDarkTheme ? COMET_COLOR : BLACK_COLOR,
        'line-style': 'dashed',
        events: 'no',
      },
    },
    {
      selector: 'edge[!edge_type]',
      style: {
        color: 'white',
        'curve-style': 'bezier',
        'line-color': isDarkTheme ? COMET_COLOR : BLACK_COLOR,
        opacity: 1,
        'font-size': '14px',
        'source-label': !options.showPinDestinationLabels
          ? ''
          : (el) => {
              const isInline =
                el.data('edge_type') &&
                el.data('edge_type') === EDGE_TYPE_INLINE
              const isDevice =
                el.data('edge_type') &&
                el.data('edge_type') === EDGE_TYPE_DEVICE
              const isSplice =
                el.data('from_category') ===
                  el.data('source') + '-' + COMPONENT_CATEGORY_SPLICE ||
                el.data('to_category') ===
                  el.data('source') + '-' + COMPONENT_CATEGORY_SPLICE
              const pins = el.data('pins')
              if (
                isInline ||
                isSplice ||
                isDevice ||
                !pins ||
                pins.length === 0 ||
                !pins[0][0]
              ) {
                return ''
              } else {
                return pins[0][0]
              }
            },
        'source-text-offset': '14px',
        'target-label': !options.showPinDestinationLabels
          ? ''
          : (el) => {
              const isInline =
                el.data('edge_type') &&
                el.data('edge_type') === EDGE_TYPE_INLINE
              const isDevice =
                el.data('edge_type') &&
                el.data('edge_type') === EDGE_TYPE_DEVICE
              const isSplice =
                el.data('from_category') ===
                  el.data('target') + '-' + COMPONENT_CATEGORY_SPLICE ||
                el.data('to_category') ===
                  el.data('target') + '-' + COMPONENT_CATEGORY_SPLICE
              const pins = el.data('pins')
              if (
                isInline ||
                isSplice ||
                isDevice ||
                !pins ||
                pins.length === 0 ||
                !pins[0][1]
              ) {
                return ''
              } else {
                return pins[0][1]
              }
            },
        'target-text-offset': '14px',
        'text-background-color': isDarkTheme ? COMET_COLOR : BLACK_COLOR,
        'text-background-opacity': 1,
        'text-background-shape': 'roundrectangle',
        width: 3,
      },
    },
    {
      selector: 'edge[?unfocus]',
      style: {
        opacity: 0.35,
      },
    },
    {
      selector: `.${CLASS_NAME_ACTIVE}`,
      style: {
        'background-color': AZURE_RADIANCE_COLOR,
        'line-color': AZURE_RADIANCE_COLOR,
      },
    },
    {
      selector: `[category!="${COMPONENT_CATEGORY_SPLICE}"][?${CLASS_NAME_ACTIVE}]`,
      style: {
        'background-color': AZURE_RADIANCE_COLOR,
        'line-color': AZURE_RADIANCE_COLOR,
      },
    },
    {
      selector: `.${CLASS_NAME_FAULTY}`,
      style: {
        'background-color': RED_COLOR,
        'line-color': RED_COLOR,
      },
    },
    {
      selector: `[category!="${COMPONENT_CATEGORY_SPLICE}"][?${CLASS_NAME_FAULTY}]`,
      style: {
        'background-color': RED_COLOR,
        'line-color': RED_COLOR,
      },
    },
    {
      selector: ':active',
      style: {
        'overlay-opacity': '0',
      },
    },
  ]

  return styles
}
