import React from 'react'
import { FormattedMessage } from 'react-intl'
import IconNoImage from './Icons/IconNoImage'

const NoGraph = (props) => {
  return (
    <div id="no-graph-wrapper">
      <div className="no-graph-error-message">
        <IconNoImage />
        <p>
          <FormattedMessage id="tracer.harnessView.noGraph.message" />
        </p>
        <p>
          <FormattedMessage id="tracer.harnessView.noGraph.youCan" />
          <span className="feedback" onClick={props.openFeedbackModal}>
            <FormattedMessage id="tracer.harnessView.noGraph.leaveFeedback" />
          </span>
        </p>
      </div>
    </div>
  )
}

export default NoGraph
