import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import MustHave from '../../containers/MustHave'
import { injectIntl } from 'react-intl'
import { Flex } from 'antd'

import { DownOutlined, UpOutlined } from '@ant-design/icons'

class ListPanel extends Component {
  state = {
    expanded: true,
    headingDimensions: {
      width: 0,
      height: 0,
    },
    footerDimensions: {
      width: 0,
      height: 0,
    },
  }

  render() {
    const panelClassNames = classNames('collapsible-group-content', {
      collapsed: this.props.collapsible && !this.state.expanded,
      expanded: this.props.collapsible && this.state.expanded,
    })

    return (
      <Flex gap={0} vertical className="collapsible-group-container">
        <Flex className="collapsible-group-header" vertical={false}>
          <a onClick={this.handlePanelHeaderClick}>{this.props.title}</a>
          {this.props.toggleShowHideDtcMemoEdit &&
            !this.props.isEphemeralVehicleTest && (
              <MustHave permission="dtc-memo:edit">
                <a
                  id="show-hide-dtc-memo-edit"
                  onClick={() => this.props.toggleShowHideDtcMemoEdit()}
                >
                  {this.props.isDtcMemoEditShown
                    ? this.props.intl.formatMessage({
                        id: 'dtcMemo.switchToPreview',
                      })
                    : this.props.intl.formatMessage({
                        id: 'dtcMemo.switchToEdit',
                      })}
                </a>
              </MustHave>
            )}
          {this.props.collapsible &&
            (this.state.expanded ? (
              <DownOutlined className="collapse-icon" />
            ) : (
              <UpOutlined className="collapse-icon" />
            ))}
          {this.props.infoIcon}
        </Flex>
        <div className={panelClassNames}>{this.props.children}</div>
      </Flex>
    )
  }

  handlePanelHeaderClick = () => {
    if (this.props.collapsible) {
      this.setState({
        expanded: !this.state.expanded,
      })
    }

    if (this.props.onHeaderClick) {
      this.props.onHeaderClick()
    }
  }
}

ListPanel.propTypes = {
  title: PropTypes.string.isRequired,
  collapsible: PropTypes.bool,
  scrolling: PropTypes.bool,
  headerTop: PropTypes.number,
  panelHeight: PropTypes.string,
}

ListPanel.defaultProps = {
  collapsible: false,
  scrolling: false,
  headerTop: 0,
}

export default injectIntl(ListPanel)
