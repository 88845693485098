import React from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch, withRouter, useHistory } from 'react-router'
import { injectIntl } from 'react-intl'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'

import TabContent from '../Navigation/TabContent'
import TabPane from '../Navigation/TabPane'
import PinoutContainer from '../../containers/PinoutContainer'
import SidebarContainer from '../../containers/SidebarContainer'
import LeftSideBar from '../LeftSideBar/LeftSideBar'
import SameIssuesHistoryButton from '../../containers/SameIssuesHistoryButtonContainer'
import HarnessView from '../HarnessView'
import VehicleView from '../VehicleView'
import NoGraphContainer from '../../containers/NoGraphContainer'

import history from '../../history'
import { getHarnessDataFromPath, formatResponse } from '../../api/harness-api'
import { updateCircuitState } from '../../api/logging-api'
import {
  setHtmlTitle,
  setHtmlTitleToDefault,
} from '../../actions/title-actions'
import { resetState } from '../../actions/component-actions'
import { clearError } from '../../actions/error-actions'
import {
  setEphemeralVehicleTest,
  unsetEphemeralVehicleTest,
} from '../../actions/ephemeral-vehicle-test-actions'
import { selectNavigationTab } from '../../actions/tab-actions'
import { loadVerifiedComponents } from '../../thunks/component'
import { requireAuthentication } from '../../helpers/authentication'
import {
  HARNESS_VIEW,
  VEHICLE_VIEW,
  PINOUT_VIEW,
  MAIN_NAMESPACE,
} from '../../constants'
import { Col, Row, Flex } from 'antd'
import './VehicleTestResult.scss'
import Loading from '../Loading'

export const EphemeralVehicleTestResult = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const result = history.location.state?.result
  React.useEffect(() => {
    if (result) {
      dispatch(clearError())
      dispatch(setEphemeralVehicleTest())
      dispatch(selectNavigationTab(HARNESS_VIEW, MAIN_NAMESPACE))
      formatResponse(result)
    }
    return () => {
      dispatch(resetState())
      dispatch(unsetEphemeralVehicleTest())
    }
  }, [result, dispatch])

  return <BaseVehicleTestResult />
}

const VehicleTestResult = ({ match: { params } }) => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (params.pathId) {
      getHarnessDataFromPath(params.pathId)
    }
    return () => dispatch(resetState())
  }, [params.pathId, dispatch])

  return <BaseVehicleTestResult />
}

const BaseVehicleTestResult = () => {
  const dispatch = useDispatch()

  const props = useSelector((state) => {
    console.log(state.componentState)
    return {
      makeModelId: state.componentState.makeModelId,
      vin: state.componentState.vin,
      sequenceNumber: state.componentState.sequenceNumber,
      svgEnabled: state.componentState.svgEnabled,
      elements: state.componentState.elements,
    }
  }, shallowEqual)

  React.useEffect(() => {
    dispatch(resetState())
  }, [dispatch])

  React.useEffect(() => {
    const title = props.sequenceNumber || props.vin
    if (title) {
      dispatch(setHtmlTitle(title))
    }
    return () => dispatch(setHtmlTitleToDefault())
  }, [props.sequenceNumber, props.vin, dispatch])

  React.useEffect(() => {
    if (props.makeModelId && props.vin) {
      updateCircuitState(props.makeModelId, props.vin)
    }
  }, [props.makeModelId, props.vin])

  React.useEffect(() => {
    dispatch(loadVerifiedComponents())
  }, [props.vin, dispatch])

  return (
    <>
      {props.makeModelId == null && <Loading />}
      {props.makeModelId != null && (
        <Flex vertical={false} className="vehicle-test-container">
          <div className="vehicle-test-left-side-panel-container">
            <SameIssuesHistoryButton />
            <LeftSideBar />
          </div>
          <Flex className="vehicle-test-center-container">
            <div className="vehicle-test-view-container">
              <ConnectedRouter history={history}>
                <Switch>
                  <Route
                    exact
                    path="/tracer/no-result"
                    component={NoGraphContainer}
                  />
                  <Route>
                    <TabContent>
                      <TabPane
                        id={HARNESS_VIEW}
                        namespace={MAIN_NAMESPACE}
                        position={0}
                        canBeDeactivated={false}
                        enabled={!!props.vin}
                      >
                        <HarnessView />
                      </TabPane>

                      <TabPane
                        id={VEHICLE_VIEW}
                        namespace={MAIN_NAMESPACE}
                        position={1}
                        enabled={props.svgEnabled}
                      >
                        <VehicleView />
                      </TabPane>

                      <TabPane
                        id={PINOUT_VIEW}
                        namespace={MAIN_NAMESPACE}
                        position={2}
                        enabled={!!props.vin}
                      >
                        <PinoutContainer />
                      </TabPane>
                    </TabContent>
                  </Route>
                </Switch>
              </ConnectedRouter>
            </div>
            <div className="vehicle-test-collapsible-right-panel-container">
              <SidebarContainer />
            </div>
          </Flex>
        </Flex>
      )}
    </>
  )
}

export default requireAuthentication(injectIntl(withRouter(VehicleTestResult)))
