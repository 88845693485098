import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { createDtcList } from '../../selectors'
import {
  getSelectedDtcs,
  getDtcOptions,
  getHarnessList,
} from '../../selectors/repairHistory'
import _ from 'lodash'
import FaultEditForm from './FaultEditForm'

import {
  addHarnessProblem,
  updateHarnessProblem,
} from '../../api/controller-api'

/*
 Helper FSCs
*/

class HarnessDetails extends Component {
  constructor(props) {
    super(props)
    const isEdit = this.props.item && this.props.item.id
    const isNew = !isEdit
    this.state = {
      isNew,
      isEdit,
      connectorType: 'harness',
      annotation: this.props.item.annotation,
    }

    const harness = this.state.isEdit && {
      label: this.props.item.harness,
      description: this.props.item.description,
      value: this.props.item.harness,
      id: this.props.item.harness,
    }
    this.props.initialize({ item: this.props.item, harness: harness })
  }
  saveItem = (values) => {
    const { id } = values.item

    const annotation = this.state.annotation
    const reason = values.item.reason.value || values.item.reason
    const harness = values.harness.id
    const dtcs = values.item.dtcs.map((item) => (item.dtc ? item.dtc : item))
    if (this.state.isNew) {
      this.props.add({
        annotation,
        harness,
        dtcs,
        reason,
      })
    } else {
      this.props.update({ id, annotation, harness, dtcs, reason })
    }
  }

  updateAnnotations = (annotation) => {
    this.setState({ annotation })
  }

  render = () => (
    <FaultEditForm
      submit={this.props.handleSubmit(this.saveItem)}
      formId={'repair-history-harness-details'}
      intl={this.props.intl}
      faults={this.props.faults}
      dtcOptions={this.props.dtcOptions}
      selectedDtcs={this.props.selectedDtcs}
      onClose={this.props.onClose}
      annotations={this.state.annotation}
      updateAnnotation={this.updateAnnotations}
      categorySelectorDetails={{
        label: 'repairHistory.add.form.harnessLabel',
        name: 'harness',
        data: this.props.harness,
        placeholder: 'repairHistory.add.form.harnessPlaceholder',
      }}
    />
  )
}
HarnessDetails = reduxForm({
  form: 'repair-history-harness-details',
  destroyOnUnmount: true,
})(HarnessDetails)

const mapStateToProps = (state, ownProps) => {
  return {
    vehicleTestResultId: _.get(
      ownProps,
      'item.vehicle_test_result_id',
      state.componentState.vehicleTestResultId,
    ),
    makeModelId: state.componentState.makeModelId,
    vin: state.componentState.vin,
    dtcs: createDtcList(state),
    dtcOptions: getDtcOptions(state),
    selectedDtcs: getSelectedDtcs(_.get(ownProps, 'item.dtcs', []))(state),
    harnessLevels: state.harnessState.harnessLevels,
    faults: state.loggingState.faults.harness.map((item) => ({
      value: item.key,
      label: item.key,
    })),
    harnesses: getHarnessList(state),
    ...ownProps,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  _add: ({
    annotation,
    vehicleTestResultId,
    harness,
    dtcs,
    makeModelId,
    reason,
    vin,
    harnessLevel,
  }) => {
    addHarnessProblem({
      annotation,
      vehicleTestResultId,
      harness,
      dtcs,
      isProblem: true,
      makeModelId,
      reason,
      vin,
      harnessLevel,
    })
  },
  _update: ({
    id,
    annotation,
    vehicleTestResultId,
    harness,
    dtcs,
    makeModelId,
    reason,
    vin,
    harnessLevel,
  }) => {
    updateHarnessProblem({
      id,
      annotation,
      vehicleTestResultId,
      harness,
      makeModelId,
      vin,
      isProblem: true,
      reason,
      dtcs,
      harnessLevel,
    })
  },
})

function getHarnessLevel(harnessLevels, harness) {
  const harnessLevel = harnessLevels[harness.toUpperCase()]
  if (Array.isArray(harnessLevel) && harnessLevel.length > 0) {
    return harnessLevel[0]
  } else {
    return harnessLevel
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    add: (args) => {
      dispatchProps._add({
        ...args,
        vehicleTestResultId: stateProps.vehicleTestResultId,
        makeModelId: stateProps.makeModelId,
        vin: stateProps.vin,
        harnessLevel: getHarnessLevel(stateProps.harnessLevels, args.harness),
      })
      ownProps.onClose()
    },
    update: (args) => {
      dispatchProps._update({
        ...args,
        vehicleTestResultId: stateProps.vehicleTestResultId,
        makeModelId: stateProps.makeModelId,
        vin: stateProps.vin,
        harnessLevel: getHarnessLevel(stateProps.harnessLevels, args.harness),
      })
      ownProps.onClose()
    },
  }
}

export const HarnessDetailsContainer = injectIntl(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(HarnessDetails),
)
