import get from 'lodash/get'
import React, { useState, useEffect } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { unsetModal } from '../actions/modal-actions'

import { submitFeedback } from '../api/feedback-api'
import { isValidEmail } from '../helpers/utils'
import { Modal, Input, Button, Flex, Select } from 'antd'

const UNSUPPORTED_ERROR_CODE = 'unsupported_error_code'
const INCORRECT_IMAGE = 'incorrect_image'
const MISSING_IMAGE = 'missing_image'
const MISSING_COMPONENT = 'missing_component'
export const INCORRECT_RECOMMENDATION = 'incorrect_recommendation'
const FEATURE_REQUEST = 'feature_request'
const OTHER_PROBLEM = 'other_problem'

const FeedbackForm = ({
  intl,
  isOpen,
  closeModal,
  submitForm,
  username,
  initialOption = null,
}) => {
  const selectTypeToOption = {
    [UNSUPPORTED_ERROR_CODE]: 'Unsupported Error Code',
    [INCORRECT_IMAGE]: 'Incorrect Image',
    [MISSING_IMAGE]: 'Missing Image',
    [MISSING_COMPONENT]: 'Missing Component',
    [INCORRECT_RECOMMENDATION]: 'Incorrect Recommendation',
    [FEATURE_REQUEST]: 'Feature Request',
    [OTHER_PROBLEM]: 'Other',
  }
  const selectTypeOptions = Object.keys(selectTypeToOption).map((key) => ({
    value: key,
    label: selectTypeToOption[key],
  }))

  const [selection, selectOption] = useState(initialOption)
  const [description, updateDescription] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [emailEnabled, setEmailEnabled] = useState(true)

  let submittable = !!selection
  if (selection && [FEATURE_REQUEST, OTHER_PROBLEM].includes(selection))
    submittable = !!description.length

  useEffect(() => {
    if (isValidEmail(username) && !email) {
      setEmail(username)
      setEmailEnabled(false)
    }
  })

  const onSubmitClick = (e) => {
    e.preventDefault()
    if (!selection) return

    submitForm({
      selectionValue: selection,
      selectionLabel: selectTypeToOption[selection],
      description,
      name,
      email,
    })
    closeModal()
  }

  const renderSelectProblemType = () => {
    const placeholder = '--'
    return (
      <div>
        <label>
          <FormattedMessage id="feedbackForm.selectProblem" /> (
          <FormattedMessage id="forms.required" />
          ):
        </label>
        <Select
          style={{ width: '100%' }}
          options={selectTypeOptions}
          onChange={(value) => selectOption(value)}
          value={
            selection
              ? { value: selection, label: selectTypeToOption[selection] }
              : null
          }
          placeholder={placeholder}
        />
      </div>
    )
  }

  const renderEnterDescription = () => {
    return (
      <div>
        <label>
          <FormattedMessage id="feedbackForm.description" /> (
          {submittable ? (
            <FormattedMessage id="forms.optional" />
          ) : (
            <FormattedMessage id="forms.required" />
          )}
          )
        </label>
        <Input.TextArea
          name="description"
          placeholder={intl.formatMessage({
            id: 'tracer.logFailure.commentPlaceholder',
          })}
          onChange={(e) => updateDescription(e.target.value)}
          value={description}
        />
      </div>
    )
  }

  const renderEnterName = () => {
    return (
      <div>
        <label>
          <FormattedMessage id="feedbackForm.name" /> (
          <FormattedMessage id="forms.optional" />
          ):
        </label>
        <Input
          name="name"
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
      </div>
    )
  }

  const renderEnterEmail = () => {
    return (
      <div>
        <label>
          <FormattedMessage id="feedbackForm.email" /> (
          <FormattedMessage id="forms.optional" />
          ):
        </label>
        <Input
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          disabled={emailEnabled ? '' : 'disabled'}
        />
      </div>
    )
  }

  return (
    <Modal
      open={isOpen}
      title={<FormattedMessage id="feedbackForm.title" />}
      onCancel={closeModal}
      footer={[
        <Button onClick={closeModal}>
          <FormattedMessage id="forms.cancel" />
        </Button>,
        <Button type="primary" onClick={onSubmitClick} disabled={!submittable}>
          <FormattedMessage id="forms.submit" />
        </Button>,
      ]}
      centered={true}
    >
      <Flex id="submit-feedback-form" vertical={true} gap="middle">
        <div>
          <FormattedMessage id="feedbackForm.leadingText" />
        </div>
        {renderSelectProblemType()}
        {renderEnterDescription()}
        {renderEnterEmail()}
        {renderEnterName()}
      </Flex>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  const path = get(state, 'router.location.pathname')
  const vin = get(state.componentState, 'vin')
  const username = get(state, 'user.data.username')

  return {
    isOpen: state.modalState.isOpen,
    initialOption: state.modalState.initialOption,
    username: username,
    submitForm: (form) => {
      const payload = {
        form,
        path: get(state, 'router.location.pathname'),
        html_title: get(state, 'title'),
        vin: vin,
        redux_state: state,
      }
      submitFeedback(payload)
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(unsetModal())
    },
  }
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(FeedbackForm),
)
