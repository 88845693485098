import { Modal, Layout } from 'antd'
import './style.scss'

const ModalFullScreen = ({
  HeaderProp,
  ContentProp,
  isOpen,
  dataTestIdContainer,
  dataTestIdHeader,
  dataTestIdContent,
}) => {
  const { Header, Content } = Layout
  return (
    <Modal
      className="full-screen-modal"
      closable={false}
      open={isOpen}
      width={'100%'}
      footer={null}
    >
      <Layout data-testid={dataTestIdContainer}>
        <Header className="header-container" data-testid={dataTestIdHeader}>
          <HeaderProp />
        </Header>
        <Content className="content-container" data-testid={dataTestIdContent}>
          <ContentProp />
        </Content>
      </Layout>
    </Modal>
  )
}

export default ModalFullScreen
