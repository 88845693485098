import React from 'react'
import IconNoImage from './Icons/IconNoImage'

export const ErrorComponent = ({ children }) => {
  return (
    <div className="error-message">
      <IconNoImage />
      {children}
    </div>
  )
}
