import React from 'react'
import Topology from '../SvgViews/Topology'
import { FormattedMessage } from 'react-intl'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useComponentDetails } from '../../hooks/ComponentDetails'
import {
  setSearch,
  setSelectedComponentId,
  setSelectedComponent,
  resetComponent,
} from '../../actions/dtc-mapping'
import { DestinationComponentHeader } from './CrowdsourcedDTCMapping'
import { PinsTable } from './AddConnectorMappingStartComponent'
import { setActiveUIComponent } from '../../actions/dtc-mapping'
import { DTC_MAPPING_ACTIVE_UI } from '../../constants'
import { useComponentSearch } from '../../hooks/ComponentSearch'
import { Layout, Button, Row, Col, Input, Flex, Table } from 'antd'

const ComponentsSearchTable = ({ componentSearchString, vin }) => {
  const dispatch = useDispatch()
  const { components, isLoading, isSuccess } = useComponentSearch({
    searchString: componentSearchString,
    vin,
  })
  const selectedComponentId = useSelector(
    (state) => state.dtcMapping.selectedComponentId,
    shallowEqual,
  )
  const {
    componentDetails,
    isLoading: componentDetailsIsLoading,
    isSuccess: componentDetailsIsSuccess,
  } = useComponentDetails({ vin, componentId: selectedComponentId })

  const doSelectComponent = (componentId) => {
    dispatch(resetComponent())
    dispatch(setSelectedComponentId(componentId))
  }

  React.useEffect(() => {
    if (!componentDetailsIsSuccess) return
    dispatch(setSelectedComponent(componentDetails.component))
  }, [componentDetails, selectedComponentId])

  const columns = [
    {
      title: <FormattedMessage id="crowdsourcedDtcMapping.columns.alias" />,
      dataIndex: ['component', 'alias'],
      width: '120px',
    },
    {
      title: <FormattedMessage id="crowdsourcedDtcMapping.columns.id" />,
      dataIndex: ['component', 'id'],
      width: '240px',
    },
    {
      title: (
        <FormattedMessage id="crowdsourcedDtcMapping.columns.description" />
      ),
      dataIndex: ['component', 'description'],
      ellipsis: true,
    },
  ]

  return (
    <div className="mapping-table-wrapper">
      <div className="mapping-table-header">
        Components {isLoading && ' - Loading...'}
        {isSuccess && '-'}
        {isSuccess &&
          ` ${components.length} results for "${componentSearchString}"`}
      </div>
      <Table
        pagination={{
          pageSize: 3,
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        rowSelection={{
          selectedRowKeys: [selectedComponentId],
          type: 'radio',
          onChange: (selectedRowKeys) => {
            doSelectComponent(selectedRowKeys[0])
          },
        }}
        onRow={(record) => ({
          onClick: () => {
            doSelectComponent(record.component.id)
          },
        })}
        columns={columns}
        dataSource={components}
        rowKey={(record) => record.component.id}
      />
    </div>
  )
}

export const AddConnectorMapping = ({ vin, dtcMemoId }) => {
  const { Content, Header } = Layout
  const dispatch = useDispatch()
  // State from dtcMappingReducer
  const props = useSelector((state) => state.dtcMapping, shallowEqual)

  const doChangeSearchQuery = (e) => setSearchString(e.target.value)

  const doSearch = () => {
    dispatch(setSearch(searchString))
    dispatch(resetComponent())
  }

  const doSearchByPressEnter = (e) => searchString && doSearch()

  const [searchString, setSearchString] = React.useState(props.search)

  return (
    <>
      <Header className="add-connector-mapping-header">
        <Flex gap={2} vertical={false} justify="space-between" align="center">
          <DestinationComponentHeader dtcMemoId={dtcMemoId} vin={vin} />
          <Button
            onClick={() =>
              dispatch(
                setActiveUIComponent(DTC_MAPPING_ACTIVE_UI.MANAGE_MAPPINGS),
              )
            }
          >
            <FormattedMessage id="crowdsourcedDtcMapping.back" />
          </Button>
        </Flex>
      </Header>
      <Content className="add-connector-mapping-content">
        <Row>
          <Col span={14}>
            <Topology component={props.selectedComponent} />
          </Col>
          <Col span={10} className="add-connector-mapping-content-right-pane">
            <label htmlFor="search-input">
              <FormattedMessage id="crowdsourcedDtcMapping.searchLabel" />
            </label>
            <Flex vertical={true} gap="middle">
              <Row
                align="bottom"
                justify="space-between"
                className="add-connector-mapping-content-right-pane-search"
              >
                <Col span={20}>
                  <Input
                    value={searchString}
                    onChange={doChangeSearchQuery}
                    onPressEnter={doSearchByPressEnter}
                  />
                </Col>
                <Col span={3}>
                  <Button onClick={doSearch} disabled={!searchString}>
                    <FormattedMessage id="crowdsourcedDtcMapping.search" />
                  </Button>
                </Col>
              </Row>
              <Flex
                className="add-connector-mapping-content-right-pane-table-container"
                vertical
                gap="middle"
              >
                <ComponentsSearchTable
                  vin={vin}
                  componentSearchString={props.search}
                />
                <PinsTable
                  componentId={props.selectedComponentId}
                  vin={vin}
                  dtcMemoId={dtcMemoId}
                />
              </Flex>
            </Flex>
          </Col>
        </Row>
      </Content>
    </>
  )
}
