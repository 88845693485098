import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Modal } from 'antd'
import { FormattedMessage } from 'react-intl'

import { unsetModal } from '../actions/modal-actions'

const DependentDTCItem = ({ dtc, module, description }) => (
  <div className="dependent-dtc-item">
    <div>
      <b>
        {dtc} ({module})
      </b>
    </div>
    <div>{description}</div>
  </div>
)

const DTCDependencyInfo = () => {
  const props = useSelector(
    (state) => ({
      isOpen: state.modalState.isOpen,
      dtcDependencies: state.componentState.dtcDependencies,
    }),
    shallowEqual,
  )

  const dispatch = useDispatch()
  const closeModal = () => dispatch(unsetModal())

  return (
    <Modal
      open={props.isOpen}
      title={
        <FormattedMessage
          id="dtcDependencies.title"
          values={{ total: props.dtcDependencies.length }}
        />
      }
      onCancel={closeModal}
      footer={null}
      centered={true}
    >
      <div className="dependent-dtc-items-list">
        {props.dtcDependencies.map((item, idx) => (
          <DependentDTCItem
            key={idx}
            dtc={item.dependent_dtc}
            module={item.dependent_dtc_module_acronym}
            description={item.dependent_dtc_desc}
          />
        ))}
      </div>
    </Modal>
  )
}

export default DTCDependencyInfo
