import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import history from '../history'
import { MappingsHeader } from './DTCMapping/CrowdsourcedDTCMapping'
import { Row, Col, Button, Flex, Input } from 'antd'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const DTCMemo = ({
  memoId,
  initialMemo = '',
  initialShortDescription = '',
  acronym,
  makeModelStr,
  dtcError,
  dtcId,
  onSubmit,
  username,
}) => {
  const [description, setDescription] = React.useState(initialMemo)
  const [shortDescription, setShortDescription] = React.useState(
    initialShortDescription,
  )
  const doSaveAndGoBack = () => {
    onSubmit.mutate({
      memoId,
      memo: description,
      shortDescription,
      updatedBy: username,
    })
    history.goBack()
  }
  React.useEffect(() => {
    setDescription(initialMemo)
  }, [initialMemo])

  React.useEffect(() => {
    setShortDescription(initialShortDescription)
  }, [initialShortDescription])

  return (
    <Flex style={{ height: '100%' }} vertical align="center" gap="small">
      <Row>
        <Col span="24">
          <MappingsHeader dtcMemoId={memoId}>
            {initialMemo ? (
              <FormattedMessage id="dtcMemo.addMemo" />
            ) : (
              <FormattedMessage id="dtcMemo.editMemo" />
            )}
          </MappingsHeader>
        </Col>
      </Row>
      <Row className="dtc-memo-content">
        <Col span="24">
          <Flex gap="large" className="dtc-details">
            <div>
              <span>
                <b>
                  <FormattedMessage id="dtcMemo.model" />:
                </b>
              </span>{' '}
              {makeModelStr}
            </div>
            <div>
              <span>
                <b>
                  <FormattedMessage id="dtcMemo.module" />:
                </b>
              </span>{' '}
              {acronym}
            </div>
            <div>
              <span>
                <b>
                  <FormattedMessage id="dtcMemo.dtc" />:
                </b>
              </span>{' '}
              {dtcError}
            </div>
          </Flex>
          <div className="dtc-description">
            <label>
              <FormattedMessage id="dtcMemo.shortDescription" />
            </label>
            <Input
              id="shortDescription"
              value={shortDescription}
              onChange={(event) => setShortDescription(event.target.value)}
              disabled={dtcId}
            />
          </div>
          <div className="text-editor-container">
            <label className="">
              <FormattedMessage id="dtcMemo.notes" />
            </label>
            <ReactQuill
              id="quill-description"
              theme="snow"
              modules={{ toolbar: [{ list: 'ordered' }, { list: 'bullet' }] }}
              value={description}
              onChange={setDescription}
            />
          </div>
          <div className="form-button-container">
            <Button
              id="save-memo"
              onClick={() => doSaveAndGoBack()}
              type="primary"
            >
              <FormattedMessage id="dtcMemo.save" />
            </Button>
            <Button onClick={() => history.goBack()} id="cancel-edit-memo">
              <FormattedMessage id="dtcMemo.cancel" />
            </Button>
          </div>
        </Col>
      </Row>
    </Flex>
  )
}

DTCMemo.propTypes = {
  // Data
  dtcMemo: PropTypes.shape({
    id: PropTypes.number,
    make_model_id: PropTypes.number,
    make_model_str: PropTypes.string,
    acronym: PropTypes.string,
    dtc: PropTypes.string,
    short_desc: PropTypes.string,
    memo: PropTypes.string,
  }),
}

const mapStateToProps = (state) => ({
  username: get(state, 'user.data.username'),
})

export default connect(mapStateToProps)(DTCMemo)
