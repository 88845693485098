import React from 'react'
import SvgViewer from '../SvgViews/SvgViewer'
import { useCDN } from '../../hooks/CDN'
import { ConnectorColorTag } from '../ConnectorColorTag'
import './style.scss'

export const ConnectorPreview = ({ title, colorCode, fileName, theme }) => {
  const { isSuccess, cdn } = useCDN()
  if (!fileName) {
    console.error('File name unspecified could not show connector preview')
    return null
  }

  if (!isSuccess) {
    console.error('CDN Error could not show connector preview')
    return null
  }

  return (
    <div className="connector-preview-box" data-testid="connector-preview-box">
      <div className="connector-preview-box-title">
        <ConnectorColorTag colorCode={colorCode} />
        <div className="connector-preview-box-title-text">{title}</div>
      </div>
      <SvgViewer
        key={fileName}
        src={`${cdn.imagesURL}/${fileName}`}
        panzoom={false}
        panEnabled={false}
        zoomEnabled={false}
        theme={theme}
        className={'connector-preview-inline-svg'}
      />
    </div>
  )
}
