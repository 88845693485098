import React from 'react'
import history from '../../history'
import { getNavigatedFromKey } from '../../services/navigation'
import { LeftOutlined } from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'
import { Flex } from 'antd'

function BackButton({ intl }) {
  const historyLabelKey = `navigationHistory.${getNavigatedFromKey(window.location.search)}`
  const defaultMessage = intl.formatMessage({ id: 'generic.back' })
  return (
    <Flex gap="small" className="back-button-container">
      <LeftOutlined onClick={history.goBack} />
      <div onClick={history.goBack}>
        <div>
          <FormattedMessage
            id={historyLabelKey}
            defaultMessage={defaultMessage}
          />
        </div>
      </div>
    </Flex>
  )
}

export default BackButton
