import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import Measure from 'react-measure'
import { injectIntl } from 'react-intl'

import TabContent from '../Navigation/TabContent'
import TabPane from '../Navigation/TabPane'
import Loading from '../Loading'
import PinDetails from '../PinDetails'
import FusesAndRelays from '../FusesAndRelays'
import Topology from '../SvgViews/Topology'
import PinDestinationGraphContainer from '../../containers/PinDestinationGraphContainer'
import {
  HARNESS_VIEW,
  VEHICLE_VIEW,
  FUSES_AND_RELAYS_VIEW,
  PINOUT_NAMESPACE,
} from '../../constants'
import NavigationTabs from '../Navigation/NavigationTabs'
import { getElementName } from '../../helpers/element'
import {
  ZoomInControl,
  ZoomOutControl,
  ZoomResetControl,
} from '../ZoomControls'
import { Row, Col, Button, Flex } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import './PinDestination.scss'
import ModalFullScreen from '../Modal/ModalFullScreen'

const propTypes = {
  selectedElement: PropTypes.object,
  zoomFactor: PropTypes.number,
}

const defaultProps = {
  selectedElement: null,
  zoomFactor: 0.25,
}

function Loadable({ isLoading, children }) {
  if (isLoading) {
    return <Loading />
  }
  return children
}

class PinDestination extends Component {
  constructor(props) {
    super(props)
    this.closeModal = this.closeModal.bind(this)
    this.headerContainer = this.headerContainer.bind(this)
    this.contentContainer = this.contentContainer.bind(this)
  }

  closeModal() {
    this.props.onClose()
  }

  contentContainer() {
    const {
      selectedElement,
      pinDestination,
      showConnectorModal,
      intl,
      pin,
      vehicleTestId,
    } = this.props

    const isLoading = isEmpty(pinDestination)

    return (
      <>
        <Loadable isLoading={isLoading}>
          <Row className="pin-destination-modal-content">
            <Col span={6}>
              <PinDetails
                selectedElement={selectedElement}
                showConnectorModal={showConnectorModal}
              />
            </Col>
            <Col span={18} className="pin-destination-modal-content-right-pane">
              <TabContent>
                <TabPane
                  id={HARNESS_VIEW}
                  position={0}
                  namespace={PINOUT_NAMESPACE}
                  canBeDeactivated={false}
                >
                  <PinDestinationGraphContainer
                    zoomInControl={ZoomInControl}
                    zoomOutControl={ZoomOutControl}
                    zoomResetControl={ZoomResetControl}
                  />
                </TabPane>

                <TabPane
                  id={VEHICLE_VIEW}
                  position={1}
                  namespace={PINOUT_NAMESPACE}
                >
                  <Topology component={selectedElement} />
                </TabPane>

                <TabPane
                  id={FUSES_AND_RELAYS_VIEW}
                  text={intl.formatMessage({
                    id: 'tracer.fusesAndRelaysView.tabName',
                  })}
                  position={2}
                  namespace={PINOUT_NAMESPACE}
                >
                  <FusesAndRelays
                    componentId={selectedElement.id}
                    pin={pin}
                    vehicleTestId={vehicleTestId}
                    connectorName={getElementName(selectedElement)}
                  />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Loadable>
      </>
    )
  }

  headerContainer() {
    const { onClose } = this.props

    return (
      <>
        <Row>
          <Col span={10}>
            <Button icon={<ArrowLeftOutlined />} onClick={onClose}>
              Go Back
            </Button>
          </Col>
          <Col span={14}>
            <NavigationTabs namespace={PINOUT_NAMESPACE} />
          </Col>
        </Row>
      </>
    )
  }

  render() {
    const { isOpen } = this.props
    return (
      <ModalFullScreen
        HeaderProp={this.headerContainer}
        ContentProp={this.contentContainer}
        isOpen={isOpen}
      />
    )
  }
}

PinDestination.defaultProps = defaultProps
PinDestination.propTypes = propTypes

export default injectIntl(PinDestination)
