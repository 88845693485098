import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import NoGraph from '../components/NoGraph'
import { setNotification } from '../actions/notification-actions'
import {
  MODAL_ADD_FAULT,
  MODAL_SUBMIT_FEEDBACK,
  NOTIFICATION_GENERIC,
} from '../constants'
import { getCircuitCategories } from '../api/logging-api'
import {
  getComponentsForModel,
  getHarnessesForModel,
} from '../api/controller-api'
import { setModal } from '../actions/modal-actions'
import { OverlayButtons } from '../components/OverlayButtons/OverlayButtons'

const { REACT_APP_VERSION } = process.env

class NoGraphContainer extends PureComponent {
  componentDidMount() {
    this.loadData()
  }

  loadData() {
    const { makeModelId } = this.props

    if (makeModelId) {
      getComponentsForModel(makeModelId)
      getHarnessesForModel(makeModelId)
      getCircuitCategories()
    }
  }

  render() {
    return (
      <div className="no-graph-container">
        <NoGraph {...this.props} />
        <OverlayButtons
          open={this.props.open}
          vehicleTestResultId={this.props.vehicleTestResultId}
          openFaultModalHandler={this.props.openAddFaultModal}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  notificationContainer: state.notificationState.container,
  modalContainers: state.modalState.containers,
  faults: state.loggingState.faults,
  componentsForModel: state.componentState.componentsForModel,
  harnessesForModel: state.harnessState.harnessesForModel,
  makeModelId: state.componentState.makeModelId,
  vehicleTestResultId: state.componentState.vehicleTestResultId,
  open: state.sidebar.open,
})

const mapDispatchToProps = (dispatch) => {
  return {
    showNotification: () => {
      dispatch(
        setNotification(
          NOTIFICATION_GENERIC,
          `Tracer Version: ${REACT_APP_VERSION}`,
        ),
      )
    },
    openFeedbackModal: () => dispatch(setModal(MODAL_SUBMIT_FEEDBACK)),
    openAddFaultModal: () => dispatch(setModal(MODAL_ADD_FAULT)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NoGraphContainer)
