// Internet Explorer polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import { datadogRum } from '@datadog/browser-rum'

import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import Root from './Root'
import Title from './HtmlTitle'
import $ from 'jquery'
import { Provider as StoreProvider } from 'react-redux'
import smoothscroll from 'smoothscroll-polyfill'
import 'intersection-observer'
import 'url-search-params-polyfill'
import 'font-awesome/css/font-awesome.css'
import './assets/styles/main.scss'
import 'bootstrap-daterangepicker/daterangepicker.css'
import url from './services/url'
import store from './store'

smoothscroll.polyfill()

window.jQuery = $
require('bootstrap')

ReactDOM.render(
  <StrictMode>
    <StoreProvider store={store}>
      <Root />
    </StoreProvider>
  </StrictMode>,
  document.getElementById('root'),
)
ReactDOM.render(
  <StrictMode>
    <Title />
  </StrictMode>,
  document.getElementsByTagName('title')[0],
)

if (process.env.REACT_APP_ENABLE_DATAGOG === 'true') {
  datadogRum.init({
    applicationId: 'd4d250f6-e28d-4f7a-b84f-a7acdd764198',
    clientToken: 'pub34fca3391e3569407a12c75ccf72453d',
    site: 'datadoghq.com',
    env: url.getCustomerEnvironment(),
    service: url.getCustomerSubdomainPart(),
    version: process.env.REACT_APP_VERSION,
    sampleRate: 100,
    trackInteractions: true,
  })
}
