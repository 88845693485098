import React from 'react'
import { connect } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import './style.scss'
import {
  INIT_MAIN_VIEW_QUERY_PARAM,
  MODAL_ADD_FAULT,
  MODAL_HARNESS_STATE,
  VEHICLE_VIEW,
} from '../../constants'
import Topology from '../SvgViews/Topology'
import { setComponent, setModal } from '../../actions/modal-actions'
import { Button } from 'antd'
import { OverlayButtons } from '../OverlayButtons/OverlayButtons'

const VehicleView = (props) => {
  const dispatch = useDispatch()
  const { open } = props

  const vehicleTestResultId = useSelector(
    (state) => state.componentState.vehicleTestResultId,
  )
  const component = useSelector((state) => state.componentState.selectedElement)

  const openFaultModalHandler = () => dispatch(setModal(MODAL_ADD_FAULT))

  return (
    <>
      {component && component.isEdge ? (
        <Button
          onClick={() => {
            dispatch(setComponent(component))
            dispatch(setModal(MODAL_HARNESS_STATE))
          }}
          dataTestId="vehicle-view-add-faulty-harness-button"
          className="vehicle-view-faulty-harness"
        >
          <FormattedMessage id="generic.faultyHarness" />
        </Button>
      ) : null}
      <Topology open={open} />
      <OverlayButtons
        open={open}
        vehicleTestResultId={vehicleTestResultId}
        openFaultModalHandler={openFaultModalHandler}
        hrefParams={`${INIT_MAIN_VIEW_QUERY_PARAM}=${VEHICLE_VIEW}`}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    open: state.sidebar.open,
  }
}

export default connect(mapStateToProps)(VehicleView)
