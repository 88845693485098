import React, { useState } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { arrayOf, bool, func, number, shape, string } from 'prop-types'
import classNames from 'classnames'
import find from 'lodash/find'
import get from 'lodash/get'

import { capitalizeAllWords } from '../../helpers/utils'
import Topology from '../SvgViews/Topology'
import { formatLocalizedDate } from '../../helpers/datetime'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Row, Col, Button, Input, Select, Flex } from 'antd'
import ModalFullScreen from '../Modal/ModalFullScreen'

const CommentSection = ({ comment }) => {
  const classes = classNames({ 'no-comments': !comment })
  const content = comment || (
    <FormattedMessage id="tracer.componentHistory.noComments" />
  )
  return <p className={classes}>{content}</p>
}

const FaultSourceDetail = ({ problemLog, component }) => {
  const { comment, faultType, relatedIncidentCount } = problemLog
  const { title, description } = component
  return (
    <Flex vertical={true} className="fault-type-container">
      <div className="fault-type-component-details">
        <p>
          <FormattedMessage id="generic.component" />:
        </p>
        <p className="big">{title}</p>
        <p>{description}</p>

        <p>
          <FormattedMessage id="tracer.sameIssues.faultSource" />:
        </p>
        <p className="big fault-type-name">{capitalizeAllWords(faultType)}</p>

        <div className="related-incidents">
          <p>
            <FormattedMessage id="tracer.sameIssues.numberReported" />:
          </p>
          <span className="incident-count">{relatedIncidentCount}</span>
        </div>
      </div>

      <div className="comments-container">
        <p>
          <FormattedMessage id="generic.comments" />:
        </p>
        <CommentSection comment={comment} />
      </div>
    </Flex>
  )
}

const PreviousCaseSelect = ({ value, onChange, history, formatString, tz }) => {
  const selectOptions = history.map((problemLog, index) => {
    const formattedTime = formatLocalizedDate(
      problemLog.timestamp,
      formatString,
      tz,
    )
    console.log(history)
    return {
      value: index,
      label: (
        <span>
          {formattedTime} <span className="vin">{problemLog.vin}</span>
        </span>
      ),
    }
  })

  return (
    <Select
      value={selectOptions[value]}
      options={selectOptions}
      onChange={onChange}
      className="select-previous-test-case"
    />
  )
}

function SameIssuesHistory(props) {
  const { intl, isOpen, onClose, components, historiesForComponents, tz } =
    props

  const firstProblemLogIndex = 0
  const [selectedProblemLogIndex, onProblemLogSelect] =
    useState(firstProblemLogIndex)
  const selectedProblemLog = get(
    historiesForComponents,
    `[${selectedProblemLogIndex}]`,
  )
  const selectedComponentId = get(selectedProblemLog, 'componentId')
  const selectedComponent = find(components, { id: selectedComponentId })

  const formatString = intl.formatMessage({ id: 'format.timestamp' })

  const header = () => {
    return (
      <Row>
        <Col span={9}>
          <Row gap={2}>
            <Col span={5}>
              <Button icon={<ArrowLeftOutlined />} onClick={onClose}>
                Go Back
              </Button>
            </Col>
            <Col span={19} className="modal-title-container">
              <FormattedMessage id="tracer.sameIssues.modalHeader" />
            </Col>
          </Row>
        </Col>
        <Col span={15}>
          <Flex justify="flex-end" align="center">
            <PreviousCaseSelect
              value={selectedProblemLogIndex}
              history={historiesForComponents}
              formatString={formatString}
              tz={tz}
              onChange={(newIndex) => onProblemLogSelect(newIndex)}
            />
          </Flex>
        </Col>
      </Row>
    )
  }

  const content = () => {
    return (
      <Row className="same-issues-content-container">
        <Col span={8}>
          <FaultSourceDetail
            problemLog={selectedProblemLog}
            component={selectedComponent}
          />
        </Col>
        <Col span={16}>
          <Topology component={selectedComponent} />
        </Col>
      </Row>
    )
  }

  return (
    <ModalFullScreen
      HeaderProp={header}
      ContentProp={content}
      isOpen={props.isOpen}
      dataTestIdContainer={'same-issues-history-modal-container'}
      dataTestIdHeader={'same-issues-history-modal-header'}
      dataTestIdContent={'same-issues-history-modal-content'}
    />
  )
}

SameIssuesHistory.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  historiesForComponents: arrayOf(
    shape({
      timestamp: string.isRequired,
      comment: string,
      componentId: string,
      faultType: string.isRequired,
      relatedIncidentCount: number.isRequired,
      vin: string.isRequired,
    }),
  ).isRequired,
}

export default injectIntl(SameIssuesHistory)
