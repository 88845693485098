import React from 'react'
import { Field } from 'redux-form'
import isArray from 'lodash/isArray'
import ErrorMessage from './ErrorMessage'
import { Select } from 'antd'

const selectComponent = ({
  input,
  options,
  disabled,
  clearable,
  placeholder,
  meta,
  multiselect,
  defaultValue,
}) => {
  return (
    <>
      {!multiselect && (
        <div>
          <Select
            value={input.value}
            options={options}
            disabled={disabled}
            onChange={(value) => input.onChange(value)}
            placeholder={placeholder}
            allowClear={clearable}
          />
          <ErrorMessage meta={meta} />
        </div>
      )}
      {multiselect && (
        <div>
          <Select
            mode="multiple"
            defaultValue={defaultValue}
            options={options}
            disabled={disabled}
            onChange={(value) => input.onChange(value)}
            placeholder={placeholder}
            allowClear={clearable}
          />
        </div>
      )}
    </>
  )
}

export default function (props) {
  let validators = null

  if (!props.disabled && props.validate) {
    validators = isArray(props.validate) ? props.validate : [props.validate]
  }

  return (
    <Field
      name={props.name}
      validate={validators}
      component={selectComponent}
      disabled={props.disabled}
      options={props.options}
      placeholder={props.placeholder}
      clearable={props.clearable}
      multiselect={props.multiselect}
      defaultValue={props.defaultValue}
    />
  )
}
