import React, { useRef } from 'react'
import { connect, useSelector, shallowEqual, useDispatch } from 'react-redux'
import get from 'lodash/get'
import { injectIntl } from 'react-intl'

import ComponentListContainer from '../../containers/ComponentListContainer'
import VinPanelContainer from '../../containers/VinPanelContainer'
import BackButton from './BackButton'
import { Flex } from 'antd'

const LeftSideBar = ({ intl }) => {
  const isEphemeralVehicleTest = useSelector(
    (state) => state.ephemeralVehicleTest.isEphemeralVehicleTest,
    shallowEqual,
  )

  const selectedComponent = useSelector(
    (state) => state.componentState.selectedElement,
    shallowEqual,
  )

  const containerRef = useRef(null)
  const selectedRef = useRef(null)

  React.useEffect(() => {
    if (selectedRef.current != null) {
      containerRef.current.scrollTop =
        selectedRef.current.offsetTop - containerRef.current.offsetTop - '5'
    }
  }, [selectedComponent])

  return (
    <Flex vertical className="left-panel-container">
      <Flex vertical className="left-panel-header">
        <BackButton intl={intl} />
        {!isEphemeralVehicleTest && <VinPanelContainer />}
        <div className="left-panel-body-title">
          {intl.formatMessage({ id: 'tracer.componentListPanel.title' })}
        </div>
      </Flex>
      <Flex vertical gap="small" className="left-panel-body" ref={containerRef}>
        <ComponentListContainer selectedRef={selectedRef} />
      </Flex>
    </Flex>
  )
}

export default injectIntl(LeftSideBar)
