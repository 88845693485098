import React from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import { useCanvas } from 'reaflow'

import './CustomPort.scss'

/*
  Port is based on reaflow Port component.
 */
export const Port = ({
  id,
  x,
  y,
  rx,
  ry,
  offsetX,
  offsetY,
  disabled,
  className,
  properties,
  style,
  labelStyle,
  children,
  active,
  onDrag = () => undefined,
  onDragStart = () => undefined,
  onDragEnd = () => undefined,
  onEnter = () => undefined,
  onLeave = () => undefined,
  onClick = () => undefined,
}) => {
  const [isHovered, setIsHovered] = React.useState(false)
  const { selections, ...canvas } = useCanvas()
  const newX = x - properties.width / 2
  const newY = y - properties.height / 2
  const isDisabled = properties.disabled || disabled
  const isActive = selections?.length
    ? selections.includes(properties.id)
    : null
  const portChildProps = {
    port: properties,
    isHovered,
    isDisabled,
    x,
    y,
    rx,
    ry,
    offsetX,
    offsetY,
  }

  return (
    <g id={id}>
      <motion.rect
        key={`${x}-${y}`}
        style={style}
        height={properties.height}
        width={properties.width}
        x={newX}
        y={newY}
        rx={rx}
        ry={ry}
        className={classNames('port', className, properties?.className, {
          ['active']: isActive,
        })}
        onMouseEnter={(event) => {
          event.stopPropagation()
          if (!isDisabled) {
            setIsHovered(true)
            onEnter(event, properties)
          }
        }}
        onMouseLeave={(event) => {
          event.stopPropagation()
          if (!isDisabled) {
            setIsHovered(false)
            onLeave(event, properties)
          }
        }}
        onClick={(event) => {
          event.stopPropagation()
          if (!isDisabled) {
            onClick(event, properties)
          }
        }}
        whileHover={{ scale: 1.1 }}
      />
      <g transform={`translate(${x}, ${y})`}>
        <motion.text
          className={classNames('port-label', {
            ['active']: isActive,
          })}
          dominantBaseline="middle"
          textAnchor="middle"
          style={labelStyle}
        >
          {properties.label}
        </motion.text>
        {children && (
          <>
            {typeof children === 'function'
              ? children(portChildProps)
              : children}
          </>
        )}
      </g>
    </g>
  )
}
