import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Controlled as CodeMirror } from 'react-codemirror2'

import { createDemoPayloadList } from '../selectors/demo'
import ErrorMessage from './Fields/ErrorMessage'

import { getHarnessDataForDemo } from '../api/harness-api'
import isEmpty from 'lodash/isEmpty'
import { isJson, required } from '../validators'

import Loading from './Loading'
import { FormattedMessage, injectIntl } from 'react-intl'
import Subtitle from './Subtitle'

import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/javascript/javascript'
import { Button, Typography } from 'antd'

const { Link } = Typography

class CodeField extends React.Component {
  render = () => {
    const { input, meta } = this.props
    return (
      <div>
        <CodeMirror
          onBeforeChange={(e, target, value) => {
            input.onChange(value)
          }}
          value={input.value}
          options={{
            lineNumbers: true,
            viewportMargin: Number.POSITIVE_INFINITY, // Allows the text search to work in browser
            mode: { name: 'javascript', json: true },
            preserveScrollPosition: true,
          }}
        />
        <ErrorMessage meta={meta} ignoreTouched />
      </div>
    )
  }
}

class CustomPayloadForm extends React.Component {
  componentDidUpdate = (prevProps, prevState) => {
    // Select first option by default
    if (
      this.payloadSelector &&
      !this.payloadSelector.value &&
      !isEmpty(this.props.payloadOptions) &&
      !this.payload.value
    ) {
      const option = this.props.payloadOptions[0].props.value
      this.updatePayloadFromOption(option)
    }
  }

  render = () => {
    if (this.props.submitting) {
      return <Loading />
    }

    return (
      <>
        <div className="text-center header-relative">
          <h2>
            <FormattedMessage id="demoTracer.payloadTitle" />
          </h2>
          <Subtitle>
            <FormattedMessage id="demoTracer.payloadDescription" />
            <Link href="/reports">
              <FormattedMessage id="demoTracer.payloadDescriptionClickable" />
            </Link>
          </Subtitle>
        </div>
        <br />
        <form
          className="form form-payload"
          onSubmit={this.props.handleSubmit(this.submit)}
        >
          <label>
            <FormattedMessage id="demoTracer.payloadLabel" />
          </label>
          <Field
            forwardRef={true}
            ref={(c) => (this.payloadSelector = c)}
            component="select"
            name="payloadSelector"
            className="form-control"
            onChange={this.handleOptionChange}
          >
            {this.props.payloadOptions}
          </Field>
          <Field
            forwardRef={true}
            ref={(c) => (this.payload = c)}
            name="payload"
            validate={[required, isJson]}
            component={CodeField}
          />
          <br />
          <br />
          <Button
            disabled={this.props.pristine || this.props.submitting}
            type="primary"
            htmlType="submit"
          >
            {this.props.intl.formatMessage({ id: 'forms.submit' })}
          </Button>
        </form>
      </>
    )
  }

  handleOptionChange = (e, value, oldValue) => {
    this.updatePayloadFromOption(value)
  }

  updatePayloadFromOption = (option) => {
    const payload = JSON.stringify(this.props.demoPayloads[option], null, 2)
    this.props.blur('payload', payload)
  }

  submit = ({ payload }) => {
    return getHarnessDataForDemo(
      JSON.parse(payload),
      this.props.user.data.token,
    )
  }
}

CustomPayloadForm = reduxForm({
  form: 'customPayload', // a unique identifier for this form
  destroyOnUnmount: false,
})(CustomPayloadForm)

const mapStateToProps = (state, ownProps) => {
  return {
    demoPayloads: state.componentState.demoPayloads,
    payloadOptions: createDemoPayloadList(state),
  }
}

export default injectIntl(connect(mapStateToProps)(CustomPayloadForm))
