import React from 'react'
import PropTypes from 'prop-types'

import {
  PlusOutlined,
  MinusOutlined,
  CompressOutlined,
} from '@ant-design/icons'

export const ZoomInControl = ({ zoomIn }) => (
  <div onClick={zoomIn}>
    <PlusOutlined />
  </div>
)

ZoomInControl.propTypes = {
  zoomIn: PropTypes.func,
}

export const ZoomOutControl = ({ zoomOut }) => (
  <div onClick={zoomOut}>
    <MinusOutlined />
  </div>
)

ZoomOutControl.propTypes = {
  zoomOut: PropTypes.func,
}

export const ZoomResetControl = ({ zoomReset }) => (
  <div onClick={zoomReset}>
    <CompressOutlined />
  </div>
)

ZoomResetControl.propTypes = {
  zoomReset: PropTypes.func,
}

export const ZoomControl = ({ zoomIn, zoomOut, zoomReset }) => (
  <div className="zoom-controls">
    <ZoomInControl zoomIn={zoomIn} />
    <ZoomResetControl zoomReset={zoomReset} />
    <ZoomOutControl zoomOut={zoomOut} />
  </div>
)
