import React from 'react'
import classnames from 'classnames'
import { useDTCMemo } from '../../hooks/DTCMemo'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useComponentDetails } from '../../hooks/ComponentDetails'
import { FormattedMessage } from 'react-intl'

export function MappingsHeader({ dtcMemoId, children }) {
  const { dtcMemo, isLoading, isSuccess } = useDTCMemo(dtcMemoId)

  let dtcText = ''

  if (dtcMemo && isSuccess) {
    dtcText = dtcMemo.short_desc
      ? `${dtcMemo.dtc} — ${dtcMemo.short_desc}`
      : `${dtcMemo.dtc}`
  }

  return (
    <>
      {isLoading && 'Loading'}
      {isSuccess && (
        <div className="wizard-nav-breadcrumb">
          {dtcText}&nbsp;{children && `/`}&nbsp;{children}
        </div>
      )}
    </>
  )
}

export function DestinationComponentHeader({ dtcMemoId }) {
  return (
    <MappingsHeader dtcMemoId={dtcMemoId}>
      <FormattedMessage id="crowdsourcedDtcMapping.addConnectorMappings" />
    </MappingsHeader>
  )
}

export function EditDTCMappingsHeader({ dtcMemoId }) {
  return (
    <MappingsHeader dtcMemoId={dtcMemoId}>Edit DTC Mappings</MappingsHeader>
  )
}

export function StartComponentHeader({ dtcMemoId, vin }) {
  const { dtcMemo, isLoading, isSuccess } = useDTCMemo(dtcMemoId)
  // components, selectedComponent, pins, jumperPins, etc.
  const componentId = useSelector(
    (state) => state.dtcMapping.selectedComponentId,
    shallowEqual,
  )

  const { componentDetails, isLoading: componentDetailsIsLoading } =
    useComponentDetails({ vin, componentId })

  // pinNumber, currentPin, componentId, pinDestination, etc.
  const selectedStartComponent = useSelector(
    (state) => state.pinState.selectedElement,
    shallowEqual,
  )

  let dtcText = ''

  if (dtcMemo && isSuccess) {
    dtcText = dtcMemo.short_desc
      ? `${dtcMemo.dtc} — ${dtcMemo.short_desc}`
      : `${dtcMemo.dtc}`
  }

  const destinationComponentText = componentDetails
    ? `${componentDetails.component.alias} - ${componentDetails.component.description}`
    : ``
  const startComponentText = selectedStartComponent.id
    ? `${selectedStartComponent.alias} - ${selectedStartComponent.description}`
    : ``

  return (
    <>
      {(isLoading || componentDetailsIsLoading) && 'Loading...'}
      {isSuccess && (
        <div className="wizard-nav-breadcrumb">
          {`${dtcText} / ${destinationComponentText}`}
          {startComponentText && ` / ${startComponentText}`}
        </div>
      )}
    </>
  )
}

export function Button({ onClick, disabled, children, secondary = false }) {
  const btnClassNames = classnames('btn uppercase', {
    'btn-secondary': secondary,
    'btn-default': !secondary,
  })
  return (
    <button disabled={disabled} className={btnClassNames} onClick={onClick}>
      {children}
    </button>
  )
}

export function TableButton({ onClick, disabled, children, testid }) {
  return (
    <button
      disabled={disabled}
      className="btn table-btn uppercase"
      onClick={onClick}
      data-testid={testid}
    >
      {children}
    </button>
  )
}
