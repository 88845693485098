import React from 'react'
import { func, bool } from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Modal, Button } from 'antd'

const propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
}

function VerifyComponentConfirmationModal(props) {
  const { isOpen, onClose, onSubmit, title } = props

  return (
    <Modal
      open={isOpen}
      title={
        <FormattedMessage
          id="verifyComponentConfirmation.title"
          values={{ title }}
        />
      }
      onOk={onSubmit}
      onCancel={onClose}
      footer={[
        <Button onClick={onClose}>
          <FormattedMessage id="forms.cancel" />
        </Button>,
        <Button type="primary" onClick={onSubmit}>
          <FormattedMessage id="forms.confirm" />
        </Button>,
      ]}
      centered={true}
    >
      <FormattedMessage
        id="verifyComponentConfirmation.content"
        values={{ title }}
      />
    </Modal>
  )
}

VerifyComponentConfirmationModal.propTypes = propTypes

export default injectIntl(VerifyComponentConfirmationModal)
