import { connect } from 'react-redux'
import ComponentList from '../components/LeftSideBar/ComponentList'
import { selectElement } from '../thunks/component'
import { createConnectorSetFromToggledDtcs, createDtcList } from '../selectors'
import { highlightDtcs, toggleOffDtc } from '../actions/dtc-actions'

const mapStateToProps = (state, ownProps) => {
  return {
    elements: state.componentState.elements,
    selectedComponent: state.componentState.selectedElement,
    componentStates: state.loggingState.componentStates,
    faults: state.loggingState.faults,
    dtcs: createDtcList(state),
    connectorSet: createConnectorSetFromToggledDtcs(state),
    toggledOnDtc: state.dtcState.toggledOnDtc,
    ...ownProps,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    selectFirstComponent: (component, dtcs, toggledOnDtc) => {
      dispatch(selectElement(component, true))
      dispatch(toggleOffDtc(toggledOnDtc))
      dispatch(
        highlightDtcs(
          dtcs.filter(
            (dtc) => dtc.components && dtc.components.has(component.id),
          ),
        ),
      )
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ComponentList)
