import React from 'react'
import { Button, Flex } from 'antd'
import { FormattedMessage } from 'react-intl'

export const ButtonGroup = ({ faultType, submit, goBack }) => {
  return (
    <Flex vertical={false} gap="middle">
      <Button
        onClick={submit}
        type="primary"
        disabled={faultType === '' || !faultType}
      >
        <FormattedMessage id="forms.submit" />
      </Button>
      <Button onClick={goBack}>
        <FormattedMessage id="forms.cancel" />
      </Button>
    </Flex>
  )
}
