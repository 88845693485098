import React from 'react'
import classnames from 'classnames'
import tracerLogo from '../assets/images/headertracerlogo.png'
import url from '../services/url'

export default function Logo({ navigateToDashboard, className }) {
  const [customerLogo, setCustomerLogo] = React.useState(null)
  const client = url.getCustomerSubdomainPart()

  const classNames = classnames('navbar-logo', 'clickable', className)

  import(`../assets/images/logo-${client}.png`)
    .then((module) => {
      setCustomerLogo(module.default)
    })
    .catch(() => {
      // Customer can be without logo.
    })

  return (
    <a onClick={navigateToDashboard} className={classNames}>
      {customerLogo ? (
        <div className="logo-container">
          <div>
            <img src={tracerLogo} alt="tracer logo" />
          </div>
          <div>
            <img src={customerLogo} alt="customer logo" />
          </div>
        </div>
      ) : (
        <img src={tracerLogo} alt="tracer logo" />
      )}
    </a>
  )
}
