import * as types from '../actions/action-types'
import { DARK_MODE } from '../constants'

const initialState = {
  mainDashboardPageNum: 0,
  vin: null,
  theme: DARK_MODE,
}

const appReducer = function (state = initialState, action) {
  switch (action.type) {
    case types.SET_MAIN_DASHBOARD_PAGE_NUM:
      return Object.assign({}, state, {
        mainDashboardPageNum: action.pageNum,
      })
    case types.SET_VIN:
      return Object.assign({}, state, {
        vin: action.vin,
      })
    case types.RESET_VIN:
      return Object.assign({}, state, {
        vin: initialState.vin,
      })
    case types.TOGGLE_THEME:
      return Object.assign({}, state, {
        theme: action.updatedTheme,
      })
    default:
      return state
  }
}

export default appReducer
