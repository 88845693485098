import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useMeasure, useDebounce } from 'react-use'
import { FormattedMessage } from 'react-intl'
import { Table, Switch, Button } from 'antd'
import Pin from './Pin'
import PinEmpty from './PinEmpty'
import { strategyFactory } from '../selectors/pinout'

import { ReactComponent as GroundIcon } from '../assets/images/icons/svg/pinout/GND.svg'
import { ReactComponent as PowerIcon } from '../assets/images/icons/svg/pinout/PWR.svg'
import { ReactComponent as CANBusIcon } from '../assets/images/icons/svg/pinout/CAN.svg'
import { DARK_MODE } from '../constants'

function getCircuitIcon(circuitCategory) {
  const iconConfig = {
    GND: GroundIcon,
    PWR: PowerIcon,
    CAN: CANBusIcon,
  }
  return iconConfig[circuitCategory]
}

function pinFormatter(content) {
  const pin = content ? <Pin colors={content} /> : <PinEmpty />
  return <div>{pin}</div>
}

function categoryFormatter(content, row) {
  const CircuitIcon = getCircuitIcon(content)
  if (CircuitIcon) {
    return (
      <div
        key={crypto.randomUUID()}
        style={{ height: '25px', position: 'relative' }}
      >
        <CircuitIcon className="circuit-category-img" />
      </div>
    )
  } else {
    return null
  }
}

const tracePinFormatterFactory =
  (vin, selectedElement, onPinClick) => (content, row) => {
    const actionContent = []
    const pinClickHandler = () => {
      onPinClick(row, vin, selectedElement, row.originalCavityNumber)
    }

    if (content) {
      actionContent.push(
        <Button
          key={`circuit-details-${row.originalCavityNumber}`}
          onClick={pinClickHandler}
        >
          <FormattedMessage id="pinout.buttons.circuitDetails" />
        </Button>,
      )
    }

    return <div>{actionContent}</div>
  }

const hairlineWidth = 1
const centerLineMargin = 3

const VerticalLine = ({ height, iconColors }) => {
  const styles = {
    width: hairlineWidth,
    height,
    border: `solid 1px ${iconColors}`,
    marginLeft: centerLineMargin,
  }
  return <div style={styles} />
}

const Circle = ({ iconColors }) => {
  const centerCirclePadding = 2
  const styles = {
    paddingRight: centerCirclePadding,
    borderRadius: 4,
    width: 8,
    height: 8,
    backgroundColor: iconColors,
  }
  return <div style={styles} />
}

function JumperConnection({ iconColors }) {
  return (
    <>
      <Circle iconColors={iconColors} />
      <VerticalLine height={50} iconColors={iconColors} />
      <Circle iconColors={iconColors} />
    </>
  )
}

function PinTable({
  vin,
  selectedElement,
  onPinClick,
  showEmptyPinsToggle = true,
  showEmptyPinsDefault = false,
  open,
  theme,
}) {
  const [containerRef, { height: containerHeight }] = useMeasure()
  const [showEmptyPins, setShowEmptyPins] = React.useState(showEmptyPinsDefault)
  const [tableHeight, setTableHeight] = React.useState(0)
  const iconColors = theme != DARK_MODE ? '#000000' : '#ffffff'

  // containerHeight changes its height multiple times during re-render
  // but only the largest (last) value is needed.
  useDebounce(
    () => {
      setTableHeight(containerHeight - 50 - (showEmptyPinsToggle ? 50 : 0)) // 50 is the height of header and another 50 is the height of summary row.
    },
    1,
    [containerHeight],
  )

  useEffect(() => {
    const pinTable = document.getElementById('pin-table')
    if (open) {
      pinTable.style.width = 'calc(100% - 278px)'
    } else {
      pinTable.style.width = '100%'
    }
  }, [open])

  const { pins, jumperPins } = strategyFactory(selectedElement)(
    selectedElement,
    showEmptyPins,
  )
  const tracerPinFormatter = tracePinFormatterFactory(
    vin,
    selectedElement,
    onPinClick,
  )

  const columns = [
    {
      title: '',
      key: 'jumper',
      render: (text, record, index) => {
        return record.is_jumper && index % 2 === 0 ? (
          <JumperConnection iconColors={iconColors} />
        ) : null
      },
      width: '5%',
      onCell: (record, index) => {
        if (record.is_jumper) {
          if (index % 2 === 0) {
            return { rowSpan: 2 }
          } else {
            return { rowSpan: 0 }
          }
        }
        return {}
      },
    },
    {
      title: <FormattedMessage id="pinout.columns.circuit" />,
      dataIndex: 'circuit',
      key: 'circuit',
      width: '15%',
    },
    {
      title: <FormattedMessage id="pinout.columns.pin" />,
      key: 'cavity',
      render: (text, record, index) => (
        <div style={{ display: 'inline-block' }}>{record.cavity}</div>
      ),
      width: '15%',
    },
    {
      title: '',
      key: 'colors',
      render: (text, record, index) => pinFormatter(record.colors),
      width: '7%',
    },
    {
      title: <FormattedMessage id="pinout.columns.color" />,
      dataIndex: 'color_desc',
      key: 'color',
      width: '9%',
    },
    {
      title: '',
      key: 'category',
      render: (text, record, index) =>
        categoryFormatter(record.circuit_category, record),
      width: '9%',
      ellipsis: true,
    },
    {
      title: <FormattedMessage id="pinout.columns.signalDescription" />,
      dataIndex: 'signal_desc',
      key: 'signalDescription',
      ellipsis: true,
    },
    {
      title: '',
      key: 'action',
      render: (text, record, index) =>
        tracerPinFormatter(record.originalCavityNumber, record),
    },
  ]
  const allPins = [...pins, ...jumperPins]

  return (
    <div id="pin-table" ref={containerRef}>
      <Table
        data-testid="pinout-table"
        summary={() =>
          showEmptyPinsToggle && (
            <Table.Summary fixed="top">
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={8}>
                  <Switch
                    checkedChildren={
                      <FormattedMessage id="pinout.buttons.allPins" />
                    }
                    unCheckedChildren={
                      <FormattedMessage id="pinout.buttons.allPins" />
                    }
                    checked={showEmptyPins}
                    onChange={() => setShowEmptyPins(!showEmptyPins)}
                  />
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )
        }
        columns={columns}
        dataSource={allPins}
        pagination={false}
        scroll={{
          y: tableHeight,
        }}
        size="middle"
        rowKey={() => crypto.randomUUID()}
      />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    open: state.sidebar.open,
    theme: state.app.theme,
    ...ownProps,
  }
}

export default connect(mapStateToProps)(PinTable)
