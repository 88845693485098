import React, { useState } from 'react'
import { Spin } from 'antd'
import { useDiagram } from '../DiagramContext'
import { useComponentPinout, useComponentFullPinout } from '../../BlockDiagram'
import PinoutTable from './PinoutTable'
import { Loadable } from '../../components/Loading'

const ConnectorSinglePinInfo = ({ isAllPins, setIsAllPins, pageSize }) => {
  const diagram = useDiagram()
  const { isLoading, isSuccess, isIdle, pinout } = useComponentPinout({
    vin: diagram.vin,
    componentId: diagram.detailDiagram.data.componentId,
    neighborIds: diagram.detailDiagram.data.neighborIds,
  })

  return (
    <>
      <Loadable showText={false} isLoading={isLoading || !isSuccess}>
        <PinoutTable
          pinout={
            pinout
              ? pinout.filter(
                  (pinout_data) =>
                    pinout_data.pin === diagram.detailDiagram.data.pin,
                )
              : []
          }
          isLoading={isLoading}
          isAllPins={isAllPins}
          setIsAllPins={setIsAllPins}
          pageSize={pageSize}
        />
      </Loadable>
    </>
  )
}

const ConnectorFullPinout = ({ isAllPins, setIsAllPins, pageSize }) => {
  const diagram = useDiagram()
  const { isLoading, pinout } = useComponentFullPinout({
    vin: diagram.vin,
    componentId: diagram.detailDiagram.data.componentId,
  })

  return (
    <PinoutTable
      pinout={pinout}
      isLoading={isLoading}
      isAllPins={isAllPins}
      setIsAllPins={setIsAllPins}
      pageSize={pageSize}
    />
  )
}

const ConnectorPinout = ({ pageSize }) => {
  const [isAllPins, setIsAllPins] = useState(false)
  return isAllPins ? (
    <ConnectorFullPinout
      isAllPins={isAllPins}
      setIsAllPins={setIsAllPins}
      pageSize={pageSize}
    />
  ) : (
    <ConnectorSinglePinInfo
      isAllPins={isAllPins}
      setIsAllPins={setIsAllPins}
      pageSize={pageSize}
    />
  )
}

export default ConnectorPinout
