import React from 'react'
import { Popover, Button } from 'antd'
import { injectIntl } from 'react-intl'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { showDtcMemoPopup } from '../../actions/dtc-actions'

const DTCMemoTooltip = ({ intl, dtc, isSelected, children }) => {
  const dispatch = useDispatch()
  const isDtcMemoTooltipOpen = useSelector(
    (state) => state.dtcState.dtcMemoTooltip,
    shallowEqual,
  )
  const hasDtcMemo = !!dtc.memo && !!dtc.memo.memo

  return (
    <Popover
      title={!hasDtcMemo && ''}
      open={isDtcMemoTooltipOpen && hasDtcMemo && isSelected}
      trigger="click"
      placement="left"
      zIndex={8000}
      overlayClassName="dtc-memo-tooltip"
      content={() => (
        <Button onClick={() => dispatch(showDtcMemoPopup())}>
          {intl.formatMessage({ id: 'dtcMemo.tooltip' })}
        </Button>
      )}
      overlayStyle={{
        width: 'max-content',
      }}
    >
      {children}
    </Popover>
  )
}

export default injectIntl(DTCMemoTooltip)
