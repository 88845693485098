import React from 'react'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import { useHistory } from 'react-router'

import './style.scss'
import { formatTestZone, formatTimestamp } from '../../helpers/utils'
import { usePrevNextNavigation } from '../../hooks/VehicleTestNavigation'
import { Radio } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

function formatNavigationObject(obj, timestampFormat, defaultLabel) {
  if (obj) {
    return `${formatTestZone(obj.test_zone_description)}${formatTimestamp(timestampFormat, obj.timestamp)}`
  }
  return defaultLabel
}

const VehicleTestResultNavigation = ({
  intl,
  vehicleTestResultId,
  hrefParams = null,
}) => {
  const history = useHistory()

  const {
    navigation = {},
    isLoading,
    isSuccess,
  } = usePrevNextNavigation({
    vehicleTestResultId,
  })

  if (!vehicleTestResultId) {
    return null
  }

  const { prev, next } = navigation
  const timestampFormat = intl.formatMessage({ id: 'format.dayAndTime' })

  let prevLabel = null
  let nextLabel = null

  if (isLoading) {
    prevLabel = nextLabel = intl.formatMessage({ id: 'processes.loading' })
  } else if (isSuccess) {
    prevLabel = formatNavigationObject(
      prev,
      timestampFormat,
      intl.formatMessage({ id: 'generic.first' }),
    )
    nextLabel = formatNavigationObject(
      next,
      timestampFormat,
      intl.formatMessage({ id: 'generic.last' }),
    )
  } else {
    prevLabel = nextLabel = intl.formatMessage({ id: 'processes.error' })
  }

  const basicUrl = `/navigation/vehicle_test_result/${vehicleTestResultId}`
  const search = history.location.search || hrefParams

  return (
    <Radio.Group value="" className="vehicle-test-result-navigation-radio">
      <Radio.Button
        onClick={() => history.push({ pathname: `${basicUrl}/prev`, search })}
        disabled={!prev}
      >
        <LeftOutlined />
        <span className="text-left" data-cy="vehicle-test-result-prev">
          {prevLabel}
        </span>
      </Radio.Button>
      <Radio.Button
        onClick={() => history.push({ pathname: `${basicUrl}/next`, search })}
        disabled={!next}
      >
        <span className="text-right" data-cy="vehicle-test-result-next">
          {nextLabel}
        </span>
        <RightOutlined />
      </Radio.Button>
    </Radio.Group>
  )
}

export default injectIntl(VehicleTestResultNavigation)
