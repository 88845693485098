import React from 'react'
import history from '../../history'
import { useCreateDTCMemo } from '../../hooks/DTCMemo'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'

import { EditOutlined, PlusOutlined } from '@ant-design/icons'

import { Button } from 'antd'
import { hideDtcMemoPopup } from '../../actions/dtc-actions'

const DTCMemoType = Object.freeze({
  CROWDSOURCED: 'crowdsourced',
  IMPORTED: 'imported',
})

const CreateMemo = ({
  makeModelId,
  acronym,
  dtc,
  shortDescription,
  memo = null,
}) => {
  const createMemo = useCreateDTCMemo()
  const dispatch = useDispatch()
  const vin = useSelector(
    (state) => state.vehicleTests.currentVehicleTest?.vin,
    shallowEqual,
  )

  const handleClick = (e) => {
    e.stopPropagation()
    dispatch(hideDtcMemoPopup())
    const data = createMemo.mutate(
      { makeModelId, acronym, shortDescription, memo, dtc },
      {
        onSuccess: (data, variables, context) =>
          history.push(`/dtc-mapping/${data.id}/${vin}`),
      },
    )
  }
  return <AddIcon className="memo-edit-icon" onClick={handleClick} />
}

const EditMemo = ({
  memoId,
  icon,
  initialMemo = null,
  initialShortDescription = null,
}) => {
  const Icon = icon
  const dispatch = useDispatch()
  const vin = useSelector(
    (state) => state.vehicleTests.currentVehicleTest?.vin,
    shallowEqual,
  )

  const handleClick = (e) => {
    e.stopPropagation()
    dispatch(hideDtcMemoPopup())
    history.push(`/dtc-mapping/${memoId}/${vin}`, {
      initialMemo,
      initialShortDescription,
    })
  }
  return <Icon className="memo-edit-icon" onClick={handleClick} />
}

function MemoButton({ dtc, makeModelId, acronym, editIcon, addIcon }) {
  const EditIcon = editIcon
  const AddIcon = addIcon

  if (!dtc.memo) {
    // Create new Memo
    return (
      <CreateMemo
        makeModelId={makeModelId}
        acronym={acronym}
        shortDescription={dtc.description}
        dtc={dtc.dtc_code}
        icon={AddIcon}
      />
    )
  }

  if (dtc.memo) {
    if (dtc.memo.memo_type === DTCMemoType.CROWDSOURCED) {
      return (
        <EditMemo
          memoId={dtc.memo.id}
          icon={dtc.memo.memo ? EditIcon : AddIcon}
          initialShortDescription={dtc.description}
        />
      )
    }
    if (dtc.memo.memo_type === DTCMemoType.IMPORTED) {
      if (dtc.memo.alternative_memo_id) {
        // In case of imported memo its memo and short description is passed as initial values
        return (
          <EditMemo
            memoId={dtc.memo.alternative_memo_id}
            initialMemo={dtc.memo.memo}
            initialShortDescription={dtc.description}
            icon={dtc.memo.memo ? EditIcon : AddIcon}
          />
        )
      } else {
        return (
          <CreateMemo
            makeModelId={makeModelId}
            acronym={acronym}
            shortDescription={dtc.description}
            dtc={dtc.dtc_code}
            memo={dtc.memo.memo}
            icon={EditIcon}
          />
        )
      }
    }
  }
  return null
}

export function EditButton({ onClick, className }) {
  return (
    <Button onClick={onClick}>
      <FormattedMessage id="tracer.dtcListPanel.memoButton.editMemo" />
    </Button>
  )
}

export function AddButton({ onClick, className }) {
  return (
    <Button onClick={onClick}>
      <FormattedMessage id="tracer.dtcListPanel.memoButton.addMemo" />
    </Button>
  )
}

export function EditIcon({ onClick, className }) {
  return (
    <span className={className} onClick={onClick}>
      <EditOutlined />
    </span>
  )
}

export function AddIcon({ onClick, className }) {
  return (
    <span className={className} onClick={onClick}>
      <PlusOutlined />
    </span>
  )
}

export default injectIntl(MemoButton)
