import React from 'react'
import BaseNode from './BaseNode'
import NodeLabel from '../labels/Label'
import GroundIcon from '../../assets/images/icons/svg/ic-ground.svg'
import GroundIconLight from '../../assets/images/icons/svg/ic-ground-light.svg'
import { DARK_MODE } from '../../constants/misc'

const GroundLabel = ({ ...props }) => {
  return <NodeLabel {...props} text="" />
}

export default function GroundNode({
  node,
  onPortClick,
  onNodeClick,
  ...props
}) {
  const { width, height } = node
  return (
    <BaseNode
      {...props}
      node={node}
      label={<GroundLabel />}
      onPortClick={onPortClick}
      onNodeClick={onNodeClick}
    >
      {(event) => {
        return (
          <image
            pointerEvents={'none'}
            width={width}
            height={height}
            href={
              props.theme
                ? props.theme == DARK_MODE
                  ? GroundIcon
                  : GroundIconLight
                : GroundIcon
            }
          />
        )
      }}
    </BaseNode>
  )
}
