export const AMBER_COLOR = '#ffc000'
export const AZURE_RADIANCE_COLOR = '#0089E9'
export const BLACK_COLOR = '#000000'
export const BRAND_BLUE = '#0189E9'
export const COMET_COLOR = '#5a6382'
export const DODGER_BLUE_COLOR = '#47b3ff'
export const EBONY_CLAY_COLOR = '#272F42'
export const GRAY_COLOR = '#8f8f8f'
export const LIGHT_GRAY_COLOR = '#b5b5b5'
export const GREEN_COLOR = '#1cae5b'
export const LIGHT_GREEN_COLOR = '#0ccf61'
export const HIGHLIGHT_PIN_COLOR = '#E04F4D'
export const PICKLED_BLUEWOOD_COLOR = '#2E374E'
export const BRAND_TRANSPARENT_DARK_BLUE_COLOR = '#0069B2'
export const PIPER_COLOR = '#c36a26'
export const DARK_PIPER_COLOR = '#a3581f'
export const RED_COLOR = '#cc422e'
export const LIGHT_RED_COLOR = '#e65540'
export const SUPERNOVA_COLOR = '#FFC700'
export const TURBO_COLOR = '#ffe800'
export const VALENCIA_COLOR = '#d74053'
export const WHITE_COLOR = '#FFFFFF'
export const WILD_WATERMELON_COLOR = '#ff6c7e'
export const ISO_7010_COLOR = '#f9a800'
export const ANSI_HIGH_VOLTAGE_COLOR = '#FEE633'
export const CHARCOAL_NAVY_COLOR = '#212738'
export const CHARCOAL_BLUE_COLOR = '#2f3850'
export const GUNMETAL_COLOR = '#262d40'
export const BRIGHT_GREY_COLOR = '#f2f2f2'
export const OFF_GREY_COLOR = '#d9d9d9'
export const BRIGHT_BLUE_COLOR = '#1677ff'
export const MEDIUM_GRAY_COLOR = '#a6a6a6'
export const VIVID_GREEN_COLOR = '#389E0D'
export const BRIGHT_GREEN_COLOR = '#4ac416'
export const PASTEL_RED_COLOR = '#ff9999'
export const SKY_BLUE_COLOR = '#99d3ff'
export const TRANSPARENT = 'transparent'
